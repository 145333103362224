import React from 'react'

const ClickArea = ({
  id,
  top,
  left,
  right,
  bottom,
  height,
  width,
  visible,
  onClick
}) => {

  const VISIBLE_CLASSNAME = 'bg-red-500 opacity-50'

  return (
    <button 
      id={id} 
      className={`absolute cursor-pointer ${visible ? VISIBLE_CLASSNAME : ''}`}
      style={{
        top,
        left,
        right,
        bottom,
        height,
        width,
      }}
      onClick={onClick}
    />
  )
}

export default ClickArea