import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import AboutScreen from "./main/screens/AboutScreen/AboutScreen"
import HomeScreen from "./main/screens/HomeScreen/HomeScreen"
import NotFound from "./main/screens/NotFound/NotFound"
import PillarScreen from "./main/screens/PillarScreen/PillarScreen"
import { HelmetProvider } from 'react-helmet-async';
import MediaScreen from "./main/screens/MediaScreen/MediaScreen"
import MediasScreen from "./main/screens/MediasScreen/MediasScreen"
import MediaDetailScreen from "./main/screens/MediaDetailScreen/MediaDetailScreen"
import FAQScreen from "./main/screens/FAQScreen/FAQScreen"
import CareerScreen from "./main/screens/CareerScreen/CareerScreen"
// import CareerDetailScreen from "./main/screens/CareerDetailScreen/CareerDetailScreen"
import ContactScreen from "./main/screens/ContactScreen/ContactScreen"
import MainScreen from "./cms/screens/MainScreen/MainScreen"
import MessagesScreen from "./cms/screens/MessagesScreen/MessagesScreen"
import CMSMessageScreen from "./cms/screens/MessagesScreen/MessageScreen"
import CMSLoginScreen from "./cms/screens/LoginScreen/LoginScreen"
import CMSMediaScreen from "./cms/screens/MediasScreen/MediaScreen"
import CMSMediasScreen from "./cms/screens/MediasScreen/MediasScreen"
import CMSFAQScreen from "./cms/screens/FAQScreen/FAQScreen"
import CMSFAQMethodScreen from "./cms/screens/FAQScreen/FAQMethodScreen"
import CMSStatisticScreen from "./cms/screens/StatisticScreen/StatisticScreen"
import CMSCareersScreen from "./cms/screens/CareerScreen/CareersScreen"
import CMSCareerScreen from "./cms/screens/CareerScreen/CareerScreen"
import SeratusRibuPahlawanScreen from "./main/screens/SeratusRibuPahlawan/SeratusRibuPahlawan"
import BankScreen from "./cms/screens/BankScreen/BankScreen"
import BankMethodScreen from "./cms/screens/BankScreen/BankMethodScreen"
import DonationsScreen from "./cms/screens/DonationScreen/DonationsScreen"
import DonationScreen from "./cms/screens/DonationScreen/DonationScreen"
import CMSOrganizationScreen from "./cms/screens/OrganizationScreen/OrganizationScreen"


const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Main Webstite */}
          <Route path="/" element={<HomeScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/career" element={<CareerScreen />} />
          {/* <Route path="/career/:path" element={<CareerScreen/>}/>
          <Route path="/career/:path/:id" element={<CareerDetailScreen/>}/> */}
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/media" element={<MediasScreen />} />
          <Route path="/media/:path" element={<MediaScreen />} />
          <Route path="/media/:path/:id" element={<MediaDetailScreen />} />
          <Route path="/pillars/:slug" element={<PillarScreen />} />
          <Route path="/seratus-ribu-pahlawan" element={<SeratusRibuPahlawanScreen />} />
          {/* CMS Website */}
          <Route path="/cms" element={<MainScreen />} />
          <Route path="/cms/bank" element={<BankScreen />} />
          <Route path="/cms/bank/:method" element={<BankMethodScreen />} />
          <Route path="/cms/bank/:method/:id" element={<BankMethodScreen />} />
          <Route path="/cms/career" element={<CMSCareersScreen />} />
          <Route path="/cms/career/:type" element={<CMSCareersScreen />} />
          <Route path="/cms/career/:type/:method" element={<CMSCareerScreen />} />
          <Route path="/cms/career/:type/:method/:id" element={<CMSCareerScreen />} />
          <Route path="/cms/donation" element={<DonationsScreen />} />
          <Route path="/cms/donation/:type" element={<DonationsScreen />} />
          <Route path="/cms/donation/:type/:method" element={<DonationScreen />} />
          <Route path="/cms/donation/:type/:method/:id" element={<DonationScreen />} />
          {/* <Route path="/cms/donation/view/:id" element={<DonationDetailScreen/>} /> */}
          <Route path="/cms/faq" element={<CMSFAQScreen />} />
          <Route path="/cms/faq/:method" element={<CMSFAQMethodScreen />} />
          <Route path="/cms/faq/:method/:id" element={<CMSFAQMethodScreen />} />
          <Route path="/cms/login" element={<CMSLoginScreen />} />
          <Route path="/cms/media" element={<CMSMediasScreen />} />
          <Route path="/cms/media/:type" element={<CMSMediasScreen />} />
          <Route path="/cms/media/:type/:method" element={<CMSMediaScreen />} />
          <Route path="/cms/media/:type/:method/:id" element={<CMSMediaScreen />} />
          <Route path="/cms/messages" element={<MessagesScreen />} />
          <Route path="/cms/messages/view/:id" element={<CMSMessageScreen />} />
          <Route path="/cms/statistic" element={<CMSStatisticScreen />} />
          <Route path="/cms/organization" element={<CMSOrganizationScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  )
}

export default App