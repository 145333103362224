import React, { useEffect } from 'react'
import Typography from '../../Typography/Typography'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useMoneyDigits } from '../../../hooks/useMoneyDigits';
import { useStatisticViewModel } from '../../../viewModels/useStatisticViewModel';

const SeratusJumbotron = () => {

  const { state, handler } = useStatisticViewModel()

  const moneyDigits = useMoneyDigits()
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    handler.fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} className="flex flex-col items-center justify-center w-full p-5 space-y-10 rounded-xl bg-theme-primary-50">
      {inView &&(
        <>
          <div className='flex flex-col text-center'>
            <Typography weight={'bold'} variant={'subtitle2'}>
              We Have Collected
            </Typography>
            <Typography variant={'h2'} component={'h5'} weight={'bold'} className={' text-theme-primary'}>
              Rp <CountUp end={state.detail.seratus.amount} formattingFn={moneyDigits}/>
            </Typography> 
            <Typography weight={'bold'} variant={'subtitle2'}>
              from
            </Typography>   
            <Typography variant={'h2'} component={'h5'} weight={'bold'} className={' text-theme-primary'}>
              <CountUp end={state.detail.seratus.count} formattingFn={moneyDigits}/>
            </Typography> 
            <Typography weight={'bold'} variant={'subtitle2'}>
              Donators
            </Typography>        
          </div>
          
        </>
      )}
    </div>
  )
}

export default SeratusJumbotron