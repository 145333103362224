import React, { useEffect, useState } from 'react'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import PersonPopup from './components/PersonPopup/PersonPopup'
import Card from '../../../components/Cards/Card/Card'
import Typography from '../../../components/Typography/Typography'
import Button from '../../../components/Button/Button'
import { IoChevronBack, IoChevronDown, IoChevronForward, IoChevronUp, IoPencil, IoPencilOutline, IoPersonAddOutline, IoTrash, IoTrashOutline } from 'react-icons/io5'
import Overlay from '../../../components/Overlay/Overlay'
import PopUp from '../../../components/PopUps/PopUp/PopUp'
import { ACT_AddPerson, ACT_DeletePerson, ACT_MovePerson, ACT_UpdatePerson } from './actions/action.person'
import PersonContainer from './components/PersonContainer/PersonContainer'
import TextField from '../../../components/TextField/TextField'
import { ACT_StructureAdd, ACT_StructureDelete, ACT_StructureGetList, ACT_StructureMove, ACT_StructureUpdate } from './actions/action.structure'


function OrganizationScreen() {

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)

    const [person, setPerson] = useState({})
    const [personPopup, setPersonPopup] = useState(false)

    const [structureList, setStructureList] = useState(null)
    const [structure, setStructure] = useState(null)
    const [structurePopup, setStructurePopup] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [toast, setToast] = useState(null)


    const onTitleChange = e => setStructure(current => ({ ...current, title: e?.target?.value }))

    const openStructurePopup = (payload) => {
        setStructurePopup(true)
        setStructure({
            _id: payload?._id || '',
            title: payload?.title || '',
            sort: payload?.sort || structureList ? structureList?.length + 1 : null,
        })
    }

    const closeStructurePopup = () => {
        setStructurePopup(false)
        setTimeout(() => {
            setStructure(null)
        }, 300)
    }

    const closeDeleteStructurePopup = () => {
        setDeleteId(false)
    }

    const getStructureList = async () => {
        setLoading(true)
        const result = await ACT_StructureGetList()
        setLoading(false)
        if (!result?.data) return
        const newResult = result?.data?.records
        newResult.sort((a,b) => a.sort - b.sort)
        setStructureList(newResult)
    }

    const CFN_CreateAlert = (alert) => {
        return {
            success: (title, subtitle) => {
                alert({
                    title: title || 'Success',
                    subtitle: subtitle,
                    variant: 'success',
                    action: [
                        {
                            title: 'OK',
                            onClick: () => alert(null),
                            className: 'w-full'
                        }
                    ]
                })
            },
            danger: (title, subtitle, tryAgain) => {
                alert({
                    title: title || "Danger",
                    subtitle: subtitle,
                    variant: 'danger',
                    action: [
                        {
                            title: 'OK',
                            onClick: () => alert(null),
                            color: 'secondary',
                            variant: 'outline',
                            className: 'w-full'
                        },
                        {
                            title: 'Try Again',
                            onClick: () => tryAgain(),
                            color: 'secondary',
                            className: 'w-full'
                        }
                    ]
                })
            }
        }
    }

    const createAlert = CFN_CreateAlert(setAlert)

    const addStructure = async () => {
        setLoading(true)
        const result = await ACT_StructureAdd(structure)
        if (result.error) createAlert.danger('Add Structure Failed', result.error, addStructure)
        setLoading(false)
        if (!result?.data) return
        createAlert.success('Add Structure Success')
        closeStructurePopup()
        getStructureList()
    }

    const updateStructure = async () => {
        const result = await ACT_StructureUpdate(structure)
        if (result.error) createAlert.danger('Update Structure Failed', result.error, updateStructure)
        setLoading(false)
        if (!result?.data) return
        createAlert.success('Update Structure Success')
        closeStructurePopup()
        getStructureList()
    }

    const deleteStructure = async (structure_id) => {
        setDeleteId(structure_id)
    }

    const deleteStructureHandler = async (structure_id) => {
        setLoading(true)
        closeDeleteStructurePopup()
        setAlert(null)
        const structure = structureList.find(x => x._id === structure_id)
        if (!structure) {
            setToast({
                message: 'Structure Not Found',
                close: () => setToast(null)
            })
            closeDeleteStructurePopup()
            setLoading(false)
            return
        }


        if (structure?.people?.length > 0) {
            let deletedPerson = {}
            for (let i = 0; i < structure.people.length; i++) {
                const prs = structure.people[i];
                const result = await ACT_DeletePerson(prs._id)
                deletedPerson[prs?._id] = result.data ? true : false
            }

            const isDeletePersonFailed = Object.values(deletedPerson).find((value) => value === false) === false ? true : false

            if (isDeletePersonFailed) {
                setLoading(false)
                createAlert.danger(
                    'Delete Structure Failed',
                    'Cannot delete Structure because delete Person Failed. Try to delete Person manually',
                    () => deleteStructureHandler(structure_id)
                )
                return
            }
        }

        const result = await ACT_StructureDelete(structure_id)
        if (result.error) createAlert.danger(
            'Delete Structure Failed',
            result.error,
            () => deleteStructureHandler(structure_id)
        )
        setLoading(false)
        if (!result?.data) return
        createAlert.success('Delete Structure Success')
        setDeleteId(null)
        getStructureList()
    }

    const moveStructure = async (structure_id, direction) => {
        setLoading(true)
        const result = await ACT_StructureMove({
            _id: structure_id,
            direction
        })
        if (result.error) setToast({
            message: result.error,
            close: () => setToast(null)
        })
        setLoading(false)
        if(!result.data) return
        setToast({
            status_code: result.data?.status_code,
            message: 'Structure moved ' + direction,
            close: () => setToast(null)
        })
        getStructureList()
    }

    const onSubmitStructure = () => {
        if (!structure?._id) return addStructure()
        updateStructure()
    }

    const openPersonPopup = (sStructure, sPerson) => {
        setStructure(sStructure)
        setPersonPopup(true)
        if (sPerson) setPerson(sPerson)
    }
    const closePersonPopup = () => {
        setStructure(null)
        setPersonPopup(false)
        setTimeout(() => {
            setPerson(null)
        }, 300)
    }

    const onPersonChange = (name, value) => {
        setPerson(current => ({ ...current, [name]: value }))
    }
    const onPersonClose = () => {
        closePersonPopup()
    }

    const addPerson = async () => {
        setLoading(true)
        const result = await ACT_AddPerson(
            {
                name: person?.name,
                role: person?.role,
                image: person?.image,
                structure_id: structure?._id
            },
        )
        if (result.error) createAlert.danger('Add Person Failed', result.error, addPerson)
        setLoading(false)
        if (!result.data) return
        createAlert.success('Add Person Success')
        getStructureList()
        closePersonPopup()
    }
    const updatePerson = async () => {
        setLoading(true)
        const result = await ACT_UpdatePerson(
            {
                _id: person._id,
                name: person?.name,
                role: person?.role,
                image: person?.image,
                structure_id: structure?._id
            },
        )
        if (result.error) createAlert.danger('Update Person Failed', result.error, addPerson)
        setLoading(false)
        if (!result.data) return
        createAlert.success('Update Person Success')
        getStructureList()
        closePersonPopup()
    }

    const onPersonSubmit = async () => {
        if (!person?._id) return addPerson()
        updatePerson()
    }

    const deletePerson = async (prs) => {
        setLoading(true)
        const result = await ACT_DeletePerson(prs._id)
        if (result.error) createAlert.danger('Delete Person Failed', result.error, addPerson)
        setLoading(false)
        if (!result.data) return
        createAlert.success('Delete Person Success')
        getStructureList()
    }

    const movePerson = async (person_id, structure_id, direction) => {
        setLoading(true)
        const result = await ACT_MovePerson(
            {
                person_id: person_id,
                structure_id: structure_id,
                direction
            },
        )
        if (result.error) setToast({
            message: result.error,
            close: () => setToast(null)
        })
        setLoading(false)
        if (!result.data) return
        setToast({
            status_code: result.data?.status_code,
            message: 'Person moved to the ' + direction,
            close: () => setToast(null)
        })
        getStructureList()
    }

    useEffect(() => {
        getStructureList()
    }, [])


    return (
        <>
            <CMSLayout title={'Organization Structure'} alert={alert} toast={toast} loading={loading}>
                <div className="flex flex-col w-full max-w-4xl space-y-5 col-span-full">
                    {structureList?.map((strct, strctIdx) => {
                        return (
                            <Card key={strctIdx} className='flex flex-col p-5'>
                                <div className="flex justify-between mb-4">
                                    <Typography variant={'h6'} component={'h2'} weight={'bold'} className='w-full '>
                                        Structure #{strctIdx + 1} - {strct?.title}
                                    </Typography>
                                    <div className="flex items-center space-x-2 text-lg font-semibold">
                                        <Button variant={'outline'} color={'secondary'} className='' onClick={() => deleteStructure(strct?._id)}>
                                            <IoTrashOutline />
                                        </Button>
                                        <Button variant={'outline'} onClick={() => openStructurePopup(strct)}>
                                            <IoPencilOutline />
                                        </Button>
                                        <Button onClick={() => openPersonPopup(strct)}>
                                            <IoPersonAddOutline />
                                        </Button>
                                        <button className='p-2 rounded-full hover:bg-slate-100' onClick={() => moveStructure(strct._id, 'up')}>
                                            <IoChevronUp className='text-slate-500' />
                                        </button>
                                        <button className='p-2 rounded-full hover:bg-slate-100' onClick={() => moveStructure(strct._id, 'down')}>
                                            <IoChevronDown className='text-slate-500' />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-wrap w-full">
                                    {strct?.people?.map((prs, prsIdx) => {
                                        return (
                                            <Card key={prsIdx} className='flex flex-col relative w-[23%] aspect-[10/16] mr-3 mb-4 py-4'>
                                                <div className="flex justify-between px-5 text-lg">
                                                    <button className='p-2 rounded-full hover:bg-slate-100' onClick={() => movePerson(prs._id, strct._id, 'left')}>
                                                        <IoChevronBack className='text-slate-500' />
                                                    </button>
                                                    <button className='p-2 rounded-full hover:bg-slate-100' onClick={() => movePerson(prs._id, strct._id, 'right')}>
                                                        <IoChevronForward className='text-slate-500' />
                                                    </button>
                                                </div>
                                                <div className="flex-1">
                                                    <PersonContainer

                                                        image={{
                                                            src: prs?.image?.url,
                                                            alt: 'person' + prsIdx
                                                        }}
                                                        name={prs?.name}
                                                        role={prs?.role}
                                                        backgroundColor={prsIdx % 3 === 1 ? 'secondary' : prsIdx % 3 === 2 ? 'tertiary' : 'primary'}
                                                    />
                                                </div>
                                                <div className='flex w-full px-5 mt-auto space-x-2'>
                                                    <Button className="w-full" variant={'outline'} onClick={() => openPersonPopup(strct, prs)}>
                                                        <IoPencil />
                                                    </Button>
                                                    <Button className="w-full" variant={'outline'} color={'secondary'} onClick={() => deletePerson(prs)}>
                                                        <IoTrash />
                                                    </Button>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </Card>
                        )
                    })}
                    <Button onClick={() => openStructurePopup()} className='w-full'>
                        Add Structure
                    </Button>
                </div>
            </CMSLayout>
            <Overlay
                active={deleteId || structurePopup}
                freeze={true}
            />
            <PopUp
                active={deleteId}
                className='w-full max-w-md max-h-screen p-4 overflow-y-auto'
            >
                <Card className='flex flex-col items-center w-full p-4 text-center'>
                    <Typography variant={'h6'} component={'h2'} weight={'bold'} className='w-full'>
                        Delete Structure
                    </Typography>
                    <Typography className=''>
                        Do you want to delete this structure?
                    </Typography>
                    <div className="flex items-center w-full pt-6 space-x-2">
                        <Button className={'w-full'} variant={'outline'} onClick={closeDeleteStructurePopup}>
                            Cancel
                        </Button>
                        <Button className={'w-full'} onClick={() => deleteStructureHandler(deleteId)}>
                            Yes
                        </Button>
                    </div>
                </Card>
            </PopUp>
            <PopUp
                active={structurePopup}
                className='w-full max-w-sm max-h-screen p-4 overflow-y-auto'
            >
                <Card className='flex flex-col w-full p-4 '>
                    <Typography variant={'h6'} component={'h2'} weight={'bold'} className='w-full mb-4'>
                        {!structure?._id ? 'Add' : 'Update'} Structure
                    </Typography>
                    <TextField
                        wrapperClassName='w-full mb-4'
                        label={'Title'}
                        name={'title'}
                        value={structure?.title || ''}
                        onChange={onTitleChange}
                    />
                    <div className="flex items-center justify-end w-full pt-6 space-x-2">
                        <Button variant={'outline'} onClick={closeStructurePopup}>
                            Cancel
                        </Button>
                        <Button onClick={onSubmitStructure}>
                            {!structure?._id ? 'Add' : 'Update'}
                        </Button>
                    </div>
                </Card>
            </PopUp>
            <PersonPopup
                visible={personPopup}
                person={person}
                onChange={onPersonChange}
                onClose={onPersonClose}
                onSubmit={onPersonSubmit}
            />
        </>
    )
}

export default OrganizationScreen