import React from 'react'
import { useGetFullDate } from '../../../hooks/useGetFullDate'
import Button from '../../Button/Button'
import ImageContainer from '../../ImageContainer/ImageContainer'
import Typography from '../../Typography/Typography'

const MediaCard = ({
  data,
  className = ''
}) => {

  const getFullDate = useGetFullDate()

  return (
    <div className={`${styles.card_container} ${className}`}>
      <div className="relative w-full overflow-hidden rounded-2xl bg-slate-100">
        {data.video && (
          <iframe className='absolute inset-0 z-10' width="100%" height="100%" src={data.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        )}
        <ImageContainer className='w-full' src={data.image.src} alt={data.title} ratio={data.image.ratio}/>
      </div>
      <div className="z-20 flex flex-col p-5 -mt-3 bg-white rounded-xl">
        <div className='space-y-1'>
          <Typography
            variant={'h6'}
            component={'div'}
            weight={'semibold'}
            lineHeight={'tight'}
            className={'line-clamp-2 break-after-all'}
          >
            {data.title}
          </Typography>
          {data.createdAt && (
            <Typography variant={'subtitle2'}>
              {getFullDate(data.createdAt)}
            </Typography>
          )}
        </div>
        {data.description && (
          <Typography className='mt-2 break-words line-clamp-2 text-slate-500' variant={'body2'}>
            {data.description}
          </Typography>
        )}
        {data.action && (
          <Button
            {...data.action.props}
            className='w-full mt-4'
            color={'tertiary'}
            variant={'outline'}
          >
            {data.action.title}
          </Button>
        )}
      </div>
    </div>
  )
}

const styles = {
  card_container: 'flex flex-col  rounded-xl overflow-hidden  shadow-lg'
}

export default MediaCard