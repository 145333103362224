import React from 'react'
import Button from '../../../../components/Button/Button'
import ShapeMask from '../../../../components/ShapeMask/ShapeMask'
import Typography from '../../../../components/Typography/Typography'
import { IMAGE_PATH } from '../../../../constants/urlConstants'
import certificate from './certificate.png'

const DonationJumbotron = () => {
  return (
    <ShapeMask 
      component={'section'}
      maskImage={`${IMAGE_PATH}/shapes/shape_3.png`}
      maskPosition={'center'}
      maskSize={'100% 100%'}
      className={'bg-theme-secondary -mt-[8vh] lg:-mt-[10vh] section-non-responsive'}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Typography
            variant={'h3'}
            component={'h2'}
            className={'text-white mb-5'}
            weight={'bold'}
            lineHeight={'tight'}
          >
            Helping Children Build Dreams
          </Typography>
          <Typography
            variant={'subtitle1'}
            className={'text-white mb-10'}
            lineHeight={'tight'}
          >
            Help us to helping children to build their dreams through donation
          </Typography>
          <Button component={'a'} href="#donation">DONATE NOW</Button>
          <a href={certificate} target='_blank' rel='noreferrer' className='mt-20'>
            <img src={certificate} alt={'certificate'}/>
          </a>
        </div>
      </div>
    </ShapeMask>
  )
}

const styles = {
  wrapper: "relative flex flex-col items-center w-full h-full py-44 responsive xl:py-48",
  container: "max-w-lg mx-auto flex flex-col h-full justify-center items-center text-center",
}

export default DonationJumbotron