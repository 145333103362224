import React, { useState } from 'react'
import Overlay from '../../../../../components/Overlay/Overlay'
import PopUp from '../../../../../components/PopUps/PopUp/PopUp'
import Card from '../../../../../components/Cards/Card/Card'
import Typography from '../../../../../components/Typography/Typography'
import TextField from '../../../../../components/TextField/TextField'
import ImageContainer from '../../../../../components/ImageContainer/ImageContainer'
import Button from '../../../../../components/Button/Button'

function PersonPopup({
    person,
    visible,
    onChange,
    onClose,
    onSubmit,
}) {
    const [errors, setErrors] = useState(null)

    const onInputChange = (e) => {
        onChange(e.target.name, e.target.value)
    }

    const onImageChange = (image) => {
        onChange("image", image)
    }

    const addPersonImage = (e) => {
        const files = e.target.files
        if (files && files.length > 0) {
            let errorsObj = errors ? { ...errors } : {}
            if (files[0].size / 1000 <= 1000) {
                delete errorsObj.image
                setErrors(errorsObj)
                onImageChange(files[0])
                return
            }
            errorsObj.image = { type: 'invalid', message: 'Your file is more than 1 MB' }
            setErrors(errorsObj)
        }
    };

    const onCloseHandler = () => {
        onClose && onClose()
    }

    return (
        <>
            <Overlay
                active={visible}
                freeze={true}
            />
            <PopUp
                active={visible}
                className='flex justify-center w-full h-full p-4'
            >
                <Card className='flex flex-col w-full h-full max-w-sm p-4 whitespace-nowrap'>
                    <Typography variant={'h6'} component={'h2'} weight={'bold'} className='w-full mb-4'>
                        {person?._id ? 'Update' : 'Add'} Person
                    </Typography>
                    <TextField
                        wrapperClassName='w-full mb-4'
                        label={'Name'}
                        name={'name'}
                        value={person?.name || ''}
                        onChange={onInputChange}
                    />
                    <TextField
                        wrapperClassName='w-full mb-4'
                        label={'Role'}
                        name={'role'}
                        value={person?.role || ''}
                        onChange={onInputChange}
                    />
                    <div className={'flex flex-col h-full w-full'}>
                        <span className="mb-2 text-xs font-semibold">
                            Image
                        </span>
                        {person?.image ? (
                            <div className='flex flex-col w-full'>
                                <ImageContainer
                                    fit='contain'
                                    className='overflow-hidden border rounded-xl'
                                    src={person?.image?.url || URL.createObjectURL(person?.image)}
                                    alt="Thumb"
                                    ratio={{ width: 1, height: 1 }}
                                />
                                <button className='mt-2 text-sm text-center text-red-600' onClick={() => onImageChange(null)}>Remove This Image</button>
                            </div>
                        ) : (
                            <>
                                <label className={'text-xs font-semibold mb-2 cursor-pointer'} htmlFor="image">
                                    <input id='image' type="file" className='absolute top-0 left-0 w-px h-px opacity-0' onChange={addPersonImage} accept="image/png, image/jpg, image/jpeg" />
                                    <div
                                        className={
                                            'flex flex-col items-center justify-center ' +
                                            'text-center font-bold text-theme-primary aspect-square ' +
                                            'border-2 border-theme-primary border-dashed  rounded-xl w-full '
                                        }
                                    >
                                        + Image
                                    </div>
                                </label>
                                <div className='mt-1 text-xs'>
                                    {errors?.image && (
                                        <span className='text-theme-secondary'>{errors?.image?.message}</span>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex items-center justify-end w-full pt-4 space-x-2">
                        <Button variant={'outline'} onClick={onCloseHandler}>
                            Cancel
                        </Button>
                        <Button onClick={() => onSubmit(person)}>
                            {person?._id ? 'Update' : 'Add'} Person
                        </Button>
                    </div>
                </Card>
            </PopUp>
        </>
    )
}

export default PersonPopup