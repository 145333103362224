import React from 'react'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import ShapeMask from '../../ShapeMask/ShapeMask'
import Typography from '../../Typography/Typography'

const SmallHero = ({title, subtitle, src, backgroundPosition, }) => {
  return (
    <ShapeMask 
      component={'section'}
      maskImage={`${IMAGE_PATH}/shapes/shape_1.svg`}
      maskPosition={'bottom'}
      maskSize={'100% 100%'}
      backgroundImage={src || `${IMAGE_PATH}/photos/small_hero/small_hero.png`}
      backgroundPosition={src ? (backgroundPosition || 'center') : 'top 30% right'} 
      overlay={true}
      className={'pt-16 pb-14 text-white xl:pt-20 xl:pb-20 2xl:py-24'}
    >
      <div className="responsive">
        {subtitle && (
          <Typography className='flex justify-center text-center capitalize' variant={'subtitle1'} weight={'medium'}>
            {subtitle}
          </Typography>
        )}
        <Typography className='text-center capitalize' variant={'h4'} weight={'bold'} lineHeight={'tight'}>
          {title}
        </Typography>
      </div>
    </ShapeMask>
  )
}

export default SmallHero