import { IMAGE_PATH, PILLARS_AND_PROGRAMS_IMAGE_PATH } from "../constants/urlConstants"
import { RiInstagramLine, RiLinkedinFill, RiTwitterFill } from 'react-icons/ri'
import { IoLogoFlickr } from "react-icons/io5"

const data = {
  link: {
    navigation: [
      {
        title: 'Home',
        path: '/'
      },
      {
        title: 'About',
        path: '/about'
      },
      {
        title: 'Media',
        path: '/media'
      },
      // {
      //   title: 'Career',
      //   path: '/career'
      // },
      {
        title: 'Contact',
        path: '/contact'
      },
    ],
    socmed: [
      {
        title: 'Facebook',
        path: 'https://www.facebook.com/yhm.rednose.5',
        icon: ( <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path></g></svg> )
      },
      {
        title: 'Twitter',
        path: 'https://twitter.com/RNFRedNose',
        icon: <RiTwitterFill />
      },
      {
        title: 'Instagram',
        path: 'https://www.instagram.com/rednosefoundation',
        icon: <RiInstagramLine />
      },
      {
        title: 'LinkedIn',
        path: 'https://www.linkedin.com/company/the-red-nose-foundation/',
        icon: <RiLinkedinFill />
      },
      {
        title: 'Flickr',
        path: 'https://www.flickr.com/people/93275615@N03',
        icon: <IoLogoFlickr />
      },
    ],
  },
  history: [
    {
      amount: 2324,
      title: 'Happy Kids'
    },
    {
      amount: 24,
      title: 'Countries'
    },
    {
      amount: 24,
      title: 'Years Experience'
    },
  ],
  org_structure: {
    sm: [
      {
        category: 'founder',
        title: 'Meet Our Founder',
        personList: [
          {
            name: 'Dan Roberts ',
            role: 'Founder',
            image: `${IMAGE_PATH}/photos/org/fd-dan.png`
          },
          {
            name: 'Dedy Purwadi',
            role: 'Co-Founder & Board Member',
            image: `${IMAGE_PATH}/photos/org/cf-dedy.png`
          },
          {
            name: 'Renny Roberts',
            role: 'Co-Founder',
            image: `${IMAGE_PATH}/photos/org/cf-renny.png`
          },
        ]
      },
      {
        category: 'board',
        title: 'Meet Our Board Member',
        personList: [
          {
            name: 'Erwien Temasmico Djayoesman',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-erwien.png`
          },
          {
            name: 'Nino Rianditya Putra',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-nino.png`
          },
          {
            name: 'Haykal Hasan',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-haykal.png`,
            position: 'top'
          },
          {
            name: 'Don Reeves',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-don.png`,
          },
        ]
      },
      {
        category: 'team',
        title: 'Meet Our Team',
        personList: [
          {
            name: 'Ratih Prativi Negara',
            role: 'Executive Director',
            image: `${IMAGE_PATH}/photos/org/ed-ratih.png`
          },
          {
            name: 'Prasiddha Santika',
            role: 'Strategic Business & Outreach Manager',
            image: `${IMAGE_PATH}/photos/org/sbom-pras.png`
          },
          {
            name: 'Rima Permata Sari',
            role: 'Office Manager',
            image: `${IMAGE_PATH}/photos/org/om-rima.png`
          },
          {
            name: 'Wilda Nuurul Fatah',
            role: 'Youth Empowerment & Training Manager',
            image: `${IMAGE_PATH}/photos/org/yetm-wilda.png`
          },
          {
            name: 'Akwila ',
            role: 'Child Development and Arts Manager',
            image: `${IMAGE_PATH}/photos/org/cdam-akwila.png`
          },
          {
            name: 'Arly Getha Purba',
            role: 'Digital Marketing Communication Officer',
            image: `${IMAGE_PATH}/photos/org/mcs-arly.png`
          },
          {
            name: 'Suhendi',
            role: 'Operational Support Officer',
            image: `${IMAGE_PATH}/photos/org/fas-suhendi.png`
          },
          {
            name: 'Wawan Kurniawan',
            role: 'Social Circus Instructor',
            image: `${IMAGE_PATH}/photos/org/cdai-wawan.png`
          },
          {
            name: 'Syaid Parlindungan',
            role: 'Social Circus Instructor',
            image: `${IMAGE_PATH}/photos/org/cdai-syaid.png`
          },
          {
            name: 'Muhammad Wahyu',
            role: 'Sport and Healthy Lifestyle Instructor',
            image: `${IMAGE_PATH}/photos/org/cdai-wahyu.png`
          },
          {
            name: 'Suparmi',
            role: 'Office Assistant',
            image: `${IMAGE_PATH}/photos/org/oa-suparmi.png`
          },
        ]
      },
    ],
    lg: [
      {
        category: 'founder',
        title: 'Meet Our Founder',
        personList: [
          {
            name: 'Dan Roberts ',
            role: 'Founder',
            image: `${IMAGE_PATH}/photos/org/fd-dan.png`
          },
          {
            name: 'Dedy Purwadi',
            role: 'Co-Founder & Board Member',
            image: `${IMAGE_PATH}/photos/org/cf-dedy.png`
          },
          {
            name: 'Renny Roberts',
            role: 'Co-Founder',
            image: `${IMAGE_PATH}/photos/org/cf-renny.png`
          },
        ]
      },
      {
        category: 'board',
        title: 'Meet Our Board Member',
        personList: [
          {
            name: 'Erwien Temasmico Djayoesman',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-erwien.png`
          },
          {
            name: 'Nino Rianditya Putra',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-nino.png`
          },
          {
            name: 'Haykal Hasan',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-haykal.png`,
            position: 'top'
          },
          {
            name: 'Don Reeves',
            role: 'Board Member',
            image: `${IMAGE_PATH}/photos/org/br-don.png`,
          },
        ]
      },
      {
        category: 'team',
        title: 'Meet Our Team',
        personList: [
          [
            {
              name: 'Ratih Prativi Negara',
              role: 'Executive Director',
              image: `${IMAGE_PATH}/photos/org/ed-ratih.png`
            },
            {
              name: 'Prasiddha Santika',
              role: 'Strategic Business & Outreach Manager',
              image: `${IMAGE_PATH}/photos/org/sbom-pras.png`
            },
            {
              name: 'Rima Permata Sari',
              role: 'Office Manager',
              image: `${IMAGE_PATH}/photos/org/om-rima.png`
            },
          ],
          [
            {
              name: 'Wilda Nuurul Fatah',
              role: 'Youth Empowerment & Training Manager',
              image: `${IMAGE_PATH}/photos/org/yetm-wilda.png`
            },
            {
              name: 'Akwila ',
              role: 'Child Development and Arts Manager',
              image: `${IMAGE_PATH}/photos/org/cdam-akwila.png`
            },
            {
              name: 'Arly Getha Purba',
              role: 'Digital Marketing Communication Officer',
              image: `${IMAGE_PATH}/photos/org/mcs-arly.png`
            },
            {
              name: 'Suhendi',
              role: 'Operational Support Officer',
              image: `${IMAGE_PATH}/photos/org/fas-suhendi.png`
            },
            {
              name: 'Wawan Kurniawan',
              role: 'Social Circus Instructor',
              image: `${IMAGE_PATH}/photos/org/cdai-wawan.png`
            },
            {
              name: 'Syaid Parlindungan',
              role: 'Social Circus Instructor',
              image: `${IMAGE_PATH}/photos/org/cdai-syaid.png`
            },
            {
              name: 'Muhammad Wahyu',
              role: 'Sport and Healthy Lifestyle Instructor',
              image: `${IMAGE_PATH}/photos/org/cdai-wahyu.png`
            },
            {
              name: 'Suparmi',
              role: 'Office Assistant',
              image: `${IMAGE_PATH}/photos/org/oa-suparmi.png`
            },
          ]
        ]
      },
    ],
  },
  pillars: [
    {
      image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children.png`,
      title: 'Art and Education for Children',
      slug: 'art-and-education-for-children',
      description: `The Red Nose Foundation (RNF) curriculum is always updated to provide better programs that are both engaging and meet the needs of children. RNF's approach is innovative and combines traditional education subjects with art education. This hands-on method makes it easier for teachers to facilitate learning and ensure that kids are engaged. RNF's strategies help the children to become more active and creative with more practical experience`,
      programs: [
        {
          title: 'Creative Learning Program',
          data: [
            {
              title: `Literacy and Numeracy Training`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/literacy_and_numeracy_training.jpg`,
              description: `The Literacy & Numeracy Training began to start in 2019 as it
              was revealed that many children still encounter difficulties in
              understanding and applying themselves in classes due to a
              lack of comprehension skills and hands-on experience. In
              addressing this issue, RNF goes back to the basics by focusing
              on children’ literacy and numeracy skills and fostering their
              love of learning. Targeted at children aged 6-14, the
              curriculum of this program contains a variety of exciting
              activities and practical scenarios, such as shopping simulations
              and acting as a bank teller.`
            },
            {
              title: `English and Theater`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/english_and_theater.jpg`,
              description: `In today’s globalized world, learning English as a global
              language is very important for children. Having good
              English skills can boost their self-esteem as well as widen
              their opportunities in reaching their dreams. To address
              this issue, from 2017 to 2018, RNF has created a very special
              English Class with unique approaches, such as including
              theater performances and social activism as part of the
              program. By learning with these methods, children will
              get a chance to sharpen their confidence, public speaking
              skill, and collaboration skill in addition to learning a new
              language.`
            },
            {
              title: `Science and Art`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/science_and_art.jpg`,
              description: `The Literacy & Numeracy Training began to start in 2019 as it
              was revealed that many children still encounter difficulties in
              understanding and applying themselves in classes due to a
              lack of comprehension skills and hands-on experience. In
              addressing this issue, RNF goes back to the basics by focusing
              on children’ literacy and numeracy skills and fostering their
              love of learning. Targeted at children aged 6-14, the
              curriculum of this program contains a variety of exciting
              activities and practical scenarios, such as shopping simulations
              and acting as a bank teller.`
            },
          ]
        },
        {
          title: 'Healthy Lifestyle Program',
          data: [
            {
              title: `Sport and Nutrition Class`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/sport_and_nutrition.jpg`,
              description: `Sports and Nutrition Classes were launched to tackle the physical
              issues faced by individuals in underprivileged communities as
              many of them still lack access to health education or healthcare.
              Thus, RNF is committed to actively promoting a healthy lifestyle
              through fun and empowering activities. This includes various
              sports classes which also aim to improve children’ discipline,
              respect for friends, and consumption of healthy and balanced food.
              Through the program, RNF also engages parents and the local
              community to get involved by organizing seminars and workshops.
              `
            },
            {
              title: `Social Circus Class`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/social_circus_class.jpg`,
              description: `RNF believes in the power of Social Circus as a tool for
              creating social change. It provides children and youth with
              accessible empowerment opportunities that will help them
              to achieve personal and social development. In addition, it
              also empowers children by combining the values of
              education, physical arts, and life skills. As children learn to
              do juggling, plate spinning, gymnastics, and acrobatics,
              they also learn important life values and competencies
              along the way, such as responsibility, respect, confidence,
              and collaboration.`
            },
            {
              title: `Psychosocial & Gender Mainstreaming Classes`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/psychosocial_gender_mainstream.jpg`,
              description: `The Psychosocial class was first introduced in 2017 as a part
              of the Healthy Lifestyle Program to address mental health
              issues. It provides children a place where they can ask for
              help, reveal their true selves, and expresses problems they
              may have. Various activities and knowledge are also
              delivered in order to spread the core values of the RNF,
              such as responsibility, respect, collaboration, love of
              learning, persistence, critical thinking, and the importance
              of gender equality in all situations. In addition, counseling
              and peer support groups are applied for children and
              parents.`
            },
          ]
        },
        {
          title: 'Scholarship Program',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/art_and_education_for_children/scholarship.jpg`,
          description: `We currently award full scholarships to 60 children/ year who
          have participated in RNF’s programs for more than 2 years.
          The scholarship we provide covers 90% of school tuition and
          other fees needed by children. In addition, 250 partial
          scholarships are also awarded to children who have
          demonstrated excellent academic efforts. By providing
          scholarships, we hope that it can reduce the number of
          dropouts among children, which often happens due to their
          families’ financial condition.`
        },
      ]
    },
    {
      image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/youth_empowerment.png`,
      title: 'Youth Empowerment',
      slug: 'youth-empowerment',
      description: `Before we enter the work environment, requiring soft skills does not cross our minds and often we are blind about the workplace because of lack of experience. Another challenge came even when we get the job, where we find it difficult to adapt to the new workplace. To overcome this, RNF wants to help youth with their development through training. The training includes individual goal setting, work ethic, effective communication, basic financial literacy, CV writing, interview techniques, and communication. Knowledge about workers' rights and gender equality are also explained in the training sessions. This program is in line with the Indonesian government’s Youth Empowerment as Employment Solutions program and aims to help curb the cycle of unemployment and underemployment`,
      programs: [
        {
          title: 'Job-Readiness & Vocational Training',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/youth_empowerment/job_readiness_training.jpg`,
          description: `The Job Readiness Training is held with the aim to prepare youths aged
          15-18, especially those who are underprivileged, for entering the
          workforce. The training equips children with important skills that are
          needed to enter the job market: goal setting, work ethic, effective
          communication, basic financial literacy, CV writing, interview
          techniques, and communication. With a mission to improve young
          people’s job readiness and employability levels, we conduct a hospitality
          class, in which children are given a series of training to broaden their
          knowledge regarding the job and industry. They are also offered to
          enroll in an internship program to further sharpen their skills and gain
          real-life experience. Through our partnership with various corporations
          in the hospitality sector, children are welcomed to be an intern in well-established hotels and restaurants for 3-6 months.`
        },
        {
          title: 'Mini-Enterprise Training',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/youth_empowerment/mini_enterprise_training.jpg`,
          description: `As a part of our Vocational Training Program, the Mini-Enterprise Training is actively organizing a graphic design
          class for youths started in 2019. In this class, children are
          equipped with practical knowledge and skills, such as
          typography, as well as an introduction to graphic design,
          logo design, vector design, and creating infographics.
          Furthermore, they also have the chance to do internships in
          RNF’s company partners, such as creative firms with the
          hope that they can gain real working experience. We are
          preparing Clothing Printing as our piloting project for RNF
          small business unit.`
        },
        {
          title: 'International Performance Troupe',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/youth_empowerment/international_performance_troupe.jpg`,
          description: `We want our youth to learn overseas in order to improve
          their circus skills. Since 2010, we brought more than 20
          youth to experience International Performance in Chicago,
          Kentucky, Vermont, Vancouver, Cambodia, Yangon,
          Bangkok, and cities in Poland. Our youth learn new circus
          skills to be adapted in Indonesia and held social circus
          performances in mentioned countries.`
        },
      ]
    },
    {
      image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/sustainable_community.png`,
      title: 'Sustainable Community',
      slug: 'sustainable-community',
      description: 'As we believe that children can not stand alone, and the entire elements around them are important for their development process. As a support system, we are educating and engaging closely with parents, school teachers, local authorities, and peers through seminars and Focus Group Discussions',
      programs: [
        {
          title: 'Focus Group Discussion and Seminars',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/sustainable_community/focus_group_discussion_and_seminars.jpg`,
          description: `The Large Scale Social Restriction (PSBB) which has been implemented
          in Jakarta several times in 2021 due to the COVID-19 outbreak makes it
          difficult for us to conduct our programs offline. However, RNF is still
          committed to supporting our local communities in this difficult time by
          facilitating several online Focus Group Discussions. For example the bi-monthly supporting group discussions with the RPTRA’s representatives
          and peer-to-peer group discussions, where children can have a safe
          space to express their feelings and thoughts. Previously, we have
          successfully conducted this program through our Coaching for Life
          (COOL) Project from August 2019 to July 2020 together with Save the
          Children (STC) as our partner.`
        },
        {
          title: 'Families Fun Activities',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/sustainable_community/families_fun_activities.jpg`,
          description: `Family is the core system in a child's life that functioned as the first
          support system. We realize that developing children and youth means
          developing parents and families as well. Several activities for families
          started in 2017 with Green Juice Demo where mothers learned to
          produce healthy drinks with fruits and vegetables as main ingredients.
          Later on, during the COVID-19 pandemic, we encourage parents and
          children to do sport together and get prizes from its campaign. We also
          invite parents to Virtual Zumba activities. So far, more than 800 parents
          involved in our family's fun activities.`
        },
        {
          title: 'Teachers Training',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/sustainable_community/teachers_training.jpg`,
          description: `Other than staying connected with our children and their parents, RNF
          also actively organizes workshops for teachers near our centers in
          Cilincing and Bintaro to introduce creative teaching methods and
          innovative curriculum in schools. We believe that teachers play a very
          important role in creating more quality education. The purpose of this
          workshop is to help teachers in creating more exciting lessons in the
          classroom which are relevant in today’s context and fulfills the children’
          needs. In 2017, RNF flew to Central and South Sulawesi to hold sports
          teachers' training. And regularly 2 times a year, Sports Teachers Training
          is given to Cilincing and Jurangmangu, where our centers are located.
          In 2018, we also train experimental learning for science teachers and in
          2019, we hold Digital Literacy Training for more than 150 teachers.`
        },
       
      ]
    },
    {
      image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/emergency_response.png`,
      title: 'Emergency Response',
      slug: 'emergency-response',
      description: `We are aware and stand for all children at risk, especially in moments of force majeure and post-conflict trauma. We use Social Circus to entertain and bring smiles again to children. Our psychosocial approach also helps them to adapt and be resilient in facing their challenges in life`,
      programs: [
        {
          title: 'COVID-19 Hygiene Kit Distribution',
          image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/emergency_response/COVID-19_hygiene_kit_distribution.jpg`,
          description: `During the COVID-19 pandemic, RNF has a mission to spread more
          awareness regarding the importance of a healthy lifestyle. Through our
          collaboration with partners such as NIKE, Yayasan PLAN Indonesia, and
          New Zealand Aid Programme to distribute Hygiene Kit and Nutrition Kit
          to the communities near RNF’s centers in Cilincing and Jurangmangu.
          It is hoped that our efforts will bring good impacts to the communities
          and serve as a reminder for everyone to keep maintaining a hygienic
          condition and eating nutritious food to strengthen their immune
          systems. In total, we distributed more than 2.000 hygiene kits to local
          communities, and water taps to our local governments.`
        },        
        {
          title: 'Trauma Healing through Social Circus',
          data: [
            {
              title: `In Yogyakarta`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/emergency_response/jogja.jpg`,
              description: `The eruption of Mount Merapi, Yogyakarta in 2010 has left many impacts
              on those who were affected, including the feeling of trauma. In an
              attempt to help the victims’ trauma recovery, RNF collaborated with the
              Jakarta Intercultural School (JIS) to conduct the special circus
              performance and workshops for them. Through this program, our hope
              was to replace fear and grief experienced by the victims, especially
              children, with laughter and joy.`
            },
            {
              title: `In Mentawai`,
              image: `${PILLARS_AND_PROGRAMS_IMAGE_PATH}/emergency_response/mentawai.jpg`,
              description: `In October 2010, a 7.8 magnitude earthquake and tsunami hit
              Mentawai, West Sumatra which swept up the local villagers’ homes
              without warning and resulted in the displacement of thousands of
              people. As a response, RNF team held a social circus workshop and
              performance to entertain and heal the trauma of these natural disasters’
              victims, including many children. To reach the area where they
              temporarily stayed, we collaborated with the United Nations
              Development Program (UNDP).`
            },
          ]
        },        
      ]
    },
  ],
  performances: [
    {
      title: 'North America',
      image: `${IMAGE_PATH}/maps/north_america_map.png`,
      description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab recusandae nemo enim, molestias accusantium dolores ea possimus repellendus adipisci fuga, asperiores accusamus rerum odio dignissimos itaque. Dolor mollitia ad eum.`,
      list: {
        title: 'Countries / States / Cities',
        data: [          
          {
            title: 'Chicago',
            image: `${IMAGE_PATH}/photos/performances/chicago1.jpg`,
            description: `Wawan together with Dan Robert succeeded in adding to the achievements of RNF in the international arena. They participated in IRV activities at North Park University, Chicago, Illinois, and became representatives for All Around Vault Junior Boys`,
          },
          {
            title: 'Missouri',
            image: `${IMAGE_PATH}/photos/performances/missouri1.jpg`,
            description: `From April to July 2015, Red Nose Foundation artistic director Dedi Purwadi was in St. Louis, Missouri, for social circus training with Circus Harmony.`,
          },
        ]
      }
    },
    {
      title: 'Europe',
      image: `${IMAGE_PATH}/maps/europe_map.png`,
      description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab recusandae nemo enim, molestias accusantium dolores ea possimus repellendus adipisci fuga, asperiores accusamus rerum odio dignissimos itaque. Dolor mollitia ad eum.`,
      list: {
        title: 'Countries',
        data: [
          {
            title: 'Poland',
            image: `${IMAGE_PATH}/photos/performances/poland1.jpg`,
            description: `In 2014, the Red Nose Foundation returned to writing stories on the international scene. Together with children in Poland, the Red Nose Foundation trains together to train Circus. The excitement and also exchanging experiences between children became a separate experiences and excitement for the children at that time`,
            list: {
              title: 'Cities',
              data: [
                'Warsaw',
                'Wałbrzych (2016)',
              ]
            }
          },
        ]
      }
    },
    {
      title: 'Asia',
      image: `${IMAGE_PATH}/maps/asia_map.png`,
      description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab recusandae nemo enim, molestias accusantium dolores ea possimus repellendus adipisci fuga, asperiores accusamus rerum odio dignissimos itaque. Dolor mollitia ad eum.`,
      list: {
        title: 'Countries',
        data: [
          {
            title: 'Thailand',
            image: `${IMAGE_PATH}/photos/performances/thailand1.jpg`,
            description: `In October 2012, the Red Nose Foundation sent 2 representatives to participate in the Circus Workshop with friends in Thailand. April and Desi together with Founder, Dan Robert and Dedi, went together to attend the Southeast Asian Social Circus Conference`,
            list: {
              title: 'Cities',
              data: [
                'Bangkok',
              ]
            }
          },
          {
            title: 'Cambodia',
            image: `${IMAGE_PATH}/photos/performances/cambodia1.jpg`,
            description: `Tini Tinou and Wawan April with PPS. Together practicing in a series of Circus Workshop events to continue to equip circus playing skills and also share experiences with children from other regions`,
            list: {
              title: 'Cities',
              data: [
                'Pnom Penh',
                'Badambang (2015)',
              ]
            }
          },
          {
            title: 'Myanmar',
            image: `${IMAGE_PATH}/photos/performances/myanmar1.jpg`,
            description: `Minglabar Myanmar! 6 Red Nose children (Wawan, April, Rais, Rika, Syaid, and Rini), together with Executive Director Dan Roberts and artistic director Dedi Purwadi participated in the International Juggling Festival in Yangon, Myanmar, from 9-15 February 2015`,
          },
        ]
      }
    },
  ],
  media: {
    news: {
      title: 'News',
      path: '/media/news',
      data: [
        {
          id: '5161664613516816',
          title: 'Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus  1',
          slug: 'news-title-1',
          image: `${IMAGE_PATH}/photos/performances/cambodia1.jpg`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pharetra, ipsum ut porttitor faucibus, sapien eros convallis ligula, nec volutpat tellus nulla et massa. Vivamus ultricies rutrum dolor, vitae accumsan libero malesuada ut. Proin velit lorem, facilisis ac leo sodales, volutpat porttitor dui. In hac habitasse platea dictumst. Maecenas dignissim euismod quam eu hendrerit. Aenean aliquam turpis in lacus suscipit volutpat. Quisque et volutpat quam. Phasellus enim nibh, aliquam in orci quis, rutrum imperdiet lorem. \n \n Suspendisse potenti. Etiam vel porttitor libero, vitae eleifend risus. Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus ultrices dapibus. Morbi ultrices eleifend rutrum. Nulla et quam vel neque placerat accumsan vel in odio. Pellentesque non varius orci, quis laoreet nisl. Ut rutrum et nisl et sollicitudin. Suspendisse potenti. Aenean vitae sodales purus. Vestibulum mattis enim sed leo cursus, sit amet condimentum nulla mattis.`,
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus  2',
          slug: 'news-title-2',
          image: `${IMAGE_PATH}/photos/performances/cambodia1.jpg`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pharetra, ipsum ut porttitor faucibus, sapien eros convallis ligula, nec volutpat tellus nulla et massa. Vivamus ultricies rutrum dolor, vitae accumsan libero malesuada ut. Proin velit lorem, facilisis ac leo sodales, volutpat porttitor dui. In hac habitasse platea dictumst. Maecenas dignissim euismod quam eu hendrerit. Aenean aliquam turpis in lacus suscipit volutpat. Quisque et volutpat quam. Phasellus enim nibh, aliquam in orci quis, rutrum imperdiet lorem. \n \n Suspendisse potenti. Etiam vel porttitor libero, vitae eleifend risus. Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus ultrices dapibus. Morbi ultrices eleifend rutrum. Nulla et quam vel neque placerat accumsan vel in odio. Pellentesque non varius orci, quis laoreet nisl. Ut rutrum et nisl et sollicitudin. Suspendisse potenti. Aenean vitae sodales purus. Vestibulum mattis enim sed leo cursus, sit amet condimentum nulla mattis.`,
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus  3',
          slug: 'news-title-3',
          image: `${IMAGE_PATH}/photos/performances/cambodia1.jpg`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pharetra, ipsum ut porttitor faucibus, sapien eros convallis ligula, nec volutpat tellus nulla et massa. Vivamus ultricies rutrum dolor, vitae accumsan libero malesuada ut. Proin velit lorem, facilisis ac leo sodales, volutpat porttitor dui. In hac habitasse platea dictumst. Maecenas dignissim euismod quam eu hendrerit. Aenean aliquam turpis in lacus suscipit volutpat. Quisque et volutpat quam. Phasellus enim nibh, aliquam in orci quis, rutrum imperdiet lorem. \n \n Suspendisse potenti. Etiam vel porttitor libero, vitae eleifend risus. Suspendisse potenti. Quisque bibendum nulla mi, vehicula semper tellus ultrices dapibus. Morbi ultrices eleifend rutrum. Nulla et quam vel neque placerat accumsan vel in odio. Pellentesque non varius orci, quis laoreet nisl. Ut rutrum et nisl et sollicitudin. Suspendisse potenti. Aenean vitae sodales purus. Vestibulum mattis enim sed leo cursus, sit amet condimentum nulla mattis.`,
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
      ]
    },
    videos: {
      title: 'Videos',
      path: '/media/videos',
      data: [
        {
          id: '5161664613516816',
          title: 'Perspektif Ep 66 - Bahagia Dalam Keterbatasan 1',
          slug: 'video-title-1',
          video: 'https://www.youtube.com/embed/NMXIyIFcFhc',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Perspektif Ep 66 - Bahagia Dalam Keterbatasan 2',
          slug: 'video-title-2',
          video: 'https://www.youtube.com/embed/NMXIyIFcFhc',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Perspektif Ep 66 - Bahagia Dalam Keterbatasan 3',
          slug: 'video-title-3',
          video: 'https://www.youtube.com/embed/NMXIyIFcFhc',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
      ]
    },
    reports: {
      title: 'Reports',
      path: '/media/reports',
      data: [
        {
          id: '5161664613516816',
          title: 'Report 1',
          slug: 'report-1',
          link: 'https://www.lipsum.com/feed/html',
          image: '',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Report 2',
          slug: 'report-2',
          link: 'https://www.lipsum.com/feed/html',
          image: '',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
        {
          id: '5161664613516816',
          title: 'Report 3',
          slug: 'report-3',
          link: 'https://www.lipsum.com/feed/html',
          image: '',
          createdAt: `${new Date(Date.now())}`,
          updatedAt: `${new Date(Date.now())}`,
        },
      ]
    },
  },
  faq: [
    {
      id: `16519165168431311`,
      question: 'Nullam in massa massa. Integer maximus massa ut odio venenatis accumsan?',
      answer: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
      createdAt: `${new Date(Date.now())}`
    },
    {
      id: `16519165168431312`,
      question: 'Nullam in massa massa. Integer maximus massa ut odio venenatis accumsan?',
      answer: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
      createdAt: `${new Date(Date.now())}`
    },
    {
      id: `16519165168431313`,
      question: 'Nullam in massa massa. Integer maximus massa ut odio venenatis accumsan?',
      answer: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
      createdAt: `${new Date(Date.now())}`
    },
    {
      id: `16519165168431314`,
      question: 'Nullam in massa massa. Integer maximus massa ut odio venenatis accumsan?',
      answer: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
      createdAt: `${new Date(Date.now())}`
    },
    {
      id: `16519165168431315`,
      question: 'Nullam in massa massa. Integer maximus massa ut odio venenatis accumsan?',
      answer: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
      createdAt: `${new Date(Date.now())}`
    },
  ],
  career: {
    jobs: [
      {
        id: '039590353490850934851',
        title: 'Job Openings 1',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id: '039590353490850934852',
        title: 'Job Openings 2',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id: '039590353490850934853',
        title: 'Job Openings 3',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id: '039590353490850934854',
        title: 'Job Openings 4',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id: '039590353490850934855',
        title: 'Job Openings 5',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
    ],
    volunteers: [
      {
        id:`43270572350131`,
        title: 'Volunteer Openings 1',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id:`43270572350132`,
        title: 'Volunteer Openings 2',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id:`43270572350133`,
        title: 'Volunteer Openings 3',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id:`43270572350134`,
        title: 'Volunteer Openings 4',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
      {
        id:`43270572350135`,
        title: 'Volunteer Openings 5',
        description: 'Suspendisse potenti. Vivamus mattis tellus risus, sit amet ornare tortor suscipit sed. Sed sed nibh sollicitudin, rhoncus enim non, mattis leo. Curabitur rhoncus consequat risus ac aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris odio ligula, varius eget vestibulum quis, ultrices in mauris. Nulla mattis aliquam varius. Donec sed massa lectus. Integer blandit leo at pellentesque suscipit. Proin pellentesque tellus non libero venenatis, nec lobortis ante fermentum. Etiam fringilla magna sit amet sodales semper. Quisque ac varius odio. Curabitur dictum efficitur purus. Sed malesuada quam quis convallis tempus.',
        createdAt: `${new Date(Date.now())}`
      },
    ],
    testimonies: [
      {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sem ex, accumsan congue orci a, elementum rhoncus dui. Suspendisse ornare massa vitae quam pretium congue. Vestibulum tempor metus ac libero aliquet vehicula. Maecenas mollis nunc dolor, pellentesque sodales ipsum tempus non. Aliquam sodales nisl quam, a pharetra erat ultrices non.',
        name: 'Daisy',
        createdAt: `${new Date(Date.now())}`
      },
      {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sem ex, accumsan congue orci a, elementum rhoncus dui. Suspendisse ornare massa vitae quam pretium congue. Vestibulum tempor metus ac libero aliquet vehicula. Maecenas mollis nunc dolor, pellentesque sodales ipsum tempus non. Aliquam sodales nisl quam, a pharetra erat ultrices non.',
        name: 'Daisy',
        createdAt: `${new Date(Date.now())}`
      },
      {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sem ex, accumsan congue orci a, elementum rhoncus dui. Suspendisse ornare massa vitae quam pretium congue. Vestibulum tempor metus ac libero aliquet vehicula. Maecenas mollis nunc dolor, pellentesque sodales ipsum tempus non. Aliquam sodales nisl quam, a pharetra erat ultrices non.',
        name: 'Daisy',
        createdAt: `${new Date(Date.now())}`
      },
      {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sem ex, accumsan congue orci a, elementum rhoncus dui. Suspendisse ornare massa vitae quam pretium congue. Vestibulum tempor metus ac libero aliquet vehicula. Maecenas mollis nunc dolor, pellentesque sodales ipsum tempus non. Aliquam sodales nisl quam, a pharetra erat ultrices non.',
        name: 'Daisy',
        createdAt: `${new Date(Date.now())}`
      },
    ],
  },
  donation: {
    list: Array(10).fill({
      id: `12345678912345678911`,
      name : `John Smith ${Math.floor(Math.random() * 99)}`,
      createdAt: `${new Date(Date.now())}`,
      phone: `0812345678911`,
      email: `john.smith@example.com`,
      status: 'new',
      message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id nisl lorem. Quisque id aliquam mi. Phasellus rhoncus tincidunt fermentum. Phasellus vestibulum, magna commodo ultricies dictum, purus tellus tristique orci, rhoncus consequat sapien enim quis leo. Curabitur et dolor tincidunt, convallis enim sed, congue nisl. Integer facilisis sem non velit fermentum,`,
      receipt: ''
    }),
    bank: Array(2).fill({
      id: '29384092341352',
      bankName: 'BCA',
      image: `${IMAGE_PATH}/bank/bca.png`,
      account: 'XXXXXXXXXX',
      owner: 'Red Nose Foundation',
      qr: `${IMAGE_PATH}/bank/bca_qr_1.png`
    })
  },
  messages: Array(10).fill({
    id: '203465161949816',
    name: 'Janet Doe',
    email: 'janetdoe@email.com',
    message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id nisl lorem. Quisque id aliquam mi. Phasellus rhoncus tincidunt fermentum. Phasellus vestibulum, magna commodo ultricies dictum, purus tellus tristique orci, rhoncus consequat sapien enim quis leo. Curabitur et dolor tincidunt, convallis enim sed, congue nisl. Integer facilisis sem non velit fermentum,`,
    status: 'readed',
    createdAt: `${new Date(Date.now())}`,
  }),
}

export default data