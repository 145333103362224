import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DonationsGeneralScreen from './donations/DonationsGeneralScreen'
import DonationsSeratusScreen from './donations/DonationsSeratusScreen'

const DonationsScreen = () => {

  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const [donation, setDonation] = useState({})
  const [page, setPage] = useState(1)


  const donations = [
    {
      type: 'general',
      element: DonationsGeneralScreen
    },
    {
      type: 'seratus',
      element: DonationsSeratusScreen
    },
  ]   

  const CHECK_TYPE = donations.find(x => x.type === params.type)

  
  const navigatePage = (type, page) => {
    navigate({
      pathname: `/cms/donation/${type}`,
      search: `?page=${page || 1}` 
    })
  }  
  
  const paginationOnClickHandler = (pageVal) => navigatePage(CHECK_TYPE.type, pageVal)

  const elementProps = {
    page: page,
    paginationOnClick: paginationOnClickHandler,
    pageNav: [
      {
        title: 'General Donation',
        active: CHECK_TYPE?.type === 'general',
        onClick: () => navigatePage('general')
      },
      {
        title: 'Gerakan 100.000 Pahlawan',
        active: CHECK_TYPE?.type === 'seratus',
        onClick: () => navigatePage('seratus')
      },      
    ]
  }

  useEffect(() => {   
    if(!CHECK_TYPE) return navigate('/cms/donation/general')   
    setDonation(CHECK_TYPE)
    if(!location.search.includes('page')) return navigate({pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search.replace('?', '').split('&').find(x => x.includes('page')).replace('page=', '')
    setPage(currentPage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, navigate, params.type]) 
  
  const Element = donation.element

  return (
    <>
      {Element && <Element {...elementProps}/>}
    </>
  )
}

export default DonationsScreen