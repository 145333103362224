import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import List from '../../../../components/Lists/List/List'
import Typography from '../../../../components/Typography/Typography'
import { useApplyingStepsViewModel } from '../../../../viewModels/useApplyingStepsViewModel'

const CareersApplyingStepsScreen = ({page, paginationOnClick, pageNav}) => {

  const { state, handler } = useApplyingStepsViewModel()

  const navigate = useNavigate()

  useEffect(() => {
    handler.fetch(null, state.search.length > 3 ? {search: state.search} : {})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.search])

  return (
    <CMSLayout title={'Career'} >
      <div className="flex space-x-2 col-span-full">
        {pageNav.map((pgNav, pgNavIdx) => (
          <Button key={pgNavIdx} variant={'outline'} active={pgNav.active} onClick={pgNav.onClick}>
            {pgNav.title}
          </Button>
        ))}
      </div>
      <Card className="flex-col p-8 col-span-full" >
        <div className="flex items-end justify-end w-full">
          <Button  className={'capitalize'} color={'secondary'} onClick={() => navigate(`/cms/career/applying/edit`)}>
            Edit Steps
          </Button>          
        </div>     
        <section className="flex flex-col space-y-12 responsive responsive--article">
          <div>
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-4 text-center'}
            >
              {state.detail.title}
            </Typography>
            <Typography className='text-center'>
              {state.detail.subtitle}
            </Typography>
          </div>
          <List className='w-full space-y-4'>
            {state.detail.steps.map((how, howIdx) => (
              <div key={howIdx} className="w-full">
                <Typography variant={'subtitle1'} weight={'semibold'} className='mb-1'>
                  {howIdx+1}. {how?.title}
                </Typography>
                <Typography>
                  {how?.description}
                </Typography>
              </div>
            ))}
          </List>
        </section>
      </Card>
    </CMSLayout>
  )
}

export default CareersApplyingStepsScreen