import React, { useEffect, useState } from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../../../components/Cards/Card/Card'
import ImageContainer from '../../../../components/ImageContainer/ImageContainer'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import Typography from '../../../../components/Typography/Typography'
import { useGetFullDate } from '../../../../hooks/useGetFullDate'
import { useSeratusViewModel } from '../../../../viewModels/useSeratusViewModel'


const DonationSeratusScreen = () => {
  
  const { state, handler } = useSeratusViewModel()

  const params = useParams()
  const navigate = useNavigate()

  const [currentAlert, setCurrentAlert] = useState(null)

  const getFullDate = useGetFullDate()

  const deleteHandler = () => {
    handler.deleteSeratus(params.id)
    setCurrentAlert(null)
  }

  const deleteAlert = {
    title: 'Delete Donation',
    subtitle: 'Are you sure to delete?',
    variant: 'danger',
    action: [
      {
        title: 'Yes',
        variant: 'outline',
        color: 'secondary',
        onClick: () => deleteHandler(),
        className: 'w-full'
      },
      {
        title: 'No',
        variant: '',
        color: 'secondary',
        onClick: () => setCurrentAlert(null),
        className: 'w-full'
      },
    ],
  }

 

  useEffect(() => {      
    handler.fetchSeratus(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useEffect(() => {
    if(!state.toast) return
    setCurrentAlert({
      title: 'Donation Deleted',
      subtitle: state.toast.message,
      variant: 'success',
      action: [
        {
          title: 'Back to Donation',
          variant: 'outline',
          color: 'secondary',
          onClick: () => navigate('/cms/donation/seratus'),
          className: 'w-full'
        },
      ],
    })
  }, [navigate, state.toast])

  return (
    <>
      <CMSLayout 
        title={`Donation Detail`}
        action={[
          {
            title: 'Delete',
            variant: 'outline',
            color: 'secondary',
            icon: <IoTrashOutline />,
            onClick: () => setCurrentAlert(deleteAlert)
          }
        ]}
        alert={currentAlert}
      >
        <Card className="grid flex-col grid-cols-4 gap-2 p-5 col-span-full">
          <Typography
            variant={'h6'}
            weight={'semibold'}
            className={'col-span-full'}
          > 
            Information
          </Typography>
          <div className="grid grid-cols-3 col-span-2">
            <Typography variant={'body1'} weight={'medium'}>
              Donator
            </Typography>
            <Typography className='col-span-2' variant={'subtitle1'}>
              {state.seratus?.name}
            </Typography>
          </div>
          <div className="grid grid-cols-3 col-span-2">
            <Typography variant={'body1'} weight={'medium'}>
              ID
            </Typography>
            <Typography className='col-span-2' variant={'subtitle1'}>
              {state.seratus?.id}
            </Typography>
          </div>
          <div className="grid grid-cols-3 col-span-2">
            <Typography variant={'body1'} weight={'medium'}>
              Email
            </Typography>
            <Typography className='col-span-2' variant={'subtitle1'}>
              {state.seratus?.email}
            </Typography>
          </div>
          <div className="grid grid-cols-3 col-span-2">
            <Typography variant={'body1'} weight={'medium'}>
              Donated At
            </Typography>
            <Typography className='col-span-2' variant={'subtitle1'}>
              {getFullDate(state.seratus?.createdAt)}
            </Typography> 
          </div>  
          <div className="grid grid-cols-3 col-span-2">
            <Typography variant={'body1'} weight={'medium'}>
              Phone Number
            </Typography>
            <Typography className='col-span-2' variant={'subtitle1'}>
              {state.seratus?.phone}
            </Typography> 
          </div>
                  
        </Card>
        <Card className="flex-col col-span-6 gap-2 p-5">
          <Typography
            variant={'h6'}
            weight={'semibold'}
            className={'col-span-full'}
          > 
            Message
          </Typography>
          <Typography className='col-span-2' lineHeight={'relaxed'}>
            {state.seratus?.message}
          </Typography>
        </Card>
        <Card className="flex-col col-span-6 gap-2 p-5">
          <Typography
            variant={'h6'}
            weight={'semibold'}
            className={'col-span-full'}
          > 
            Receipt
          </Typography>
          {state.seratus?.receipt &&  <ImageContainer ratio={{width: 16, height:9}} src={state.seratus?.receipt?.url} fit={'contain'} onClick={(e) => window.open(e.target.src, '_blank')}/>}
        </Card>      
        
      </CMSLayout>
    </>
  )
}

export default DonationSeratusScreen