import React, { useEffect, useState } from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import {  useNavigate, useParams } from 'react-router-dom'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Typography from '../../../components/Typography/Typography'
import { useGetFullDate } from '../../../hooks/useGetFullDate'
import { useMessageViewModel } from '../../../viewModels/useMessageViewModel'

const MessageScreen = () => {

  const { state, handler } = useMessageViewModel()

  const params = useParams()


  const [currentAlert, setCurrentAlert] = useState(null)

  const getFullDate = useGetFullDate()
  const navigate = useNavigate()

  const deleteHandler = () => {
    handler.deleteMessage(state.message.data.data._id)
    setCurrentAlert(null)
  }

  const deleteAlert = {
    title: 'Delete Message',
    subtitle: 'Are you sure to delete?',
    variant: 'danger',
    action: [
      {
        title: 'Yes',
        variant: 'outline',
        color: 'secondary',
        onClick: () => deleteHandler(),
        className: 'w-full'
      },
      {
        title: 'No',
        variant: '',
        color: 'secondary',
        onClick: () => setCurrentAlert(null),
        className: 'w-full'
      },
    ],
  }

  useEffect(() => {
    handler.fetchMessage(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!state.toast) return
    setCurrentAlert({
      title: 'Message Deleted',
      subtitle: state.toast.message,
      variant: 'success',
      action: [
        {
          title: 'Back to Messages',
          variant: 'outline',
          color: 'secondary',
          onClick: () => navigate('/cms/messages'),
          className: 'w-full'
        },
      ],
    })
  }, [navigate, state.toast])

  return (
    <>
      {state.message.data && (
        <CMSLayout 
          title={`Donation Detail`}
          action={[
            {
              title: 'Delete',
              variant: 'outline',
              color: 'secondary',
              icon: <IoTrashOutline />,
              onClick: () => setCurrentAlert(deleteAlert)
            }
          ]}
          alert={currentAlert}
        >
          <Card className="grid flex-col grid-cols-4 gap-2 p-5 col-span-full">
            <Typography
              variant={'h6'}
              weight={'semibold'}
              className={'col-span-full'}
            > 
              Information
            </Typography>
            <div className="grid grid-cols-3 col-span-2">
              <Typography variant={'body1'} weight={'medium'}>
                Name
              </Typography>
              <Typography className='col-span-2' variant={'subtitle1'}>
                {state.message.data?.data?.name}
              </Typography>
            </div>
            <div className="grid grid-cols-3 col-span-2">
              <Typography variant={'body1'} weight={'medium'}>
                ID
              </Typography>
              <Typography className='col-span-2' variant={'subtitle1'}>
                {state.message.data?.data?._id}
              </Typography>
            </div>
            <div className="grid grid-cols-3 col-span-2">
              <Typography variant={'body1'} weight={'medium'}>
                Email
              </Typography>
              <Typography className='col-span-2' variant={'subtitle1'}>
                {state.message.data?.data?.email}
              </Typography>
            </div>
            <div className="grid grid-cols-3 col-span-2">
              <Typography variant={'body1'} weight={'medium'}>
                Sent At
              </Typography>
              <Typography className='col-span-2' variant={'subtitle1'}>
                {getFullDate(state.message.data?.data?.createdAt)}
              </Typography> 
            </div>                  
          </Card>
          <Card className="flex-col gap-2 p-5 col-span-full">
            <Typography
              variant={'h6'}
              weight={'semibold'}
              className={'col-span-full'}
            > 
              Message
            </Typography>
            <Typography className='col-span-2' lineHeight={'relaxed'}>
              {state.message.data?.data?.message}
            </Typography>
          </Card>      
        </CMSLayout>
      )}
    </>
  )
}

export default MessageScreen