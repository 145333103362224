import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'

const MainScreen = () => {

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/cms/donation')
  }, [navigate])

  return (
    <CMSLayout>
      CMS
    </CMSLayout>
  )
}

export default MainScreen