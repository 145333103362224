import React, { useState } from 'react'
import { IoEyeOutline, IoPencilOutline, IoTrashOutline } from 'react-icons/io5'
import Alert from '../../../components/Alert/Alert'
import IconContainer from '../../../components/IconContainer/IconContainer'

const ActionButtons = ({
  deleteAlert,
  deleteAction,
  editAction,
  seeDetailAction,
}) => {

  const [currentAlert, setCurrentAlert] = useState(null)

  const deleteAlertConfig = {
    title: deleteAlert?.title || 'Delete',
    subtitle: deleteAlert?.subtitle || 'Are you sure want to delete?',
    variant: 'danger',
    action: [
      {
        title: 'Yes',
        variant: 'outline',
        color: 'secondary',
        onClick: () => {
          deleteAction()
          setCurrentAlert(null)
        },
        className: 'w-full'
      },
      {
        title: 'No',
        variant: '',
        color: 'secondary',
        onClick: () => setCurrentAlert(null),
        className: 'w-full'
      },
    ],
  }

  return (
    <>
      <div className='flex space-x-1'>
        {deleteAction && (
          <button className={''} title={'Delete'} onClick={() => setCurrentAlert(deleteAlertConfig)}>
            <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md hover:text-theme-secondary hover:border-theme-secondary'>
              <IoTrashOutline />
            </IconContainer>
          </button>
        )}      
        {editAction && (
          <button className={''} title={'Edit'} onClick={editAction}>
            <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md hover:text-theme-primary hover:border-theme-primary'>
              <IoPencilOutline />
            </IconContainer>
          </button>  
        )}
        {seeDetailAction && (
          <button className={''} title={'See Detail'} onClick={seeDetailAction}>
            <IconContainer className='w-8 h-8 text-gray-400 transition duration-150 border border-gray-400 rounded-md hover:text-theme-primary hover:border-theme-primary'>
              <IoEyeOutline />
            </IconContainer>
          </button>   
        )}   
        
      </div>
      {currentAlert && (
        <Alert 
          title={currentAlert.title}
          subtitle={currentAlert.subtitle}
          variant={currentAlert.variant}
          icon={currentAlert.icon}
          action={currentAlert.action}
        />
      )}  
    </>
  )
}

export default ActionButtons