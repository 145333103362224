import axios from "axios"
import {  useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL} from "../utils/urls"

export const useVideoViewModel = () => {

  const initialState = {
    video: {
      previous: null,
      current: {title: '', image: '', description: '', link: ''},
      next: null
    }
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()

  const [search, setSearch] = useState('')
  const [videoList, setVideoList] = useState([])
  const [video, setVideo] = useState(initialState.video)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setVideo({...video, current: {...video?.current, [e.target.name]: e.target.value}})
  }

  const imageInputOnChange = (e, data) => {
    setVideo({...video, current: {...video?.current, image: data}})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'title',
      title: 'title',
      value: video?.current?.title || '',
      onChange: textFieldOnChange,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
        invalid: 'Video with the same title is already exist'
      },
      required: true
    },
    {
      name: 'image',
      title: 'image',
      value: video?.current?.image?.url || video?.current?.image || '',
      onChange: imageInputOnChange,
      message: {
        required: 'You have to upload image',
      },
      validation: errors?.image?.message,
      autoComplete: 'off',
      required: true
    },
    {
      name: 'link',
      title: 'embed link',
      value: video?.current?.link || '',
      onChange: textFieldOnChange,
      validation: errors?.link?.message,
      message: {
        required: 'You have to upload link',
      },
      required: true,
    },
  ]
  

  const alerts = {
    add: {
      title: `Video Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media/videos')
        }
      ]
    },
    edit: {
      title: `Video Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media/videos')
        }
      ]
    },
    delete: {
      title: `Video Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media/videos')
        }
      ]
    }
  }


  const fetchVideo= async(id, params = {}) => {
    setLoading(true)
    const config = {
      url: `/video${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setVideoList(data) : setVideo(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addVideo = async() => {

    const dataToAppendEntries = Object.entries(video?.current)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)

    const config = {
      url: `/video`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setVideo(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editVideo = async() => {
    const dataToAppendEntries = Object.entries(video?.current)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)
    const config = {
      url: `/video/${video?.current._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setVideo(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteVideo = async(id, callback) => {
    const config = {
      url: `/video/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setVideo(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addVideo() : editVideo()
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: videoList,
      detail: video,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchVideo,
      delete: deleteVideo,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}