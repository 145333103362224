import React, { useEffect } from 'react'
import StatisticJumbotron from '../../../components/Jumbotrons/StatisticJumbotron/StatisticJumbotron'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import useToggle from '../../../hooks/useToggle'
import AboutSection from '../../sections/AboutSection'
import DonationJumbotron from './components/DonationJumbotron'
import DonationSection from '../../sections/DonationSection'
import FAQSection from '../../sections/FAQSection'
import HeroSection from '../../sections/HeroSection'
import PerformancesSection from '../../sections/PerformancesSection'
import PillarsAndrogramsSection from '../../sections/PillarsAndrogramsSection'
import { useStatisticViewModel } from '../../../viewModels/useStatisticViewModel'


const HomeScreen = () => {

  const [overlay, toggleOverlay] = useToggle(false)

  const { state, handler } = useStatisticViewModel()
  
  const pinOnClickHandler = (dataObj, callback) => {
    toggleOverlay()
    callback && callback()
  } 

  useEffect(() => {
    handler.fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const statisticData = [
    {
      amount: state.detail?.helps?.kids,
      title: 'Children We Reach'
    },
    {
      amount: state.detail?.helps?.countries,
      title: 'Countries'
    },
    {
      amount: state.detail?.helps?.cities,
      title: 'Cities'
    },
  ]

  return (
    <GlobalLayout overlay={overlay} overlayOnClick={toggleOverlay}>
    
      <HeroSection />
      <AboutSection className={`section`} />
      <section className='responsive xl:py-20'>
        <StatisticJumbotron data={statisticData}/>
      </section>
      <PillarsAndrogramsSection className={`section`} />      
      <PerformancesSection className={`section`} pinOnClick={pinOnClickHandler}/>
      <DonationJumbotron />
      <DonationSection className={`section`}/>
      <FAQSection className={`section`}/>
    </GlobalLayout>
  )
}

export default HomeScreen