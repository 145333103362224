import React from 'react'
import './Button.css'

const Button = ({
  component,
  className = '',
  children,
  color,
  size,
  variant,
  onClick,
  disabled,
  target,
  href,
  active
}) => {

  const Component = component || 'button'

  const buttonVariants = ['default', 'outline', 'pill', 'round', 'round-outline']
  const buttonSizes = ['xs', 'sm', 'md', 'lg', 'xl']
  const buttonColors = ['primary', 'secondary', 'tertiary', 'orange', 'gray']
  
  const CHECK_BUTTON_SIZE = buttonSizes.find(x => x === size) ? size : 'md'
  const CHECK_BUTTON_VARIANT = buttonVariants.find(x => x === variant) ? variant : buttonVariants[0]
  const CHECK_BUTTON_COLOR = buttonColors.find(x => x === color) ? color : buttonColors[0]
  const CHECK_BUTTON_ACTIVE = active ? 'active' : ''

  return (
    !disabled ? (
      <Component href={href} className={`btn btn--${CHECK_BUTTON_SIZE} btn--${CHECK_BUTTON_COLOR} btn--${CHECK_BUTTON_VARIANT} ${CHECK_BUTTON_ACTIVE} ${className}`} target={target} rel="noopener noreferrer" onClick={onClick}>
        {children}
      </Component>
    ) : (
      <div className={`btn btn--${CHECK_BUTTON_SIZE} btn--disabled ${className}`}>{children}</div>
    )
  )
}

export default Button