import React, { useEffect } from 'react'
import useToggle from '../../../hooks/useToggle'

const PopUp = ({
  active,
  onClick,
  children,
  className = '',
  zIndex = 40
}) => {

  const [render, toggleRender] = useToggle(false)
  const [visible, toggleVisible] = useToggle(false)

  const CHECK_VISIBLE = visible ? styles.visible : styles.hidden

  useEffect(() => {
    const renderHandler = () => {
      toggleRender(true)
      setTimeout(() => {
        toggleVisible(true)
      }, 200)
    }
    const hideHandler = () => {
      toggleVisible(false)
      setTimeout(() => {
        toggleRender(false)
      }, 500)
    }
    if (!active) return hideHandler()
    if (active) return renderHandler()

  }, [active, toggleRender, toggleVisible])
  
  return (
    render ? (
      <div className={`${styles.popup} ${CHECK_VISIBLE} ${className}`} onClick={onClick} style={{zIndex}}>
        {children}
      </div>
    ) : ''
  )
}

export default PopUp

const styles = {
  popup: 'fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition duration-500 ease-in-out',
  hidden: 'scale-0',
  visible: 'scale-100'
}