import React from 'react'
import './Typography.css'

const Typography = ({
  className = '',
  variant,
  component,
  weight,
  lineHeight,
  children
}) => {

  const components = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span' ]
  const weights = ['thin', 'light', 'extralight', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']
  const lineHeights = ["none", "tight", "snug", "normal", "relaxed", "loose",]
  
  const checkTypographyComponent = (defaultComponent) => components.find(x => x ===  component) ? component : defaultComponent
  const checkTypographyWeight = (defaultWeight) => weights.find(x => x ===  weight) ? weight : defaultWeight

  const variants = [
    {
      variant: 'h1',
      component: checkTypographyComponent('h1'),
      weight: checkTypographyWeight('bold')
    }, 
    {
      variant: 'h2',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('semibold')
    }, 
    {
      variant: 'h3',
      component: checkTypographyComponent('h3'),
      weight: checkTypographyWeight('medium')
    }, 
    {
      variant: 'h4',
      component: checkTypographyComponent('h4'),
      weight: checkTypographyWeight('normal')
    }, 
    {
      variant: 'h5',
      component: checkTypographyComponent('h5'),
      weight: checkTypographyWeight('normal')
    }, 
    {
      variant: 'h6',
      component: checkTypographyComponent('h6'),
      weight: checkTypographyWeight('normal')
    }, 
    {
      variant: 'subtitle1',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('medium')
    }, 
    {
      variant: 'subtitle2',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('normal')
    }, 
    {
      variant: 'body1',
      component: checkTypographyComponent('p'),
      weight: checkTypographyWeight('normal')
    }, 
    {
      variant: 'body2',
      component: checkTypographyComponent('p'),
      weight: checkTypographyWeight('normal')
    }, 
  ]
  
  const CHECK_TYPOGRAPHY_VARIANT = variants.find(x => x.variant === variant) || variants.find(x => x.variant === 'body1')
  const CHECK_TYPOGRAPHY_lineHeight = lineHeights.find(x => x === lineHeight) ? lineHeight : 'normal'

  const Component = CHECK_TYPOGRAPHY_VARIANT.component

  return (
    <Component className={`tpg tpg--${CHECK_TYPOGRAPHY_VARIANT.variant} tpg--${CHECK_TYPOGRAPHY_VARIANT.weight} tpg--line-height-${CHECK_TYPOGRAPHY_lineHeight} ${className}`}>
      {children}
    </Component>
  )
}

export default Typography