import React from 'react'

const ShapeMask = ({
  className,
  maskImage,
  backgroundImage,
  backgroundPosition,
  children,
  maskSize,
  maskPosition,
  overlay
}) => {

  return (
    <div 
      style={{
        WebkitMaskImage: `url(${maskImage})`,
        maskImage: `url(${maskImage})`,
        WebkitMaskSize: maskSize || 'cover',
        maskSize: maskSize || 'cover',
        WebkitMaskPosition: maskPosition || 'top',
        maskPosition: maskPosition || 'top',
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        backgroundImage: backgroundImage && `url(${backgroundImage})`,
        backgroundRepeat: backgroundImage && 'no-repeat',
        backgroundSize: backgroundImage && 'cover',
        backgroundPosition: backgroundPosition || 'top',
      }}
      className={`relative ${className}`}
    >
      {overlay && <div className='absolute inset-0 bg-opacity-50 bg-theme-primary'></div>}
      <div className="relative w-full h-full">
        {children}
      </div>
    </div>
  )
}

export default ShapeMask