import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useNewsViewModel = () => {

  const initialState = {
    news: {
      previous: null,
      current: {title: '', image: '', description: '', link: ''},
      next: null
    }
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [newsList, setNewsList] = useState({records: []})
  const [news, setNews] = useState(initialState.news)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setNews({...news, current: {...news.current, [e.target.name]: e.target.value}})
  }

  const imageInputOnChange = (e, data) => {
    setNews({...news, current: {...news.current, image: data} })
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'title',
      title: 'title',
      value: news?.current?.title || '',
      onChange: textFieldOnChange,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
        invalid: 'News with the same title is already exist'
      },
      required: true
    },
    {
      name: 'image',
      title: 'image',
      value: news?.current?.image?.url || news?.current?.image || '',
      onChange: imageInputOnChange,
      message: {
        required: 'You have to upload image',
      },
      validation: errors?.image?.message,
      autoComplete: 'off',
      required: true
    },
    {
      name: 'description',
      title: 'description',
      value: news?.current?.description || '',
      onChange: textFieldOnChange,
      validation: errors?.description?.message,
      message: {
        required: 'You have to write the description',
      },
      required: true
    },
    {
      name: 'link',
      title: 'link',
      value: news?.current?.link || '',
      onChange: textFieldOnChange,
      validation: errors?.link?.message,
      message: {
        required: 'You have to upload link',
      }
    },
  ]
  

  const alerts = {
    add: {
      title: `News Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media')
        }
      ]
    },
    edit: {
      title: `News Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media')
        }
      ]
    },
    delete: {
      title: `News Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/media')
        }
      ]
    },
    
  }


  const fetchNews= async(id, params = {}, callback) => {
    setLoading(true)
    const config = {
      url: `/news${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setNewsList(data) : setNews(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const addNews = async(callback) => {

    const dataToAppendEntries = Object.entries(news.current)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)

    const config = {
      url: `/news`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setNews(data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }
  
  const editNews = async(callback) => {
    const dataToAppendEntries = Object.entries(news.current)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)
    const config = {
      url: `/news/${news?.current?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setNews(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const deleteNews = async(id, callback) => {
    const config = {
      url: `/news/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setNews(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addNews() : editNews()
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: newsList,
      detail: news,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchNews,
      delete: deleteNews,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}