import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import MediaNewsScreen from './media/MediaNewsScreen'
import MediaReportScreen from './media/MediaReportScreen'
import MediaVideoScreen from './media/MediaVideoScreen'


const MediaScreen = () => {

  const params = useParams()
  const methods = ['add', 'edit']
  const [CHECK_METHOD] = useState(methods.find(x => params.method === x))
  
 
  const types = [
    {
      type: 'news',
      element: <MediaNewsScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'video',
      element: <MediaVideoScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'report',
      element: <MediaReportScreen method={CHECK_METHOD}/>
    }
  ]

  const [CHECK_TYPE] = useState(types.find(x => params.type === x.type))  

  return CHECK_TYPE.element
}

export default MediaScreen