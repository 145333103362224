
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Alert from '../../../../components/Alert/Alert'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import ImageInput from '../../../../components/ImageInput/ImageInput'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import List from '../../../../components/Lists/List/List'
import Textarea from '../../../../components/Textarea/Textarea'
import TextField from '../../../../components/TextField/TextField'
import Typography from '../../../../components/Typography/Typography'
import { useApplyingStepsViewModel } from '../../../../viewModels/useApplyingStepsViewModel'
import ActionButtons from '../../../components/ActionButtons/ActionButtons'



const CareerApplyingStepsScreen = ({method}) => {

  const params = useParams()

  const { state, handler, element, bundle } = useApplyingStepsViewModel(method)

   
  useEffect(() => {
    if(method !== 'edit') return
    handler.fetch(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method])

  return (
    <>
      <CMSLayout alert={state.alert} title={`${method} Applying Steps ${params.id ? `#${params.id}` : ''}`} >
        <Card className='flex flex-col p-5 col-span-full'>
          <div className="space-y-2 responsive responsive--article">
            {element.inputs.map((inp, inpIdx) => (
              <div  key={inpIdx} className={'space-y-1'}>
                <Typography variant={'subtitle1'} weight={'semibold'} className={'capitalize'}>
                  {inp.title}
                </Typography>
                {inp.name === 'description' ? (
                  <Textarea
                    {...inp}
                  />            
                ) : inp.name === 'image' ? (
                  <ImageInput
                    imageFit='contain'
                    {...inp}
                  />
                ) : (
                  <TextField               
                    {...inp}
                  />
                )}
              </div>
            ))}
            <Card className='flex flex-col p-5 space-y-5'>
              <div className="flex items-center justify-between w-full">
                <Typography variant={'subtitle1'} weight={'semibold'} className={'capitalize'}>
                  Steps
                </Typography>
                <Button color={'secondary'} size={'sm'} onClick={() => bundle.step.handler.alert.show('add')}>
                  Add Step
                </Button>
              </div>
              <List className='w-full space-y-4'>
                {state.detail.steps.length > 0 ? (
                  state.detail.steps.map((how, howIdx) => (
                    <Card key={howIdx} className="flex flex-col w-full p-5">
                      <div className="flex justify-between w-full">
                        <Typography variant={'subtitle1'} weight={'semibold'} className='mb-1'>
                          {howIdx+1}. {how?.title}
                        </Typography>
                        <ActionButtons deleteAction={() => bundle.step.handler.delete(how, howIdx)} editAction={() => bundle.step.handler.alert.show('edit', how, howIdx)}/>
                      </div>
                      <Typography>
                        {how?.description}
                      </Typography>
                    </Card>
                  ))
                ) : (
                  <div>No Step</div>
                )}
              </List>
            </Card>
            <div className='w-full pt-5'>
              <Button className='w-full capitalize' disabled={state.loading} onClick={() => handler.submit(method)}>
                {method} Applying Steps
              </Button>
            </div>
          </div>
        </Card>
      </CMSLayout>
      {bundle.step.state.alert && (
        <Alert 
          title={(
            <div className='capitalize'>{bundle.step.state.alert.method} Step</div>
          )}
          close
          closeFunction={bundle.step.handler.alert.hide}
        >
          <div className='flex flex-col w-full mt-6 space-y-4'>
            {bundle.step.element.inputs.map((inp, inpIdx) => (
              <div className='flex flex-col items-start w-full' key={inpIdx}>
                <Typography variant={'subtitle1'} weight={'semibold'} className='mb-1 capitalize'>
                  {inp.title}
                </Typography>
                {inp.title === 'description' ? (
                  <Textarea rows={10} {...inp}/>
                ) : (
                  <TextField {...inp} wrapperClassName={'w-full'}/>
                )}
              </div>
            ))}
          </div>
          <div className='w-full mt-6'>
            <Button className='w-full capitalize' onClick={bundle.step.handler[bundle.step.state.alert.method]} disabled={bundle.step.state.detail.description.length === 0 || bundle.step.state.detail.title.length === 0}>
              {bundle.step.state.alert.method} Step
            </Button>
          </div>
        </Alert>
      )}
    </>
  )
}

export default CareerApplyingStepsScreen