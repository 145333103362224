import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import List from '../../../components/Lists/List/List'
import ListCard from '../../../components/Lists/ListCard/ListCard'
import Textarea from '../../../components/Textarea/Textarea'
import TextField from '../../../components/TextField/TextField'
import Toast from '../../../components/Toast/Toast'
import Typography from '../../../components/Typography/Typography'
import { BANK_ACCOUNT_VALIDATION, BANK_NAME_VALIDATION, BANK_OWNER_VALIDATION, EMAIL_VALIDATION, MESSAGE_VALIDATION, NAME_VALIDATION, PHONE_VALIDATION, RECEIPT_VALIDATION } from '../../../constants/validationConstants'
import { useValidateValue } from '../../../hooks/useValidateValue'
import { useSeratusViewModel } from '../../../viewModels/useSeratusViewModel'


const SeratusRibuDonationSection = ({className = ''}) => {
  
  const { state, handler } = useSeratusViewModel()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [receipt, setReceipt] = useState()
  const [bank, setBank] = useState('')
  const [account, setAccount] = useState('')
  const [owner, setOwner] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({})


  const receiptInput = useRef(null)
  const validateValue = useValidateValue()

  const onChangeHandler = (e, callback) => {
    callback(e.target.value)
  }

  const receiptOnChangeHandler = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      let errorsObj = {...errors}
      if (files[0].size / 1000 <= 1000) {
        delete errorsObj.receipt
        setErrors(errorsObj)
        setReceipt(files[0])
        return
      }
      errorsObj.receipt = { type: 'invalid', message: 'Your file is more than 1 MB'}    
      setErrors(errorsObj)
      receiptInput.current.value = ''
    }
  };

  const removeReceipt = () => {
    let errorsObj = {...errors}
    receiptInput.current.value = ''
    delete errorsObj.receipt
    setErrors(errorsObj)
    setReceipt();
  };

  const textFields = [
    {
      title: 'Personal Information',
      field: [
        {
          label: 'Your Name',
          name: 'name',
          type: 'text',
          value: name,
          onChange: (e) => onChangeHandler(e, setName),
          required: true,
          validation: errors?.name?.message,
          message: NAME_VALIDATION.message  ,
        },
        {
          label: 'Your Phone',
          name: 'phone',
          type: 'number',
          value: phone,
          wrapperClassName: 'md:w-[49%]',
          onChange: (e) => onChangeHandler(e, setPhone),
          required: true,
          pattern: PHONE_VALIDATION.pattern,
          message: PHONE_VALIDATION.message,
          validation: errors?.phone?.message,
        },
        {
          label: 'Your Email',
          name: 'email',
          type: 'text',
          value: email,
          wrapperClassName: 'md:w-[49%]',
          onChange: (e) => onChangeHandler(e, setEmail),
          required: true,
          pattern: EMAIL_VALIDATION.pattern,
          message: EMAIL_VALIDATION.message,
          validation: errors?.email?.message
        },
      ]
    },
    {
      title: 'Bank Informations',
      field: [
        {
          label: 'Bank Name',
          name: 'bank',
          type: 'text',
          value: bank,
          required: true,
          onChange: (e) => onChangeHandler(e, setBank),
          message: BANK_NAME_VALIDATION.message,
          validation: errors?.bank?.message
        },
        {
          label: 'Account Number',
          name: 'account',
          type: 'number',
          value: account,
          required: true,
          onChange: (e) => onChangeHandler(e, setAccount),
          message: BANK_ACCOUNT_VALIDATION.message,
          validation: errors?.account?.message
        },
        {
          label: 'Account Owner Name',
          name: 'owner',
          type: 'text',
          value: owner,
          onChange: (e) => onChangeHandler(e, setOwner),
          required: true,
          message: BANK_OWNER_VALIDATION.message,
          validation: errors?.owner?.message
        },
      ]
    },
    {
      title: 'Receipt and Message',
      field: [
        {
          label: 'Transfer Receipt (Max. 1MB)',
          name: 'receipt',
          type: 'file',
          value: receipt,
          className: 'text-sm',
          onChange: receiptOnChangeHandler,
          required: true,
          message: RECEIPT_VALIDATION.message,
          validation: errors?.receipt?.message,
          accept: ".png, .jpg, .jpeg"
        },
        {
          label: 'Message',
          name: 'message',
          value: message,
          className: 'text-sm',
          onChange: (e) => onChangeHandler(e, setMessage),
          message: MESSAGE_VALIDATION.message,
          validation: errors?.message?.message,
          component: 'textarea',
          minLength: 3,
          rows: 10,
        },
      ]
    }
  ]

  

  const submit = (e) => {
    e.preventDefault()
    let fieldArr = []
    textFields.forEach((field) => {
      field.field.forEach(fld => {
        fieldArr.push(fld)
      })
    })
    const valueValidation = validateValue(fieldArr)
    setErrors(valueValidation)
    if(valueValidation) return
    let dataToSend = {}
    fieldArr.forEach(fld => {
      dataToSend[fld.name] = fld.value
    })
    handler.sendSeratus(dataToSend)
  }

  useEffect(() => {
    handler.fetchBanks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section id='donation' className={`flex flex-col w-full lg:flex-row lg:space-x-10 section space-y-16 lg:space-y-0 ${className}`}>
        <div className="flex flex-col w-full">
          <div className='flex flex-col w-full top-32'>
            <Typography 
              variant={'subtitle1'}
              weight={'semibold'}
              className={'mb-3'}
            >
              Donation
            </Typography>
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-5 text-theme-primary'}
            >
              Be a Pahlawan Now!
            </Typography>
            <Typography variant={'body1'} className={'mb-8'}>
              Below are the informations for our bank accounts:
            </Typography>
            <List className='space-y-2'>
              {state.banks && state.banks.map((ba, baIdx) => (
                <ListCard className='w-full divide-x' key={baIdx}>
                  <div className="flex flex-col w-full h-full pr-4">
                    <ImageContainer src={`${ba.logo.url}`} alt={ba.bank} className={'w-20 mb-2'} fit={'contain'} ratio={{width: 16, height: 9}}/>
                    <List className='space-y-2'>
                      <div className='grid md:grid-cols-2'>
                        <Typography variant={'body2'}>Account Number</Typography>
                        <Typography variant={'body2'} weight={'semibold'} className={''}>{ba.account}</Typography>
                      </div>
                      <div className='grid md:grid-cols-2'>
                        <Typography variant={'body2'}>Owner Name</Typography>
                        <Typography variant={'body2'} weight={'semibold'} className={''}>{ba.owner}</Typography>
                      </div>
                      {ba.swift && (
                        <div className='grid md:grid-cols-2'>
                          <Typography variant={'body2'}>Swift Code</Typography>
                          <Typography variant={'body2'} weight={'semibold'} className={''}>{ba.swift}</Typography>
                        </div>
                      )}
                    </List>
                  </div>
                  <div className="flex-shrink-0 w-1/2 pl-4 md:w-1/4">
                    <ImageContainer src={`${ba.qr.url}`} className={'w-full'}/>
                  </div>
                </ListCard>
              ))}
            </List>
          </div>
        </div>
        <form  onSubmit={submit} encType={'multipart/form-data'} className="flex flex-col w-full">
          <Card className='flex flex-col w-full p-5 space-y-5 shadow-lg'>
            {textFields.map((tf, tfIdx) => (
              <div key={tfIdx} className={'space-y-3 w-full'}>
                {tf.title && <Typography variant={'subtitle2'} weight={'semibold'} className={'border-b pb-1'}>{tf.title}</Typography>}
                <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:flex-wrap">
                  {tf.field.map((field, fieldIdx) => (
                    field.type === 'file' ? (
                      <div key={fieldIdx} className={'flex flex-col w-full'}>
                        <label className={'text-xs font-semibold mb-2'} htmlFor="receipt">{field.label}</label>
                        <input ref={receiptInput} id='receipt' type="file" className='text-sm' onChange={field.onChange} accept="image/png, image/jpg, image/jpeg"/>
                        <div className='mt-1 text-xs'>
                          {field.validation && (
                            <span className='text-theme-secondary'>{field.validation}</span>
                          )}
                        </div>
                        {receipt && (
                          <div className='flex flex-col w-full mt-4'>
                            <ImageContainer
                              fit='contain'
                              className='overflow-hidden border rounded-xl'
                              src={URL.createObjectURL(receipt)}
                              alt="Thumb"
                              ratio={{width: 16, height:9}}
                            />
                          <button className='mt-2 text-sm text-center text-red-600' onClick={removeReceipt}>Remove This Image</button>
                          </div>
                        )}
                      </div>
                    ) : field.component === 'textarea' ? (
                      <Textarea 
                      key={tfIdx}
                        {...field}
                      />
                    ) : (
                      <TextField
                        wrapperClassName='w-full'
                        key={fieldIdx}
                        {...field}
                      />
                    )
                  ))}
                </div>
              </div>
            ))}
            <Button color={'secondary'} disabled={state.seratusLoading}>Submit Donation</Button>
          </Card>
        </form>
      </section>
      <Toast active={state.toast} deactiveFunc={handler.closeToast} message={state.toast?.message} />
    </>
  )
}

export default SeratusRibuDonationSection