import axios from "axios"
import {  useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useVolunteerViewModel = () => {

  const initialState = {
    volunteer: {title: '', description: '', link: ''}
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [volunteerList, setVolunteerList] = useState({records: []})
  const [volunteer, setVolunteer] = useState(initialState.volunteer)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setVolunteer({...volunteer, [e.target.name]: e.target.value})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'title',
      title: 'title',
      value: volunteer?.title || '',
      onChange: textFieldOnChange,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
        invalid: 'Volunteer with the same title is already exist'
      },
      required: true
    },
    {
      name: 'description',
      title: 'description',
      value: volunteer?.description || '',
      onChange: textFieldOnChange,
      validation: errors?.description?.message,
      message: {
        required: 'You have to write the description',
      },
      required: true
    },
    {
      name: 'link',
      title: 'link to volunteer',
      value: volunteer?.link || '',
      onChange: textFieldOnChange,
      validation: errors?.link?.message,
      message: {
        required: 'You have to upload link',
      },
      required: true,
    },
  ]
  

  const alerts = {
    add: {
      title: `Volunteer Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/volunteers')
        }
      ]
    },
    edit: {
      title: `Volunteer Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/volunteers')
        }
      ]
    },
    delete: {
      title: `Volunteer Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/volunteers')
        }
      ]
    }
  }


  const fetchVolunteer= async(id, params = {}) => {
    setLoading(true)
    const config = {
      url: `/volunteer${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setVolunteerList(data) : setVolunteer(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addVolunteer = async() => {


    setLoading(true)

    const config = {
      url: `/volunteer`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: volunteer,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setVolunteer(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editVolunteer = async() => {
    const dataToAppendEntries = Object.entries(volunteer)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)
    const config = {
      url: `/volunteer/${volunteer?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setVolunteer(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteVolunteer = async(id, callback) => {
    const config = {
      url: `/volunteer/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setVolunteer(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addVolunteer(volunteer) : editVolunteer(volunteer)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: volunteerList,
      detail: volunteer,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchVolunteer,
      delete: deleteVolunteer,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}