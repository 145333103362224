import React, { useEffect, useRef, useState } from 'react'
import useToggle from '../../hooks/useToggle'
import Card from '../Cards/Card/Card'

const Accordion = ({
  header,
  collapsible,
  collapse
}) => {

  const [isCollapse, toggleIsCollapse] = useToggle(collapse || true)
  const [maxHeight, setMaxHeight] = useState(0)

  const coll = useRef(null)
  
  const onClickHandler = () => {
    toggleIsCollapse()
  }

  const passHeaderProps = { className: styles.header.default, onClick: onClickHandler}

  useEffect(() => {
    const currentColl = coll.current
    setMaxHeight(!isCollapse ? currentColl.scrollHeight + 'px' : 0)
  }, [isCollapse])

  useEffect(() => {
    if(typeof collapse !== 'boolean') return
    toggleIsCollapse(collapse)
  }, [collapse, toggleIsCollapse])

  return (
    <Card className={`${styles.container.default} ${!isCollapse ? styles.container.active : ''}`}>
      {header(passHeaderProps)}
      <div ref={coll} className={styles.collapsible.default} style={{maxHeight: maxHeight}}>
        {collapsible()}
      </div>
    </Card>
  )
}

const styles = {
  container: {
    default: 'overflow-hidden shadow-lg w-full flex flex-col',
    active: `divide-y`
  },
  header: {
    default: 'w-full',
  },
  collapsible: {
    default: 'w-full overflow-y-hidden transition-all duration-300 ease-in-out',
  },
}

export default Accordion