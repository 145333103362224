import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MediasNewsScreen from './medias/MediasNewsScreen'
import MediasReportsScreen from './medias/MediasReportsScreen'
import MediasVideosScreen from './medias/MediasVideosScreen'

const MediasScreen = () => {

  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState({news: 1, videos: 1, reports: 1})  
  const [media, setMedia] = useState({})

  const paginationOnClickHandler = (pageVal) => navigatePage(CHECK_TYPE.type, pageVal)

  const navigatePage = (type, pageVal) => {
    navigate({
      pathname: `/cms/media/${type}`,
      search: `page=${pageVal || 1}` 
    })
  }  

  const medias = [
    {
      type: 'news',
      element: MediasNewsScreen
    },
    {
      type: 'videos',
      element: MediasVideosScreen
    },
    {
      type: 'reports',
      element: MediasReportsScreen
    },
  ]   

  const CHECK_TYPE = medias.find(x => x.type === params.type)

  const elementProps = {
    page: page,
    paginationOnClick: paginationOnClickHandler, 
    pageNav: [
      {
        title: 'News',
        active: CHECK_TYPE?.type === 'news',
        onClick: () => navigatePage('news')
      },
      {
        title: 'Videos',
        active: CHECK_TYPE?.type === 'videos',
        onClick: () => navigatePage('videos')
      },
      {
        title: 'Reports',
        active: CHECK_TYPE?.type === 'reports',
        onClick: () => navigatePage('reports')
      },
      
    ]
  }

  useEffect(() => {   
    if(!CHECK_TYPE) return navigate('/cms/media/news')   
    setMedia(CHECK_TYPE)
    console.log(location.search)
    if(!location.search.includes('page')) return navigate({pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search.replace('?', '').split('&').find(x => x.includes('page')).replace('page=', '')
    setPage(currentPage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, navigate, params.type]) 
  
  const Element = media.element

  return (
    <>
      {Element && <Element {...elementProps}/>}
    </>
  )
}

export default MediasScreen