import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import TextField from '../../../components/TextField/TextField'
import Typography from '../../../components/Typography/Typography'
import axios from 'axios'
import { API_BASE_URL } from '../../../utils/urls'
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'
import { useAuth } from '../../../hooks/useAuth'

const LoginScreen = () => {

  const { auth } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState(null)
  const [errMsgBox, setErrMsgBox] = useState(null)

  const navigate = useNavigate()

  const onChangeHandler = (e, func) => {
    func(e.target.value)
  }

  const input = [
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      value: email,
      required: true,
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, // eslint-disable-line
      onChange: e => onChangeHandler(e, setEmail),
      validation: errors?.email?.message,
      message: {
        required: `Email required`,
        pattern: 'Please insert a valid email address'
      }
    },
    {
      name: 'password',
      variant: 'password',
      label: 'Password',
      type: 'password',
      value: password,
      required: true,
      onChange: e => onChangeHandler(e, setPassword),
      validation: errors?.password?.message,
      message: {
        required: `Password required`,
      }
    },
  ]

  const submit = async() => {
    let errObj = errors ? {...errors} : {}
    input.forEach((inp) => {
      if((inp.value === '' || !inp.value) && inp.required) return errObj[inp.name] = { type: 'required', message: inp.message.required }
      if(inp.pattern && !inp.pattern.test(inp.value)) return errObj[inp.name] = { type: 'pattern', message: inp.message.pattern}
      delete errObj[inp.name]
    })
    if(Object.entries(errObj).length === 0) errObj = null
    setErrors(errObj)
    if(errObj) return
    try {
      const { data } = await axios.post(`${API_BASE_URL}/users/login`, { email, password })
      if(!data) return
      Cookies.set('userInfo', JSON.stringify(data))
      navigate('/cms')
    } catch(err) {
      let errMsg = err.message
      if (err.message.includes('401')) errMsg = 'Not authorized'
      setErrMsgBox(errMsg)
    }
  }

  useEffect(() => {
    if(auth) return navigate('/cms')
  }, [auth, navigate])

  return (
    <div className='w-full h-screen bg-theme-primary flex flex-col items-center  pt-[10%]'>
      <Card className='flex flex-col w-full max-w-sm p-5 space-y-5'>
        <Typography variant={'h6'} weight={'bold'}>
          Login
        </Typography>
        {errMsgBox && (
          <div className='p-3 text-sm text-red-600 bg-red-100 border border-red-600 rounded-md'>
            {errMsgBox}
          </div>
        )}
        <div className="space-y-2">
          {input.map((inp, inpIdx) => (
            <TextField
              key={inpIdx} 
              {...inp}
            />
          ))}
        </div>
        <Button className='w-full' color={'secondary'} onClick={submit}>Login</Button>
      </Card>
    </div>
  )
}

export default LoginScreen