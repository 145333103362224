import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ImageContainer from '../../components/ImageContainer/ImageContainer'
import Typography from '../../components/Typography/Typography'
import data from '../../utils/data'

const PillarsAndrogramsSection = () => {

  const [title, setTitle] = useState('')
  const [pillars, setPillars] = useState([])

  const params = useParams()

  useEffect(() => {
    const pillarsData = data.pillars
    if(!params.slug) {
      setTitle('Our Pillars & Programs')
      setPillars(pillarsData)
      return
    }   
    let pillarsArr = [...pillarsData]
    const findPillar = pillarsArr.find(x => x.slug === params?.slug)
    if(findPillar) {
      const pillarIndex = pillarsArr.indexOf(findPillar)      
      pillarsArr.splice(pillarIndex, 1)
      setTitle('Our Other Pillars & Programs')
      setPillars(pillarsArr)
    }    
   
  }, [params.slug])

  return (
    <section className='w-full py-28'>
      <div className="text-center responsive">
        <Typography
          variant={'h4'}
          component={'h2'}
          weight={'bold'}
          lineHeight={'tight'}
          className={'mb-12 lg:mb-20'}
        >
          {title}
        </Typography>
      </div>
      <div className={'flex w-full relative overflow-x-scroll scrollbar--hidden'}>
        <div className={`${styles.pillars.default} ${styles.pillars[pillars.length < 4 ? 'partial' : 'full']}`}>
          {pillars.map((el, idx) => (
            <Link key={idx} to={`/pillars/${el.slug}`} className={'w-64 lg:w-full flex-shrink-0 flex flex-col link'}>
              <ImageContainer
                src={el.image}
                ratio={{width: 3, height: 4}}
                fit={'cover'}
                className={'rounded-xl overflow-hidden w-full'}
              />
              <div className="z-20 flex flex-col p-5 text-center bg-white rounded-xl ">
                <Typography
                  variant={'h5'}
                  component={'div'}
                  weight={'semibold'}
                  lineHeight={'tight'}
                  className={'text-theme-primary'}
                >
                  {el.title}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

const styles = {
  pillars: {
    default: 'flex px-8 pb-5 space-x-4 lg:px-0 lg:responsive lg:grid lg:space-x-0 lg:gap-4',
    full: 'lg:grid-cols-4',
    partial: 'lg:grid-cols-3'
  }
}

export default PillarsAndrogramsSection