import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CareersApplyingStepsScreen from './careers/CareersApplyingStepsScreen'
import CareersJobsScreen from './careers/CareersJobsScreen'
import CareersTestimoniesScreen from './careers/CareersTestimoniesScreen'
import CareersVolunteersScreen from './careers/CareersVolunteersScreen'

const CareersScreen = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState({jobs: 1, volunteers: 1, testimonies: 1})  
  const [career, setCareer] = useState({})

  
  const careers = [
    {
      type: 'jobs',
      element: CareersJobsScreen
    },
    {
      type: 'volunteers',
      element: CareersVolunteersScreen
    },
    {
      type: 'testimonies',
      element: CareersTestimoniesScreen
    },
    {
      type: 'applying',
      element: CareersApplyingStepsScreen
    },
  ]   

  const CHECK_TYPE = careers.find(x => x.type === params.type)

  const paginationOnClickHandler = (pageVal) => navigatePage(CHECK_TYPE.type, pageVal)

  const navigatePage = (type, page) => {
    navigate({
      pathname: `/cms/career/${type}`,
      search: `?page=${page || 1}` 
    })
  }  

  const elementProps = {
    page: page,
    paginationOnClick: paginationOnClickHandler, 
    pageNav: [
      {
        title: 'Jobs',
        active: CHECK_TYPE?.type === 'jobs',
        onClick: () => navigatePage('jobs')
      },      
      {
        title: 'Volunteers',
        active: CHECK_TYPE?.type === 'volunteers',
        onClick: () => navigatePage('volunteers')
      },      
      {
        title: 'Testimonies',
        active: CHECK_TYPE?.type === 'testimonies',
        onClick: () => navigatePage('testimonies')
      },      
      {
        title: 'Applying Steps',
        active: CHECK_TYPE?.type === 'applying',
        onClick: () => navigatePage('applying')
      },      
    ]
  }

  useEffect(() => {   
    if(!CHECK_TYPE) return navigate('/cms/career/jobs')   
    setCareer(CHECK_TYPE)
    if(!location.search.includes('page')) return navigate({pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search.replace('?', '').split('&').find(x => x.includes('page')).replace('page=', '')
    setPage(currentPage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, navigate, params.type]) 
  
  const Element = career.element

  return (
    <>
      {Element && <Element {...elementProps}/>}
    </>
  )
}

export default CareersScreen