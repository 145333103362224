import React, { useRef } from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Navigation, Autoplay, } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const BasicCarousel = ({
  data = [],
  autoplay,
  renderSlide,
  loop,
  breakpoints,
  navigation,
  className = '',
  containerClassName = '',
  slideClassName = ''
}) => {
  
  const beforeNextBtn = useRef(null)
  const beforePrevBtn = useRef(null)

  return (
    <div className={`relative ${containerClassName}`}>
      {data.length > 0 && (
        <>
          <Swiper
            className={` ${className}`}
            spaceBetween={40}
            modules={[Pagination, Navigation, Autoplay]}
            loop={loop}
            loopFillGroupWithBlank={true}
            navigation={{
              enabled: navigation,
              prevEl: beforePrevBtn.current,
              nextEl: beforeNextBtn.current
            }}
            autoplay={autoplay && {
              delay: 1000 * 10,
              disableOnInteraction: false,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = beforePrevBtn.current;
              swiper.params.navigation.nextEl = beforeNextBtn.current;
            }}
            breakpoints={breakpoints}
          >
            {data.map((el, elIdx) => (
              <SwiperSlide key={elIdx} className={`${slideClassName}`}>
                {renderSlide(el, elIdx)}
              </SwiperSlide>
            ))}

          </Swiper>
          {navigation && (
            <>
              <button ref={beforePrevBtn} className={`${styles.navBtn} ${styles.prevBtn}`}><IoChevronBack /></button>
              <button ref={beforeNextBtn} className={`${styles.navBtn} ${styles.nextBtn}`}><IoChevronForward /></button>
            </>
          )}
        </>
      )}
    </div>
  )
}

const styles = {
  navBtn: `absolute rounded-full bg-theme-primary text-white z-20 p-2 cursor-pointer`,
  prevBtn: `left-0 top-1/2 -translate-y-1/2  -translate-x-1/2`,
  nextBtn: `right-0 top-1/2 -translate-y-1/2  translate-x-1/2`,
}

export default BasicCarousel