import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useGetFullDate } from "../hooks/useGetFullDate"
import { API_BASE_URL } from "../utils/urls"

export const useDonationViewModel = () => {

  const { token } = useAuth()

  const [donations, setDonations] = useState({}) 
  const [donation, setDonation] = useState(null)
  const [banks, setBanks] = useState(null)
  const [bank, setBank] = useState(null)
  const [toast, setToast] = useState(null)
  const [donationLoading, setDonationLoading] = useState(false)

  const getFullDate = useGetFullDate()
  const navigate = useNavigate()

  const closeToast = () => {
    setToast(null)
  }

  const fetchDonations = async(page, limit, search) => {
    setDonationLoading(true)
    const config = {
      url: `/donation`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      params: {
        page,
        limit: limit || 10,
        search: search || null
      },
      
    }
    try {
      const { data } = await axios(config)
      setDonationLoading(false)
      setDonations(data)
    } catch (error) {
      setDonationLoading(false)
      setToast({...error, variant: 'danger'})
    }
  }

  const fetchDonation = async(id) => {
    const config = {
      url: `/donation/${id}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    try {
      const { data } = await axios(config)
      setDonation(data)
    } catch (error) {
      console.error(error)
    }
  }

  const sendDonation = async(data) => {
    setDonationLoading(true)
    const dataToAppend = {
      name:  data.name,
      phone: data.phone,
      email: data.email,
      bank: data.bank,
      account: data.account,
      owner: data.owner,
      receipt: data.receipt,
      message: data.message
    }
    const dataToAppendEntries = Object.entries(dataToAppend)
    const formData = new FormData()
    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    try {
      const {data} = await axios.post(`${API_BASE_URL}/donation`, formData)
      setDonationLoading(false)
      setToast(data)
    } catch (error) {
      setDonationLoading(false)
      console.error(error)
    }
  }

  const deleteDonation = async(id) => {
    const config = {
      url: `/donation/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    try {
      const { data } = await axios(config)
      setToast(data)
    } catch (error) {
      setToast(error)
    }
  }

  const fetchBanks = async() => {

    const config = {
      url: `/bank`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    
    try {
      const { data } = await axios(config)
      setBanks(data)
    } catch (error) {
      setToast(error)
    }
  }

  const fetchBank = async(id) => {

    const config = {
      url: `/bank/${id}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    
    try {
      const { data } = await axios(config)
      setBank(data)
    } catch (error) {
      setToast(error)
      navigate('/cms/not-found')
    }
  }

  const sendBank = async(data) => {

    const dataToAppend = {
      bank: data.bank,
      account: data.account,
      owner: data.owner,
      logo: data.logo,
      qr: data.qr
    }
    
    const dataToAppendEntries = Object.entries(dataToAppend)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  


    try {
      const {data} = await axios.post(
        `${API_BASE_URL}/bank`, 
        formData,
        { 
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      setToast(data)
    } catch (error) {
      setToast(error)
    }
  }

  const updateBank = async(id, data) => {
    const dataToAppend = {
      bank: data.bank,
      account: data.account,
      owner: data.owner,
      swift: data.swift,
      logo: data.logo,
      qr: data.qr
    }
    
    const dataToAppendEntries = Object.entries(dataToAppend)
    const formData = new FormData()
    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    try {
      const {data} = await axios.put(
        `${API_BASE_URL}/bank/${id}`, 
        formData,
        { 
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setToast(data)
    } catch (error) {
      setToast(error)
    }
  }

  const deleteBank = async(id) => {
    try {
      const { data } = await axios.delete(
        `${API_BASE_URL}/bank/${id}`,
        { 
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setToast(data)
    } catch (error) {
      setToast(error)
    }
  }

  const pickData = {
    donations: [
      {
        title: 'Donation ID',
        selector: '_id',
        className: 'whitespace-nowrap text-center'
      }, 
      {
        title: 'Name',
        selector: 'name',
        className: 'whitespace-nowrap text-left'
      }, 
      {
        title: 'Email',
        selector: 'email',
        className: 'whitespace-nowrap text-left'
      },
      {
        title: 'Phone Number',
        selector: 'phone',
        className: 'whitespace-nowrap text-center'
      },
      {
        title: 'Donation Date',
        selector: 'createdAt',
        processor: getFullDate,
        className: 'whitespace-nowrap text-center'
      },
    ]
  }

  const returnObj = {
    state: {
      donations,
      donation,
      donationLoading,
      banks,
      bank,
      toast
    },
    handler: {
      fetchDonations,
      fetchDonation,
      sendDonation,
      deleteDonation,
      fetchBanks,
      fetchBank,
      sendBank,
      updateBank,
      deleteBank,
      closeToast,
    },
    pickData
  }

  return returnObj


}