import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import MediaCard from '../../../components/Cards/MediaCard/MediaCard'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import { useNewsViewModel } from '../../../viewModels/useNewsViewModel'
import { useReportViewModel } from '../../../viewModels/useReportViewModel'
import { useVideoViewModel } from '../../../viewModels/useVideoViewModel'

const MediaScreen = () => {

  const [limit, setLimit] = useState(9)
  const [loadMoreBtn, setLoadMoreBtn] = useState(true)

  const params = useParams()
  const navigate = useNavigate()

  const categories = [
    {
      path: 'news',
      title: 'News',
      viewModel: useNewsViewModel
    },
    {
      path: 'video',
      title: 'Videos',
      viewModel: useVideoViewModel
    },
    {
      path: 'report',
      title: 'Reports',
      viewModel: useReportViewModel
    },
  ]

  const loadMoreOnClick = () => {
    setLimit(limit + 9)
  }

  const CHECK_CATEGORY = categories.find((ctg) => ctg.path.includes(params.path))
  const { state, handler } = CHECK_CATEGORY ? CHECK_CATEGORY?.viewModel() : {}
  
  useEffect(() => {    
    if(!CHECK_CATEGORY) return navigate('/not-found')
    handler.fetch(null, { limit: limit, page: 1})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  useEffect(() => {
    if(!state.list.records) return
    setLoadMoreBtn(limit <= state.list.records.length)
  }, [limit, state.list.records])

  return (
    <>
      {CHECK_CATEGORY && (
        <GlobalLayout smallHero={{title: <span className='capitalize'>{CHECK_CATEGORY?.title}</span>}} contentClassName={''}>
          <section className='section'>
            <div className='flex pb-4 space-x-2 text-xs capitalize lg:pb-10'>
              <Link to={`/media`} className='link text-theme-primary'>Media</Link>
              <span>/</span>
              <div className=''>{params.path !== 'news' ? `${params.path}s` : params.path}</div>
            </div>
            <div className={`grid gap-y-10 gap-x-5 md:grid-cols-2 lg:grid-cols-3`}>
              {state.list.records?.map((el, idx) => (
                <MediaCard
                  key={idx}
                  data={{
                    title: el.title,
                    createdAt: CHECK_CATEGORY?.title === 'News' && el.createdAt,
                    image: {
                      src: el.image.url,
                      alt: el.title,
                      ratio: CHECK_CATEGORY?.title === 'News' ? {width: 4, height: 3} : CHECK_CATEGORY?.title === 'Reports' ? {width: 3, height: 4} : {width: 16, height: 9} 
                    },
                    description: el.description,
                    action: {
                      title: CHECK_CATEGORY?.title === "Videos" ? "WATCH" : "READ",
                      props: (
                        CHECK_CATEGORY?.title === "Reports" ? { component: "a", href: el.link, target: "_blank" }
                        : { component: "a", href: `${CHECK_CATEGORY?.path}/${el._id}` }
                      )
                    }
                  }}
                />
              ))}
            </div>   
            {(loadMoreBtn && state.list.records?.length > 0) && (
              <div className='flex justify-center mt-20'>
                <Button variant={'outline'} onClick={loadMoreOnClick}>
                  Load More
                </Button>
              </div>
            )}
          </section>
        </GlobalLayout>
      )}
    </> 
  )
}

export default MediaScreen