import React from 'react'
import Typography from '../../Typography/Typography'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useMoneyDigits } from '../../../hooks/useMoneyDigits';

const StatisticJumbotron = ({data}) => {

  const moneyDigits = useMoneyDigits()
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  return (
    <div ref={ref} className="flex flex-col items-center justify-center w-full p-5 rounded-xl bg-theme-primary-50 md:flex-row">
      {inView && data.map((el, idx) => (
        <div key={idx} className='flex flex-col items-center w-full p-5 text-center'>
          <Typography 
            component={'div'}
            variant={'h2'}
            weight={'extrabold'}
            lineHeight={'none'}
            className={'text-theme-primary'}
          >
            <CountUp end={el.amount} formattingFn={moneyDigits}/>
          </Typography>
          <Typography weight={'bold'} variant={'subtitle2'}>
            {el.title}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default StatisticJumbotron