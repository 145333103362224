import React from 'react'

const Card = ({
  id,
  className = '',
  children
}) => {
  return (
    <div id={id} className={`${styles.card} ${className}`}>
      {children}
    </div>
  )
}

const styles = {
  card: 'flex bg-white rounded-xl md:rounded-2xl border'
}

export default Card