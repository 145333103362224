import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Table from '../../../components/Table/Table'
import Typography from '../../../components/Typography/Typography'
import { useDonationViewModel } from '../../../viewModels/useDonationViewModel'
import ActionButtons from '../../components/ActionButtons/ActionButtons'


const BankScreen = () => {

  const { state, handler } = useDonationViewModel()  

  const navigate = useNavigate()  

  useEffect(() => {
    handler.fetchBanks()    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const bankPickData = [
    {
      title: 'Bank Name',
      selector: 'bank',
      className: 'w-1/4 text-left'
    }, 
    {
      title: 'Account',
      selector: 'account',
      className: 'whitespace-nowrap w-1/5 text-left'
    }, 
    {
      title: 'Swift Code',
      selector: 'swift',
      className: 'whitespace-nowrap w-1/5 text-left'
    },     
    {
      title: 'Owner',
      selector: 'owner',
      className: 'whitespace-nowrap w-1/5 text-left'
    },
  ]

  return (
    <>
      <CMSLayout title={'Donation'} toast={state.toast ? {...state.toast, close: handler.closeToast} : null }>
        <Card className='flex-col p-5 space-y-4 col-span-full'>
          <div className="flex items-center justify-between w-full">
            <Typography
              variant={'h6'}
              weight={'bold'}
            > 
              Bank Account List
            </Typography>
            {(state?.banks?.length < 3) && (
              <Button color={'secondary'} onClick={() => navigate('/cms/bank/add')}>
                Add Bank
              </Button>
            )}
          </div>
          <Table
            containerClassName='w-full'
            className='flex flex-col'
            headRowClassName={'flex w-full'}
            bodyRowClassName={'flex w-full items-center'}
            data={state.banks || []}
            pickData={bankPickData}
            actions={(data) => (
              <ActionButtons 
                deleteAction={async() => {
                  await handler.deleteBank(data._id)
                  window.location.reload()
                }}
                editAction={() => navigate(`/cms/bank/edit/${data._id}`)}
              />
            )}
          />
        </Card>

      </CMSLayout>
     
    </>
  )
}

export default BankScreen