import axios from "axios"
import { API_BASE_URL } from "../../../../utils/urls"
import { CommonSessionToken } from "../../../functions/common"


export const ACT_StructureGetList = async () => {
    let data, error
    const config = {
        url: `/structure`,
        method: 'get',
        baseURL: API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
        },
    }
    try {
        const response = await axios(config)
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message
        error = errMessage
    }
    return { data, error }
}

export const ACT_StructureAdd = async (structure) => {

    const token = CommonSessionToken()
    let data, error
    const config = {
        url: `/structure`,
        method: 'POST',
        baseURL: API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        data: {
            title: structure.title,
            sort: structure.sort
        },
    }
    try {
        const response = await axios(config)
        if (!response.data) return
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message
        if (errMessage === ('E11000 duplicate key error collection: rnf.structures index: title_1 dup key: { title: "Meet Our Founder" }')) errMessage = 'Title already in use, please use another title'
        if (errMessage === ('Structure validation failed: title: Path `title` is required.')) errMessage = 'Title is required'
        error = errMessage
    }

    return { data, error }
}

export const ACT_StructureUpdate = async (structure) => {
   
    const token = CommonSessionToken()
    let data, error
    const config = {
        url: `/structure/` + structure?._id,
        method: 'PUT',
        baseURL: API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        data: {
            title: structure.title,
            sort: structure.sort
        },
    }
    try {
        const response = await axios(config)
        if (!response.data) return
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message
        error = errMessage
    }

    return { data, error }
}

export const ACT_StructureDelete = async (
    structure_id,
) => {
    const token = CommonSessionToken()
    let data, error

    const config = {
        url: `/structure/` + structure_id,
        method: 'DELETE',
        baseURL: API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    }
    try {
        const response = await axios(config)
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message
        if (errMessage === ('E11000 duplicate key error collection: rnf.structures index: title_1 dup key: { title: "Meet Our Founder" }')) errMessage = 'Title already in use, please use another title'
        if (errMessage === ('Structure validation failed: title: Path `title` is required.')) errMessage = 'Title is required'
        error = errMessage
    }
    return { error, data }
}

export const ACT_StructureMove = async (payload) => {

    const token = CommonSessionToken()
    let data, error
    const config = {
        url: `/structure/move/` + payload?._id,
        method: 'POST',
        baseURL: API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        data: {
            direction: payload?.direction,
        },
    }
    try {
        const response = await axios(config)
        if (!response.data) return
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message
        if (errMessage === ('E11000 duplicate key error collection: rnf.structures index: title_1 dup key: { title: "Meet Our Founder" }')) errMessage = 'Title already in use, please use another title'
        if (errMessage === ('Structure validation failed: title: Path `title` is required.')) errMessage = 'Title is required'
        error = errMessage
    }

    return { data, error }
}