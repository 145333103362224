import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useTestimonyViewModel = () => {

  const initialState = {
    testimony: {title: '', image: '', description: '', link: ''}
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [testimonyList, setTestimonyList] = useState({records: []})
  const [testimony, setTestimony] = useState(initialState.testimony)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setTestimony({...testimony, [e.target.name]: e.target.value})
  }

  const imageInputOnChange = (e, data) => {
    setTestimony({...testimony, image: data})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'name',
      title: 'name',
      value: testimony?.name || '',
      onChange: textFieldOnChange,
      validation: errors?.name?.message,
      message: {
        required: 'Name cannot be empty',
      },
      required: true
    },
    {
      name: 'occupation',
      title: 'occupation',
      value: testimony?.occupation || '',
      onChange: textFieldOnChange,
      validation: errors?.occupation?.message,
      message: {
        required: 'Occupation cannot be empty',
      },
      required: true
    },
    {
      name: 'image',
      title: 'image',
      value: testimony?.image?.url || testimony.image || '',
      onChange: imageInputOnChange,
      message: {
        required: 'You have to upload image',
      },
      validation: errors?.image?.message,
      autoComplete: 'off',
      required: true
    },
    {
      name: 'message',
      title: 'message',
      value: testimony?.message || '',
      onChange: textFieldOnChange,
      validation: errors?.message?.message,
      message: {
        required: 'You have to write the message',
      },
      required: true
    },
  ]
  

  const alerts = {
    add: {
      title: `Testimony Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/testimonies')
        }
      ]
    },
    edit: {
      title: `Testimony Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/testimonies')
        }
      ]
    },
    delete: {
      title: `Testimony Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/testimonies')
        }
      ]
    }
  }


  const fetchTestimony= async(id, params = {}) => {
    setLoading(true)
    const config = {
      url: `/testimony${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setTestimonyList(data) : setTestimony(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addTestimony = async() => {

    const dataToAppendEntries = Object.entries(testimony)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)

    const config = {
      url: `/testimony`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setTestimony(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editTestimony = async() => {
    const dataToAppendEntries = Object.entries(testimony)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)
    const config = {
      url: `/testimony/${testimony?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setTestimony(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteTestimony = async(id, callback) => {
    const config = {
      url: `/testimony/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setTestimony(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addTestimony(testimony) : editTestimony(testimony)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: testimonyList,
      detail: testimony,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchTestimony,
      delete: deleteTestimony,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}