import React, { useEffect } from 'react'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import TextField from '../../../components/TextField/TextField'
import Typography from '../../../components/Typography/Typography'
import { useStatisticViewModel } from '../../../viewModels/useStatisticViewModel'

const StatisticScreen = () => {
  
  const { state, handler, element } = useStatisticViewModel()

  useEffect(() => {
    handler.fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CMSLayout title={'Statistic'} alert={state.alert}>
      <Card className="flex flex-col col-span-6 p-5 space-y-6">
        {element.inputs.map((el, elIdx) => (
          <Card key={elIdx} className='flex-col w-full p-5 space-y-2'>
            <Typography variant={'subtitle1'} weight={"semibold"} className={'pb-5'}>
              {el.title}
            </Typography>
            {el.fields.map((inp, inpIdx) => (
              <TextField key={inpIdx} {...inp}/>
            ))}
          </Card>
        ))}
        <div className="w-full pt-5">
          <Button className='w-full capitalize' onClick={() => handler.submit()}>Save Statistic</Button>
        </div>
      </Card>
    </CMSLayout>
  )
}

export default StatisticScreen