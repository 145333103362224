/* eslint no-use-before-define: 0 */  // --> OFF

export const NAME_VALIDATION = {
  message: {
    required: 'Name is required',
  }
}
export const PHONE_VALIDATION = {
  message: {
    required: 'Phone Number is required',
    invalid: 'Phone Number is invalid'
  }
}
export const EMAIL_VALIDATION = {
  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, // eslint-disable-line
  message: {
    required: 'Email is required',
    invalid: 'Please enter a valid Email address'
  }
}
export const BANK_NAME_VALIDATION = {
  message: {
    required: 'Bank Name is required',
  }
}
export const BANK_ACCOUNT_VALIDATION = {
  message: {
    required: 'Bank Account Number is required',
  }
}
export const BANK_OWNER_VALIDATION = {
  message: {
    required: 'Bank Owner is required',
  }
}
export const BANK_SWIFT_VALIDATION = {
  message: {
    required: 'Bank Swift Code is required',
  }
}
export const BANK_LOGO_VALIDATION = {
  message: {
    required: 'Bank Logo is required',
  }
}
export const BANK_QR_VALIDATION = {
  message: {
    required: 'Bank QR is required',
  }
}
export const RECEIPT_VALIDATION = {
  message: {
    required: 'Receipt is required',
  }
}
export const MESSAGE_VALIDATION = {
  message: {
    minLength: 'Minimum 3 characters is required',
  }
}
