import React, { useEffect } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import Accordions from '../../../components/Accordion/Accordions'
import IconContainer from '../../../components/IconContainer/IconContainer'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { useFaqViewModel } from '../../../viewModels/useFaqViewModel'

const FAQScreen = () => {
  
  const { state, handler } = useFaqViewModel()

  useEffect(() => {
    handler.fetch(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GlobalLayout
      smallHero={{title: 'Frequently Asked Questions'}}
    >
      <section className="space-y-5 section">
        <Accordions
          data={state.list.records}
          renderHeader={({ data: dt, className, onClick }) => (
            <button className={`${className} flex items-center w-full p-5 space-x-2`} onClick={onClick}>
              <Typography variant={'subtitle1'} className='w-full text-left' weight={'semibold'}>{dt.question}</Typography>
              <IconContainer className=''>
                <IoChevronDown/>
              </IconContainer>
            </button>
          )}
          renderCollapsible={({data: dt}) => (
            <div className=''>
              <Typography className='w-full p-5 text-left'>
                {dt.answer}
              </Typography>
            </div>
          )}
        />
      </section>
    </GlobalLayout>
  )
}

export default FAQScreen