import React from 'react'
import useToggle from '../../hooks/useToggle'
import Input from '../Input/Input'
import { IoEyeOutline, IoSearch } from 'react-icons/io5'
import './TextField.css'
import { useRef } from 'react'

const TextField = ({
  wrapperClassName = '',
  containerClassName = '',
  className = '',
  inputRef,
  label,
  id,
  name,
  value,
  type,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onLoad,
  placeHolder,
  adornment,
  adornmentPosition,
  style,
  validation,
  disabled,
  variant,
  accept
}) => {

  const ref = useRef(null)
  const [showPassword, toggleShowPassword] = useToggle(false)
  
  const variants = [
    {
      variant: 'password',
      type: showPassword ? 'text' : 'password',
      adornment: <span className={`text-field__icon ${showPassword ? 'active' : ''}`} onClick={toggleShowPassword}><IoEyeOutline/></span>,
      adornmentPosition: 'right'
    },
    {
      variant: 'search',
      type: 'text',
      adornment: <span className={`text-field__icon`}><IoSearch/></span>,
      adornmentPosition: 'right'
    },
  ]

  const findVariant = variants.find(x => x.variant === variant)
  const CHECK_INPUT_TYPE = findVariant?.type || type
  const CHECK_INPUT_ADORNMENT = findVariant?.adornment || adornment
  const CHECK_INPUT_ADORNMENT_POSITION = findVariant?.adornmentPosition || adornmentPosition

  const onChangeHandler = (e) => {
    onChange && onChange(e)
  }

  const onEnterEscape = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      ref.current.blur()
      window.removeEventListener('keydown', onEnterEscape)
    }
  }

  const onFocusHandler = (e) => {
    window.addEventListener('keydown', onEnterEscape)
    onFocus && onFocus(e)
  }

  return (
    <Input
      wrapperClassName={wrapperClassName}
      containerClassName={containerClassName}
      className={className}
      inputRef={thisRef => {
        ref.current = thisRef.current
        inputRef = thisRef
      }}
      label={label}
      id={id}
      name={name}
      value={value}
      type={CHECK_INPUT_TYPE}
      onChange={onChangeHandler}
      onFocus={onFocusHandler}
      onBlur={onBlur}
      onClick={onClick}
      onLoad={onLoad}
      placeholder={placeHolder}
      adornment={CHECK_INPUT_ADORNMENT}
      adornmentPosition={CHECK_INPUT_ADORNMENT_POSITION}
      style={style}
      validation={validation}
      disabled={disabled}
      accept={accept}
    />
  )
}

export default TextField