import React, { useEffect, useRef, useState } from 'react'
import ImageContainer from '../ImageContainer/ImageContainer'

const ImageInput = ({
  ref,
  label,
  value,
  onChange,
  validation,
  imageRatio = { width: 16, height:9},
  containerClassName,
  imageClassName = '',
  imageFit = 'cover'
}) => {

  const [image, setImage] = useState(undefined)
  const [validationMessage, setValidationMessage] = useState('')

  const inputRef = useRef()

  const onChangeHandler = (e) => {
   
    const files = e.target.files
    if (files && files.length > 0) {
      if (files[0].size / 1000 <= 1000) {
        setValidationMessage(``)
        setImage(files[0])
        onChange && onChange(e, files[0])
        return
      }
      setValidationMessage(`Your file is more than 1 MB`)
      setImage(undefined)
      inputRef.current.value = ''
      onChange && onChange(e, undefined)
    }
  }

  const removeHandler = (e) => {
    setImage(undefined)
    onChange && onChange(e, undefined)
  }

  useEffect(() => {
    ref && ref(inputRef)
    setValidationMessage(validation)
  }, [ref, validation])

  

  return (
    <div className={`w-full ${containerClassName}`}>
      {
        image ? (
          <div className='flex flex-col w-full'>
            <label className={'text-xs font-semibold mb-2'} htmlFor="receipt">{label}</label>
            <ImageContainer
              fit={imageFit}
              className={`overflow-hidden border rounded-xl ${imageClassName}`}
              src={URL.createObjectURL(image)}
              alt="Thumb"
              ratio={imageRatio}
            />
            <button className={`mt-2 text-sm text-center text-red-600 ${imageClassName}`} onClick={removeHandler}>Remove This Image</button>
          </div>
        ) : value ? (
          <div className='flex flex-col w-full'>
            <label className={'text-xs font-semibold mb-2'} htmlFor="receipt">{label}</label>
            <ImageContainer
              fit={imageFit}
              className={`overflow-hidden border rounded-xl ${imageClassName}`}
              src={value}
              alt="Thumb"
              ratio={imageRatio}
            />
            <button className={`mt-2 text-sm text-center text-red-600 ${imageClassName}`} onClick={removeHandler}>Remove This Image</button>
          </div>
        ) : (
          <div  className={'flex flex-col w-full'}>
            <label className={'text-xs font-semibold mb-2'} htmlFor="receipt">{label}</label>
            <input ref={inputRef} id='receipt' type="file" className='text-sm' onChange={onChangeHandler} accept="image/png, image/jpg, image/jpeg"/>
            <div className='mt-1 text-xs'>
              {validationMessage && (
                <span className='text-theme-secondary'>{validationMessage}</span>
              )}
            </div>
          </div>
        )   
      }
    </div>
  )
}

export default ImageInput