import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CareerApplyingStepsScreen from './career/CareerApplyingStepsScreen'
import CareerJobScreen from './career/CareerJobScreen'
import CareerTestimonyScreen from './career/CareerTestimonyScreen'
import CareerVolunteerScreen from './career/CareerVolunteerScreen'


const MediaScreen = () => {

  const params = useParams()
  const methods = ['add', 'edit']
  const [CHECK_METHOD] = useState(methods.find(x => params.method === x))
  
  const types = [
    {
      type: 'job',
      element: <CareerJobScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'volunteer',
      element: <CareerVolunteerScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'testimony',
      element: <CareerTestimonyScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'applying',
      element: <CareerApplyingStepsScreen method={CHECK_METHOD} />,
    }, 
  ]

  const [CHECK_TYPE] = useState(types.find(x => params.type === x.type))  

  return <>{CHECK_TYPE?.element}</>
}

export default MediaScreen