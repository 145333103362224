import React from 'react'
import Button from '../../Button/Button'
import Typography from '../../Typography/Typography'

const CallForActionJumbotron = ({
  title,
  action
}) => {
  return (
    <div className="flex flex-col items-center p-8 space-y-5 text-center text-white rounded-xl bg-theme-primary lg:flex-row lg:space-y-0 lg:justify-between">
      <Typography
        variant={'h5'}
        weight={'bold'}
      >
        {title}
      </Typography>
      <Button onClick={action.onClick} color={'secondary'}>
        {action.title}
      </Button>
    </div>
  )
}

export default CallForActionJumbotron