import React from 'react'

const List = ({className = '', children}) => {

  return (
    <div className={`w-full ${className}`}>
      {children}
    </div>
  )
}

export default List