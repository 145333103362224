import React from 'react'
import PopUp from '../PopUp/PopUp'
import { IoClose } from 'react-icons/io5'
import Typography from '../../Typography/Typography'
import Card from '../../Cards/Card/Card'
import IconContainer from '../../IconContainer/IconContainer'
import Overlay from '../../Overlay/Overlay'

const CardPopUp = ({
  active,
  title, 
  close,
  closeFunction,
  children,
  freeze
}) => {
  return (
    <>
      <PopUp 
        active={active} 
        className="max-h-[80vh] h-full responsive flex flex-col items-center"
        defaultClassName="top-[50vh] left-[50vh]" 
        zIndex={52} 
      >
        <Card className='flex-col w-full h-full max-w-md p-5 space-y-5'>
        {(title || close) && (
          <div className="relative flex items-center justify-between w-full">
            {title && (
              <Typography
                variant={'h6'}
                weight={'bold'}
              >
                {title}
              </Typography>
            )}
            <IconContainer className='absolute right-0 text-2xl cursor-pointer top-1' onClick={closeFunction} >
              <IoClose />
            </IconContainer>
          </div>
        )}
        {children}
        </Card>
      </PopUp>
      <Overlay active={active} zIndex={51} freeze={freeze}/>
    </>
  )
}

export default CardPopUp