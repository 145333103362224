import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import DonationGeneralScreen from './donation/DonationGeneralScreen'
import DonationSeratusScreen from './donation/DonationSeratusScreen'


const DonationScreen = () => {

  const params = useParams()
  const methods = ['add', 'edit']
  const [CHECK_METHOD] = useState(methods.find(x => params.method === x))
  
 
  const types = [
    {
      type: 'general',
      element: <DonationGeneralScreen method={CHECK_METHOD} />,
    }, 
    {
      type: 'seratus',
      element: <DonationSeratusScreen method={CHECK_METHOD} />,
    }, 
  ]

  const [CHECK_TYPE] = useState(types.find(x => params.type === x.type))  

  return CHECK_TYPE.element
}

export default DonationScreen