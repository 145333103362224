import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import data from '../../../utils/data'
import PillarsAndrogramsSection from '../../sections/PillarsAndrogramsSection'

const PillarScreen = () => {

  const navigate = useNavigate()
  const params = useParams()
  const pillar = data.pillars.find(x => x.slug === params.slug)
  
  useEffect(() => {
    if(!pillar) navigate('*')
  }, [navigate, pillar])
  
  return (
    <>
      {pillar && (
        <GlobalLayout smallHero={{title: pillar.title, subtitle: 'Pillars and Programs', image: `${IMAGE_PATH}/photos/small_hero/${params.slug}-small_hero.jpg`}}>
          <section className='space-y-10 section md:responsive--article lg:space-y-12'>
            <div className=''>
              {pillar.description}
            </div>
            {pillar.programs?.map((pr, prIdx) => (
              <div key={prIdx} className="space-y-10">
                <div className='space-y-5'>
                  <Typography 
                    variant={'h4'}
                    weight={'bold'}
                    className={'-mb-2 text-theme-primary'}
                  >
                    {pr.title}
                  </Typography>
                  {pr.image && (
                    <ImageContainer 
                      src={pr.image}
                      fit={'cover'}
                      ratio={{width: 16, height:9}}
                      className={'rounded-xl overflow-hidden'}
                    />
                  )}
                  {pr.description && (
                    <Typography>
                      {pr.description}
                    </Typography>
                  )}
                </div>
                
                {pr.data?.map((prs, prsIdx) => (
                  <div key={prsIdx} className='space-y-5'>
                    <Typography 
                      variant={'h6'}
                      weight={'semibold'}
                      className={'-mb-2'}
                    >
                      {prs.title}
                    </Typography>
                    <ImageContainer 
                      src={prs.image}
                      fit={'cover'}
                      ratio={{width: 16, height:9}}
                      className={'rounded-xl overflow-hidden'}
                    />
                    <Typography>
                      {prs.description}
                    </Typography>
                  </div>
                ))}
              </div>
            ))}
          </section>
          <PillarsAndrogramsSection/>
        </GlobalLayout>
      )}
    </>
  )
}

export default PillarScreen