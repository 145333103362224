import Cookies from "js-cookie"
import { useEffect, useState } from "react"

export const useAuth = () => {
  const [auth, setAuth] = useState(Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null)
  const [token, setToken] = useState(auth ? auth.token : null)

  const userInfo = Cookies.get('userInfo')

  useEffect(() => {
    if(!userInfo) return
    const currAuth = JSON.parse(Cookies.get('userInfo'))
    setAuth(currAuth)
    setToken(currAuth.token)
  }, [userInfo])

  return { auth, token }
}