import React from 'react'
import Typography from '../../../../../components/Typography/Typography'

const PersonContainer = ({
  backgroundColor,
  name,
  role,
  image
}) => {

  const backgroundColors = ['primary', 'secondary', 'tertiary', 'orange', 'gray']

  const CHECK_BACKGROUND_COLOR = backgroundColors.find(x => x === backgroundColor) ? backgroundColor : backgroundColors[0]

  return (
    <div className='flex flex-col w-full px-5 text-left'>
      <div className="relative flex flex-col items-center w-full mb-3 aspect-[4/3]">
        <div className={`${styles.background} ${styles.backgroundColor[CHECK_BACKGROUND_COLOR]}`}></div>
        <img
          className='relative object-cover object-top w-full aspect-[4/3]'
          src={image?.src}
          alt=''
        />
      </div>
      <Typography variant={'subtitle1'} weight={'bold'} lineHeight={'tight'} className={'mb-1'}>
        {name}
      </Typography>
      <Typography variant={'body2'}>
        {role}
      </Typography>
    </div>
  )
}

const styles = {
  background: `absolute bottom-0 left-0 right-0 h-[60%] rounded-t-2xl`,
  backgroundColor: {
    primary: 'bg-theme-primary ',
    secondary: 'bg-theme-secondary ',
    tertiary: 'bg-theme-tertiary ',
  }
}

export default PersonContainer