import React, { useCallback, useEffect } from 'react'
import { IoAlertCircle, IoCheckmarkCircle, IoClose, IoCloseCircle } from 'react-icons/io5'
import useToggle from '../../hooks/useToggle'
import IconContainer from '../IconContainer/IconContainer'

const Toast = ({
  message,
  active,
  deactiveFunc,
  variant
}) => {  

  const variants = ['success', 'danger', 'caution']

  const CHECK_VARIANT = variants.find(x => x === variant) ? variant : variants[0]

  const [render, toggleRender] = useToggle(false)
  const [show, toggleShow] = useToggle(false)
  
  const hideHandler = useCallback(() => {
    toggleShow(false)
    deactiveFunc && deactiveFunc(false)
    setTimeout(() => {
      toggleRender(false)
    }, 300)
  }, [deactiveFunc, toggleRender, toggleShow])

  useEffect(() => {
    if(!active) return hideHandler()
    toggleRender(true)
    setTimeout(() => {
      toggleShow(true)
    }, 10)
    setTimeout(() => {
      hideHandler()
    }, 5000)
  }, [active, hideHandler, toggleRender, toggleShow])  
  

  return (
    <>
      {render && (
        <div className={[styles.container.default, styles.container.variant[CHECK_VARIANT], styles.container.display[show ? 'show' : 'hidden']].join(' ')}>
          <div className="flex items-center space-x-2">
            <IconContainer className='text-xl'>
              {variant === 'danger' ? (
                <IoCloseCircle/>
              ) : variant === 'caution' ? (
                <IoAlertCircle/>
              ) : <IoCheckmarkCircle/>}
            </IconContainer>
            <div>
              {message}
            </div>
          </div>
          <IconContainer className='text-xl cursor-pointer ' onClick={hideHandler}>
            <IoClose/>
          </IconContainer>
        </div>
      )}
    </>
  )
}

const styles = {
  container: {
    default: `fixed z-50 flex items-center p-4 space-x-8  rounded-lg right-4 left-4 sm:left-auto  transition-all duration-300 ease-in-out`,
    display: {
      hidden: `-bottom-full`,
      show: `bottom-4`,
    },
    variant: {
      success: 'bg-green-600 text-white',
      danger: `bg-red-600 text-white`,
      caution: `bg-yellow-600 text-black`
    }
  }
}

export default Toast