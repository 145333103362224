import React, { useEffect } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import IconContainer from '../../../components/IconContainer/IconContainer'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import List from '../../../components/Lists/List/List'
import Pagination from '../../../components/Pagination/Pagination'
import Typography from '../../../components/Typography/Typography'
import { useApplyingStepsViewModel } from '../../../viewModels/useApplyingStepsViewModel'
import { useJobViewModel } from '../../../viewModels/useJobViewModel'
import { useVolunteerViewModel } from '../../../viewModels/useVolunteerViewModel'

const CareerScreen = () => {

  
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const job = useJobViewModel()
  const volunteer = useVolunteerViewModel()

  const applying = useApplyingStepsViewModel()
  
  const careers = [
    {
      type: 'job',
      viewModel: job
    },
    {
      type: 'volunteer',
      viewModel: volunteer
    }
  ]
  
  const career = careers.find(x => x.type === params.path)
  const viewModel = career ? career.viewModel : null
  
  useEffect(() => {
    if(!params.path) return navigate({ pathname: '/career/volunteer', search: `?page=1`})
    if(!career) return navigate('/not-found')
    if(!location.search.includes('page')) return navigate({ pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search?.replace('?', '').split('&').find(x => x.includes('page'))?.replace(`page=`, '')
    viewModel?.handler.fetch(null, { page: Number(currentPage) })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, params.path])

  useEffect(() => {
    applying.handler.fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <GlobalLayout
        smallHero={{title: 'Career'}}
      >
        
        <section className="space-y-12 lg:space-y-20 section">          
          <div className='flex -mb-6 space-x-2'>
            <Button active={career?.type === 'volunteer'}variant={'outline'} onClick={() => navigate(`/career/volunteer`) }>Volunteers</Button>
            <Button active={career?.type === 'job'} variant={'outline'} onClick={() => navigate(`/career/job`) }>Job Openings</Button>
          </div>
          {career && (
            <>
              <div className='space-y-3'>
                {viewModel?.state.list.records?.length === 0 ? (
                  <div className='w-full text-center capitalize'>
                    No {career.type} Available
                  </div>
                ) : (
                  viewModel?.state.list.records?.map((dt, idx) => (
                    <Link to={`/career/${career.type}/${dt._id}`} key={idx} className={`overflow-hidden shadow-lg bg-white border rounded-lg  flex items-center w-full p-5 space-x-2`}>
                      <Typography variant={'subtitle1'} className='w-full text-left' weight={'semibold'}>{dt.title}</Typography>
                      <IconContainer className=''>
                        <IoChevronForward/>
                      </IconContainer>
                    </Link>
                  ))
                )}
              </div>
              <Pagination page={viewModel?.state.list.currentPage || 1} pageTotal={viewModel?.state.list.totalPages || 1} className={'justify-center'} paginationOnClick={pageNum => navigate(`${location.pathname}?page=${pageNum}`)}/>

            </>
          )}          
        </section>
        <section className="section responsive--article">
          <Typography
            variant={'h4'}
            component={'h2'}
            weight={'bold'}
            lineHeight={'tight'}
            className={'mb-4 text-center'}
          >
            {applying.state.detail.title}
          </Typography>
          <Typography className='mb-12 text-center lg:mb-20'>
            {applying.state.detail.subtitle}
          </Typography>
          <List className='space-y-3'>
            {applying.state.detail.steps.map((how, howIdx) => (
              <div key={howIdx} className="w-full">
                <Typography variant={'subtitle1'} weight={'semibold'} className='mb-1'>
                  {howIdx+1}. {how?.title}
                </Typography>
                <Typography>
                  {how?.description}
                </Typography>
              </div>
            ))}
          </List>
        </section>
    </GlobalLayout>
  )
}

export default CareerScreen