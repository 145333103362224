import React, { useEffect, useState } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import ClickArea from '../../components/ClickArea/ClickArea'
import IconContainer from '../../components/IconContainer/IconContainer'
import ImageContainer from '../../components/ImageContainer/ImageContainer'
import List from '../../components/Lists/List/List'
import ListCard from '../../components/Lists/ListCard/ListCard'
import CardPopUp from '../../components/PopUps/CardPopUp/CardPopUp'
import ShapeMask from '../../components/ShapeMask/ShapeMask'
import Typography from '../../components/Typography/Typography'
import { IMAGE_PATH } from '../../constants/urlConstants'
import useToggle from '../../hooks/useToggle'
import data from '../../utils/data'


const PerformancesSection = () => {

  const [contintet, setContinent] = useState(null)
  const [continentPopUp, toggleContinentPopUp] = useToggle(false)
  const [country, setCountry] = useState(null)
  const [countryPopUp, toggleCountryPopUp] = useToggle(false)

  const performances = data.performances

  useEffect(() => {
    document.body.style.overflow = (continentPopUp || countryPopUp) ? 'hidden' : ''
  }, [continentPopUp, countryPopUp])

  const showContinetHandler = (cont) => {
    setContinent(cont)
    toggleContinentPopUp(true)
  } 

  const showCountryHandler = (cont, coun, data) => {
    if(data) {
      setCountry(data)
      toggleCountryPopUp(true)
      return
    }
    const counPrmf = cont.list.data.find(x => x.title.toLowerCase() === coun)
    setCountry(counPrmf)
    toggleCountryPopUp(true)
  } 

  const showPopUpHandler = (cont, coun) => {
    const contiPrmf = performances.find(x => x.title.toLowerCase() === cont)
    if(!coun) return showContinetHandler(contiPrmf)
    showCountryHandler(contiPrmf, coun)
  }      

  const closePopUpHandler = (type) => {
    if(type === 'continent') {
      toggleContinentPopUp(false)
      return
    }
    if(type === 'country') { 
      toggleCountryPopUp(false)
    }
  }
  
  const clickAreas = [
    {
      id: 'north-america',
      left: '14%',
      top: '20%',
      width: '8%',
      height: '20%',
      visible: false,
      onClick: () => showPopUpHandler('north america'),
    },
    {
      id:'europe',
      right:'27.2%',
      top:'15%',
      width:'8%',
      height:'20%',
      visible: false,
      onClick: () => showPopUpHandler('europe'),
    },
    {
      id:'asia',
      right:'15.6%',
      top:'35%',
      width:'8%',
      height:'20%',
      visible: false,
      onClick: () => showPopUpHandler('asia'),
    },
  ]

  const bigClickAreas = [
    {
      id: 'chicago',
      left: '22%',
      top: '36.5%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('north america', 'chicago'),
    },
    {
      id: 'missouri',
      left: '20.6%',
      top: '39.3%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('north america', 'missouri'),
    },
    {
      id: 'poland',
      left: '51.3%',
      top: '28.5%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('europe', 'poland'),
    },
    {
      id:'myanmar',
      right:'26%',
      bottom:'46%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('asia', 'myanmar'),
    },
    {
      id:'thailand',
      right:'24.9%',
      bottom:'44%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('asia', 'thailand'),
    },
    {
      id:'cambodia',
      right:'23.6%',
      bottom:'42.5%',
      width: '1.3%',
      height: '3.5%',
      visible: false,
      onClick: () => showPopUpHandler('asia', 'cambodia'),
    },   
  ]
  
  return (
    <>
      <ShapeMask 
        component={'section'}
        maskImage={`${IMAGE_PATH}/shapes/shape_2.svg`}
        className={'bg-theme-primary h-[120vh] lg:h-[150vh]'}
      >
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-10'}
            >
              International Performances
            </Typography>
            <div className='relative w-full lg:hidden'>
              {clickAreas.map((area, idx) => (
                <ClickArea
                  key={idx}
                  {...area}
                />
              ))}
              <img className='w-full' src={`${IMAGE_PATH}/maps/world_map_small.png`} alt="world_map_small" />
            </div>
            <div className='relative hidden w-full lg:block'>
              {bigClickAreas.map((area, idx) => (
                <ClickArea
                  key={idx}
                  {...area}
                />
              ))}
              <img className='w-full' src={`${IMAGE_PATH}/maps/world_map.png`} alt="world_map" />
            </div>
          </div>
        </div>
      </ShapeMask>
      <CardPopUp
        active={continentPopUp}
        title={contintet?.title}
        close
        closeFunction={() => closePopUpHandler('continent')}
        freeze
      >
        <div className="relative flex flex-col flex-1">
          <div className="absolute inset-0 flex flex-col w-full pb-5 space-y-5 overflow-y-scroll scrollbar--hidden">
            <ImageContainer className='w-full' src={contintet?.image} ratio={{width: 16, height:9}}/>
            <div className="flex flex-col w-full space-y-5">
              {contintet?.list && (
                <div className='space-y-2'>
                  <Typography 
                    variant={'subtitle2'}
                    className={'text-gray-500'}
                  >
                    {contintet?.list.title}
                  </Typography>
                  <List>
                    {contintet?.list.data.map((ls, lsIdx) => (
                      <ListCard key={lsIdx} className="space-x-2 " onClick={() => showCountryHandler(null, null, ls)}>
                        <IconContainer className='font-semibold text-theme-secondary'>{lsIdx + 1}</IconContainer>
                        <div className='w-full font-semibold'>{ls.title}</div>
                        <IconContainer><IoChevronForward /></IconContainer>
                      </ListCard>
                    ))}
                  </List>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardPopUp>
      <CardPopUp active={countryPopUp} closeFunction={() => closePopUpHandler('country')} title={country?.title}>
        <div className="relative flex flex-col flex-1">
          <div className="absolute inset-0 flex flex-col w-full pb-5 space-y-5 overflow-y-scroll scrollbar--hidden">
            <ImageContainer className='flex-shrink-0 w-full overflow-hidden rounded-lg' src={country?.image} fit={'cover'} ratio={{width: 16, height:9}}/>
            {country?.description && (
              <div className='space-y-2'>
                <Typography variant={'body1'}>
                  {country?.description}
                </Typography>
              </div>
            )}  
          </div>
        </div>
      </CardPopUp> 
    </>
  )
}

const styles = {
  wrapper: 'relative flex flex-col items-center w-full h-full',
  container: "flex flex-col items-center h-full justify-center space-y-5 text-center text-white responsive mb"
}

export default PerformancesSection

