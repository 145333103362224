import axios from "axios"
import {  useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useJobViewModel = () => {

  const initialState = {
    job: {title: '', description: '', link: ''}
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [jobList, setJobList] = useState({records: []})
  const [job, setJob] = useState(initialState.job)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setJob({...job, [e.target.name]: e.target.value})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'title',
      title: 'title',
      value: job?.title || '',
      onChange: textFieldOnChange,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
        invalid: 'Job with the same title is already exist'
      },
      required: true
    },
    {
      name: 'description',
      title: 'description',
      value: job?.description || '',
      onChange: textFieldOnChange,
      validation: errors?.description?.message,
      message: {
        required: 'You have to write the description',
      },
      required: true
    },
    {
      name: 'link',
      title: 'link to job',
      value: job?.link || '',
      onChange: textFieldOnChange,
      validation: errors?.link?.message,
      message: {
        required: 'You have to upload link',
      },
      required: true,
    },
  ]
  

  const alerts = {
    add: {
      title: `Job Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/jobs')
        }
      ]
    },
    edit: {
      title: `Job Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/jobs')
        }
      ]
    },
    delete: {
      title: `Job Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/jobs')
        }
      ]
    }
  }


  const fetchJob= async(id, params = {}) => {
    setLoading(true)
    const config = {
      url: `/job${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setJobList(data) : setJob(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addJob = async() => {


    setLoading(true)

    const config = {
      url: `/job`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: job,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setJob(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editJob = async() => {
    const dataToAppendEntries = Object.entries(job)

    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
      formData.append(key, value)
    })  

    setLoading(true)
    const config = {
      url: `/job/${job?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: formData,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setJob(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteJob = async(id, callback) => {
    const config = {
      url: `/job/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setJob(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addJob(job) : editJob(job)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: jobList,
      detail: job,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchJob,
      delete: deleteJob,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}