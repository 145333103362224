import React, { useEffect } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { useGetFullDate } from '../../../hooks/useGetFullDate'
import { useNewsViewModel } from '../../../viewModels/useNewsViewModel'
import { useReportViewModel } from '../../../viewModels/useReportViewModel'
import { useVideoViewModel } from '../../../viewModels/useVideoViewModel'

const MediaDetailScreen = () => {

  const params = useParams()
  const navigate = useNavigate()
  const getFullDate = useGetFullDate()

  const categories = [
    {
      path: 'news',
      title: 'News',
      viewModel: useNewsViewModel
    },
    {
      path: 'video',
      title: 'Videos',
      viewModel: useVideoViewModel
    },
    {
      path: 'report',
      title: 'Reports',
      viewModel: useReportViewModel
    },
  ]

  const CHECK_CATEGORY = categories.find((ctg) => ctg.path.includes(params.path))
  const { state, handler } = CHECK_CATEGORY ? CHECK_CATEGORY?.viewModel() : {}  

  useEffect(() => {

    const CHECK_PATH_IS_REPORTS = params.path === 'reports'
    if(CHECK_PATH_IS_REPORTS || !params.id) return navigate('/not-found')
    handler.fetch(params.id, null, ({data, error}) => {
      if(error) return navigate('/not-found')
    })  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 
  return (
    <GlobalLayout 
      smallHero={{
        title: params.path, 
    }}>
      {state.detail.current?.title && (
        <div className='section responsive--article'>
          <div className='flex mb-4 space-x-2 text-xs capitalize'>
            <Link to={`/media`} className='link text-theme-primary'>Media</Link>
            <span>/</span>
            <Link to={`/media/${params.path}`} className='link text-theme-primary'>{`${params.path}${params.path!=='news' ? 's' : ''}`}</Link>
            <span>/</span>
            <span className='line-clamp-1'>{state.detail.current.title}</span>
          </div>
          <section className='mb-12'>
            <div className='w-full space-y-6 md:space-y-8'>
              {params.path === 'news' && (
                <div className="space-y-1">
                  <Typography
                    variant={'h5'}
                    component={'h1'}
                    weight={'bold'}
                    lineHeight={'snug'}
                  >
                    {state.detail.current.title}
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    {getFullDate(state.detail.current.createdAt)}
                  </Typography>
                </div>
              )}
              <div className="relative w-full overflow-hidden rounded-2xl bg-slate-100">
                {params.path === 'video' && (
                  <iframe className='absolute inset-0 z-10' width="100%" height="100%" src={`${state.detail.current.link}`} title="YouTube video player" frameBorder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                )}
                <ImageContainer className='w-full' src={state.detail.current.image.url} alt={state.detail.current.title} ratio={{width: 16, height: 9}}/>
              </div>
              {params.path === 'video' && (
                <Typography
                  variant={'h5'}
                  component={'h1'}
                  weight={'bold'}
                  lineHeight={'snug'}
                >
                  {state.detail.current.title}
                </Typography>
              )}
              {state.detail.current.description && (
                <Typography className='whitespace-pre-line'>
                  {state.detail.current.description}
                </Typography>
              )}
              {(state.detail.current.link && params.path !== 'video') && (
                <a href={state.detail.current.link} rel="noreferrer" target={'_blank'} className={`link underline break-all text-theme-primary`}>
                  {state.detail.current.link}
                </a>
              )}
            </div>
          </section>  
          <div className='grid text-sm divide-y border-y md:grid-cols-2 md:gap-8 md:divide-y-0'>
            {state.detail.next?.length > 0 && (
              <a href={`/media/${params.path}/${state.detail.next[0]._id}`} className={`md:order-2 md:col-start-2 link py-4 grid grid-cols-3 w-full gap-3`}>                
                <div className='flex flex-col items-end w-full col-span-2 py-4 space-y-2'>
                  <div className='flex items-center justify-end w-full space-x-1 font-semibold'>
                    <span className='capitalize'>Next {params.path}</span>
                    <IoChevronForward/>
                  </div>
                  <Typography variant={'body2'} className='text-right line-clamp-2'>
                    {state.detail.next[0].title}
                  </Typography>
                </div>
                <div className='flex items-center w-full'>
                  <ImageContainer className='w-full overflow-hidden rounded-lg bg-slate-100' src={state.detail.next[0].image.url} ratio={{width: 16, height: 9}}/>
                </div>
              </a>              
            )}
            {state.detail.previous?.length > 0 && (
              <a href={`/media/${params.path}/${state.detail?.previous[0]._id}`} className={`link md:order-1 py-4 grid grid-cols-3 w-full gap-3`}>
                <div className='flex items-center w-full'>
                  <ImageContainer className='w-full overflow-hidden rounded-lg bg-slate-100' src={state.detail?.previous[0].image.url} ratio={{width: 16, height: 9}}/>
                </div>
                <div className='flex flex-col items-start justify-center w-full col-span-2 space-y-2'>
                  <div className='flex items-center justify-start w-full space-x-1 font-semibold'>
                    <IoChevronBack/>
                    <span className='capitalize'>Previous {params.path}</span>
                  </div>
                  <Typography variant={'body2'} className='text-left line-clamp-2'>
                    {state.detail?.previous[0].title}
                  </Typography>
                </div>
              </a>              
            )}
          </div>  
        </div>      
      )}
    </GlobalLayout>
  )
}

export default MediaDetailScreen