import React, { useEffect, useRef, useState } from 'react'

const Textarea = ({
  id,
  label,
  name,
  value,
  onChange,
  rows = 30,
  cols,
  maxLength,
  validation
}) => {

  const [validationMessage, setValidationMessage] = useState(validation)

  const txtarea = useRef(null)

  const onChangeHandler = (e) => {
    onChange && onChange(e)
    if (validationMessage) setValidationMessage(null)
  }

  useEffect(() => {
    setValidationMessage(validation)
  }, [validation])

  useEffect(() => {
  }, [])

  return (
    <div className='flex flex-col w-full ' >
      {label && <label className={'text-xs font-semibold mb-2'} htmlFor={id || name}>{label}</label>}
      <textarea maxLength={maxLength} ref={txtarea} id={id} name={name} rows={rows} cols={cols} className={`w-full p-3 text-sm transition duration-150 border rounded-md focus:border-theme-primary focus:outline-none ${validationMessage ? 'bg-red-100 border-red-600' : ''}`} value={value} onChange={onChangeHandler}></textarea>
      <div className="flex justify-end w-full">
        {validationMessage ? (
          <div className={`text-xs text-red-600 mr-auto`}>{validationMessage}</div>
        ) : ''}
        {maxLength && <div className='text-xs'>{value?.length || 0}/{maxLength}</div>}
      </div>
      
    </div>
  )
}

export default Textarea