import axios from "axios"
import { useState } from "react"
import { useAuth } from "../hooks/useAuth"
import { API_BASE_URL } from "../utils/urls"

export const useMessageViewModel = () => {

  const initialState = {
    messages: {
      loading: false,
      error: '',
      data: [],
    },
    message: {
      loading: false,
      error: '',
      data: {},
    }
  }

  const [messages, setMessages] = useState(initialState.messages)
  const [message, setMessage] = useState(initialState.message)
  const [toast, setToast] = useState(null)

  const { token } = useAuth()

  const closeToast = () => {
    setToast(null)
  }

  const fetchMessages = async(page, limit, search, callback) => {
    const config = {
      url: `/message`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      params: {
        page,
        limit: limit || 10,
        search: search || null
      },
    }
    setMessages({...messages, loading: true})
    try {
      const { data } = await axios(config)
      setMessages({loading: false, data: data})
      setToast(data)
      callback && callback(data)
    } catch (err) {
      setMessages({loading: false, error: err.message})
      callback && callback(err)
    }
  }

  const sendMessage = async(payload = {}, callback) => {
    const config = {
      url: `/message`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
      data: {
        name: payload.name,
        email: payload.email,
        message: payload.message
      }      
    }
    setMessage({...message, loading: true})
    try {
      const { data } = await axios(config)
      setMessage({loading: false, data: data})
      setToast(data)
      callback && callback(data)
    } catch (err) {
      setMessage({loading: false, error: err.message})
      callback && callback(err)
    }
  }

  const fetchMessage = async(id, callback) => {
    const config = {
      url: `/message/${id}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    setMessage({...message, loading: true})
    try {
      const { data } = await axios(config)
      setMessage({loading: false, data: data})
      callback && callback(data)
    } catch (err) {
      setMessage({loading: false, error: err.message})
      callback && callback(err)
    }
  }

  const deleteMessage = async(id, callback) => {
    const config = {
      url: `/message/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },
    }
    setMessage({...message, loading: true})
    try {
      const { data } = await axios(config)
      setMessage({loading: false, data: data})
      setToast(data)
      callback && callback(data)
    } catch (err) {
      setMessage({loading: false, error: err.message})
      setToast(err)
      callback && callback(err)
    }
  }

  const returnObj = {
    state: {
      messages,
      message,
      toast
    }, 
    handler: {
      closeToast,
      fetchMessages,
      sendMessage,
      fetchMessage,
      deleteMessage,
    }
  }

  return  returnObj

}