import React, { useEffect, useRef, useState } from 'react'
import './Input.css'

const Input = ({
  inputRef,
  wrapperClassName = '',
  containerClassName = '',
  label,
  labelName,
  id,
  className = '',
  name,
  type,
  value,
  placeholder,
  onFocus,
  onBlur,
  onClick,
  onChange,
  onLoad,
  autoComplete,
  style,
  labelClassName = '',
  adornment,
  adornmentClassName = '',
  adornmentPosition,
  adornmentRotate,
  dropdown,
  dropdownData = [{id: 0, value: 'No Data'}],
  optionOnSelect,
  disabled,
  validation,
  accept
}) => {

  const [validationMessage, setValidationMessage] = useState(null)

  const ref = useRef(null)

  const adornmentPositions = ['left', 'right']

  const CHECK_ADORNMENT_POSITION = adornment ? adornmentPosition : adornmentPositions[0]
  const CHECK_ADORNMENT_FOR_INPUT_FIELD = adornment ? `input__field--${CHECK_ADORNMENT_POSITION}-adornment` : ''
  const CHECK_INPUT_FIELD_VALIDATION = validationMessage ? 'input__field--error' : ''

  const onChangeHandler = (e) => {
    onChange && onChange(e)
    if (validationMessage) setValidationMessage(null)
  }

  const optionOnSelectHandler = (e, data, idx) => {
    optionOnSelect && optionOnSelect(e, data, idx)
    if (validationMessage) setValidationMessage(null)
  }
  
  useEffect(() => {
    setValidationMessage(validation)
  }, [validation])

  useEffect(() => {
    if (!inputRef) return
    inputRef(ref)
  }, [inputRef])

  useEffect(() => {
    if(!disabled) return
    ref.current.blur()
  }, [disabled])

  return (
    <div className={wrapperClassName}>
      <div className={`input__container ${containerClassName}`}>
        {label ? (
          <label 
            className={`input__label ${labelClassName}`} 
            name={labelName || name || ''}
          >
            {label}
          </label>
        ) : ''}
        <div className='input__items'>
          <input 
            ref={ref}
            id={id}
            className={`${type !== 'file' ? `input__field ${CHECK_ADORNMENT_FOR_INPUT_FIELD} ${CHECK_INPUT_FIELD_VALIDATION} ${dropdown ? `input__field--active` : ''}` : ''} ${className}`}
            type={type || 'text'} 
            name={name}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            onChange={onChangeHandler}
            onLoad={onLoad}
            placeholder={placeholder}
            autoComplete={autoComplete}
            style={style}
            accept={accept}
          />
          {adornment ? (
            <div className={`input__adornment input__adornment--${CHECK_ADORNMENT_POSITION} ${dropdown ? adornmentRotate ? 'input__adornment--rotate' : '' : ''} ${adornmentClassName}`} >
              {adornment}
            </div>
          ) : ''}
          {dropdown ? (
            <ul className={`input__dropdown`}>
              {dropdownData.length > 0 ? (
                dropdownData?.map((data, idx) => (
                  <li key={idx} className={`input__dropdown__option`} onClick={(e) => optionOnSelectHandler(e, data, idx)}>{data.value}</li>
                ))
              ) : (
                <li className={`input__dropdown__option`}>No Data</li>
              )}
            </ul>
          ) : ''}
          {disabled ? (
            <div className='absolute inset-0 z-20 bg-gray-300 rounded-md opacity-50'></div>
          ) : ''}
        </div>
        {validationMessage ? (
          <div className={`text-xs text-red-600`}>{validationMessage}</div>
        ) : ''}
      </div>
    </div>
  )
}

export default Input