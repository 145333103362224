import React, { useEffect } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import Accordion from '../../components/Accordion/Accordion'
import Button from '../../components/Button/Button'
import IconContainer from '../../components/IconContainer/IconContainer'
import Typography from '../../components/Typography/Typography'
import { useFaqViewModel } from '../../viewModels/useFaqViewModel'


const FAQSection = ({
  className = ''
}) => {

  const { state, handler } = useFaqViewModel()

  useEffect(() => {
    handler.fetch(null, { limit: 5 })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={`flex flex-col items-center ${className}`}>
      <Typography
        variant={'h4'}
        component={'h2'}
        weight={'bold'}
        lineHeight={'tight'}
        className={'mb-12 lg:mb-20 text-center'}
      > 
        Frequently Asked Question
      </Typography>
      <div className="w-full mb-12 space-y-2">
        {state.list.records.map((el, elIdx) => (
          <Accordion
            key={elIdx}
            header={({ className, onClick }) => (
              <button className={`${className} flex items-center w-full p-5 space-x-2`} onClick={onClick}>
                <Typography variant={'subtitle1'} className='w-full text-left' weight={'semibold'}>{el.question}</Typography>
                <IconContainer className=''>
                  <IoChevronDown/>
                </IconContainer>
              </button>
            )}
            collapsible={() => (
              <div className=''>
                <Typography className='w-full p-5 text-left'>
                  {el.answer}
                </Typography>
              </div>
            )}
          />
        ))}
      </div>
      <Button component={'a'} href={'/faq'} variant={'outline'}>SEE MORE FAQ</Button>
    </section>
  )
}

export default FAQSection