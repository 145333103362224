import axios from "axios"
import { API_BASE_URL } from "../../../../utils/urls"
import { CommonSessionToken } from "../../../functions/common"

export const I_Person = (payload = {
    name: '',
    role: '',
    image: null,
    structure_id: null,
}) => {
    return {
        name: payload?.name || '',
        role: payload?.role || '',
        image: payload?.image || null,
        structure_id: payload?.structure_id || null,
    }
}

export const ACT_GetPersonList = async () => {
    const token = CommonSessionToken()

    let data, error

    try {
        const response = await axios.get(
            `${API_BASE_URL}/person`,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`,
                },
            }
        )
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message || err?.message
        error = errMessage
    }
    return { error, data }
}

export const ACT_AddPerson = async (person,) => {
    const token = CommonSessionToken()
    let data, error
    const dataToAppend = {
        name: person?.name,
        role: person?.role,
        image: person?.image,
        structure_id: person?.structure_id,
    }

    const dataToAppendEntries = Object.entries(dataToAppend)
    const formData = new FormData()
    dataToAppendEntries.forEach(([key, value]) => {
        value && formData.append(key, value)
    })

    try {
        const response = await axios.post(
            `${API_BASE_URL}/person`,
            formData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            }
        )
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message || err?.message
        error = errMessage
    }
    return { error, data }


}

export const ACT_UpdatePerson = async (person) => {
    const token = CommonSessionToken()
    let data, error
    const dataToAppend = {
        name: person?.name,
        role: person?.role,
        image: person?.image,
        structure_id: person?.structure_id,
    }

    const dataToAppendEntries = Object.entries(dataToAppend)
    const formData = new FormData()

    dataToAppendEntries.forEach(([key, value]) => {
        value && formData.append(key, value)
    })

    try {
        const response = await axios.put(
            `${API_BASE_URL}/person/` + person._id,
            formData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        )
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message || err?.message
        error = errMessage
    }
    return { error, data }


}


export const ACT_DeletePerson = async (person_id) => {

    const token = CommonSessionToken()
    let data, error

    try {
        const response = await axios.delete(
            `${API_BASE_URL}/person/` + person_id,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        )
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message || err?.message
        error = errMessage
    }
    return { error, data }


}

export const ACT_MovePerson = async (payload) => {
    const token = CommonSessionToken()
    let data, error

    try {
        const response = await axios.post(
            `${API_BASE_URL}/person/move/` + payload?.person_id,
            {
                structure_id: payload?.structure_id,
                direction: payload?.direction
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            }
        )
        data = response?.data
    } catch (err) {
        let errMessage = err?.response?.data?.message || err?.message
        error = errMessage
    }
    return { error, data }


}