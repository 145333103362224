
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import ImageInput from '../../../../components/ImageInput/ImageInput'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import Textarea from '../../../../components/Textarea/Textarea'
import TextField from '../../../../components/TextField/TextField'
import Typography from '../../../../components/Typography/Typography'
import { useJobViewModel } from '../../../../viewModels/useJobViewModel'



const CareerJobScreen = ({method}) => {
  const params = useParams()

  const { state, handler, element } = useJobViewModel(method)
  
  useEffect(() => {
    if(method !== 'edit') return
    handler.fetch(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method])

  return (
    <CMSLayout alert={state.alert} title={`${method} Job ${params.id ? `#${params.id}` : ''}`}>
      <Card className='flex flex-col p-5 col-span-full'>
      <div className="space-y-2 responsive responsive--article">
        {element.inputs.map((inp, inpIdx) => (
          <div  key={inpIdx} className={'space-y-1'}>
            <Typography variant={'subtitle1'} weight={'semibold'} className={'capitalize'}>
              {inp.title}
            </Typography>
            {inp.name === 'description' ? (
              <Textarea
                {...inp}
              />            
            ) : inp.name === 'image' ? (
              <ImageInput
                imageFit='contain'
                {...inp}
              />
            ) : (
              <TextField               
                {...inp}
              />
            )}
          </div>
        ))}
        <div className='w-full pt-5'>
          <Button className='w-full capitalize' disabled={state.loading} onClick={() => handler.submit(method)}>
            {method} Job
          </Button>
        </div>
      </div>
    </Card>
    </CMSLayout>
  )
}

export default CareerJobScreen