import React, { useEffect } from 'react'
import Pagination from '../Pagination/Pagination'

const Table = ({
  className='',
  headClassName='',
  headRowClassName='',
  bodyClassName='',
  bodyRowClassName='',
  page ,
  pageTotal,
  maxDataPreview,
  data,
  pickData,
  actions,
  containerClassName = '',
  pagination,
  paginationOnClick,
  listNewState,
  listOnClick,
}) => {

  return (
    <div className={`w-full ${containerClassName} text-sm`}>
      <div className="w-full overflow-x-auto">
        <table className={`w-full overflow-hidden rounded-t-xl ${className}`}>
          <thead className={`text-white bg-theme-primary ${headClassName}`}>
            <tr className={headRowClassName}>
              <th className='w-12 px-5 py-3 text-center'>
                No.
              </th>
              {pickData && pickData.map((pckDt, pckDtIdx) => (
                <th key={pckDtIdx} className={`px-5 py-3  ${pckDt.className} `}>{pckDt.title}</th>
              ))}
              {actions && (
                <th className='flex-shrink-0 w-40 px-5 py-3 ml-auto'>Actions</th>
              )}
            </tr>
          </thead>
          {data && data.length > 0 ? (
            <tbody className={`border-b divide-y  ${bodyClassName}`}>
              {data && data.map((tr, trIdx) => (
                <tr key={trIdx} className={`${styles.list.default} ${listNewState ? listNewState(tr) ? styles.list.new : styles.list.old : ''} ${bodyRowClassName}`} onClick={(e) => listOnClick && listOnClick(e, tr)}>
                  <td className='w-12 px-5 py-3 text-center'>
                    {pagination ? ((maxDataPreview * (page - 1)) + (trIdx + 1)) : trIdx + 1}
                  </td>
                  {pickData && pickData.map((pckData, pckDtIdx) => (
                    <td key={pckDtIdx} className={`px-5 py-3 ${pckData.className}`}>
                      <div className='line-clamp-1'>{pckData.processor && tr[pckData.selector] ? pckData.processor(tr[pckData.selector]) : tr[pckData.selector]}</div>
                    </td>
                  ))}
                  {actions && (
                    <td className='flex justify-center flex-shrink-0 w-40 px-5 py-3 ml-auto'>
                      {actions(tr, trIdx)}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className={`border-b divide-y h-96 relative  ${bodyClassName}`}>
              <tr className='absolute inset-0 flex flex-col items-center justify-center'>
                <td>No Data</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {pagination && (
        <Pagination className='justify-end w-full mt-5' page={page} pageTotal={pageTotal} paginationOnClick={paginationOnClick}/>
      )}
    </div>
  )
}

const styles = {
  list: {
    default: `cursor-pointer bg-white hover:bg-slate-50`,
    new: `font-semibold`,
    old: `bg-slate-100`
  }
}

export default Table