export const useGetFullDate = () => {
  const getFullDate = (dateString) => {
    const newDate = new Date(dateString);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    const date = newDate.getDate()
    const CHECK_DATE = Number(date) === 1 || Number(date) - 20 === 1 || Number(date) - 30 === 1 ? 'st' : Number(date) === 2 || Number(date) - 20 === 2 ? 'nd' : Number(date) === 3 || Number(date) - 20 === 3 ? 'rd' : 'th'
    const fullDate = `${monthNames[newDate.getUTCMonth()]} ${date}${CHECK_DATE}, ${newDate.getFullYear()}`
    return fullDate
  }

  return getFullDate
}