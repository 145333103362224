import React, {  useState } from 'react'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Textarea from '../../../components/Textarea/Textarea'
import TextField from '../../../components/TextField/TextField'
import Toast from '../../../components/Toast/Toast'
import Typography from '../../../components/Typography/Typography'
import { useValidateValue } from '../../../hooks/useValidateValue'
import { useMessageViewModel } from '../../../viewModels/useMessageViewModel'

const ContactScreen = () => {

  const { state, handler } = useMessageViewModel()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({})

  const validateValue = useValidateValue()

  const valueOnChangeHandler = (e, func) => {
    func(e.target.value)
  }
  

  const valueToValidate = [
    {
      name: "name",
      value: name,
      required: true,
      message: {
        required: 'Name is required'
      },
    },
    {
      name: "email",
      value: email,
      required: true,
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,  // eslint-disable-line
      type: 'email',
      message: {
        required: 'Email is required',
        invalid: 'Invalid Email format'
      },
    },
    {
      name: "message",
      value: message,
      minLength: 3,
      message: {
        required: 'Message is required',
        minLength: 'Minimum 3 character'
      },
    },
  ]

  const submit = () => {
    const valueValidation = validateValue(valueToValidate)
    setErrors(valueValidation)
    if(valueValidation) return
    handler.sendMessage({name, email, message: message}, (res) => {
      [setName, setEmail, setMessage].forEach((func) => {
        func('')
      })
    })
  }

  const contactInfo = [
    {
      title: 'Address',
      description: `Jl. Kali Baru Timur 11 No.12, RT.12/RW.2, Kali Baru, Kec. Cilincing, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14110, Indonesia`
    },
    {
      title: 'Phone',
      description: `+622174866447`
    },
    {
      title: 'Email',
      description: `info@rednosefoundation.org`
    },
  ]
  

  return (
    <>
      <GlobalLayout smallHero={{title: "Contact Us"}} contactJumbotron={false}>
        
        <section className="grid gap-12 section lg:grid-cols-2">
          <div className='flex flex-col justify-center'>
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-12 text-center lg:text-left'}
            > 
              Contact Info
            </Typography>
            {contactInfo.map((ctc, ctcIdx) => (
              <div key={ctcIdx} className="mb-5 space-y-2">
                <Typography >
                  {ctc.title}
                </Typography>
                <Typography variant={'subtitle1'}>
                  {ctc.description}
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex items-center">
            <Card className='flex flex-col w-full p-8 space-y-2 shadow-lg'>
              <TextField label={`Your Name`} value={name} onChange={(e) => valueOnChangeHandler(e, setName)} type={'text'} validation={errors?.name?.message}/>
              <TextField label={`Your Email`} value={email} onChange={(e) => valueOnChangeHandler(e, setEmail)} type={'email'} validation={errors?.email?.message}/>
              <Textarea label={`Your Message`} value={message} rows={'10'} onChange={(e) => valueOnChangeHandler(e, setMessage)} validation={errors?.message?.message}/>
              <div className="w-full pt-5">
                <Button className='w-full' color={'secondary'} onClick={submit} disabled={state.message.loading}>Kirim Pesan</Button>
              </div>
            </Card>
          </div>
        </section>
        <section className='-mb-[32vw] md:-mb-[20vw] lg:-mb-[16vw] grid overflow-hidden w-full xl:grid-cols-2'>
          <div className="relative w-full">
            <ImageContainer 
              className={'w-full'}
              breakpoints={{
                0: {width: 9, height: 16},
                768: {width: 4, height: 3},
                1024: {width: 16, height: 9},
                1280: {width: 9, height: 9}
              }}
            />
            <iframe title='map' className='absolute inset-0' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.226999018916!2d106.92813331531814!3d-6.100100961534291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a20373420b423%3A0x3aea3c29b1e8cd85!2sRed%20Nose%20Foundation!5e0!3m2!1sen!2sid!4v1656948955715!5m2!1sen!2sid" width={'100%'} height="100%" style={{border:0,}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="relative w-full">
            <ImageContainer 
              className={'w-full'}
              breakpoints={{
                0: {width: 9, height: 16},
                768: {width: 4, height: 3},
                1024: {width: 16, height: 9},
                1280: {width: 9, height: 9}
              }}
            />
            <iframe title='map' className='absolute inset-0' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9515638305693!2d106.72769269999999!3d-6.2701005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f18591e88457%3A0xa9289a00f39174d7!2sJurangmangu%20Community%20Center%20for%20Children%20(JCCC)%20-%20Red%20Nose%20Foundation!5e0!3m2!1sen!2sid!4v1661914385220!5m2!1sen!2sid" width={'100%'} height="100%" style={{border:0,}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section>
      </GlobalLayout>
      <Toast active={state.toast} message={state.toast?.message} variant={state.toast?.status_code !== 200 ? 'danger' : 'success'} deactiveFunc={handler.closeToast}/>
    </>
  )
}

export default ContactScreen