import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MediaCard from '../../../components/Cards/MediaCard/MediaCard'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { useNewsViewModel } from '../../../viewModels/useNewsViewModel'
import { useReportViewModel } from '../../../viewModels/useReportViewModel'
import { useVideoViewModel } from '../../../viewModels/useVideoViewModel'

const MediasScreen = () => {

  const news = useNewsViewModel()
  const video = useVideoViewModel()
  const report = useReportViewModel()

  const mediaData = [
    {
      title: 'News',
      path: '/media/news',
      ...news
    },
    {
      title: 'Videos',
      path: '/media/video',
      ...video
    },
    {
      title: 'Reports',
      path: '/media/report',
      ...report
    },
  ]

  useEffect(() => {
    const propsArr = [
      null, 
      { limit: 3 }
    ]
    news.handler.fetch(...propsArr)
    video.handler.fetch(...propsArr)
    report.handler.fetch(...propsArr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  return (
    <GlobalLayout smallHero={{title: "Media"}} contentClassName={'space-y-20 lg:space-y-24 section-non-responsive'}>
      {mediaData.map((media, mediaIdx) => (
        media.state.list.records?.length > 0 && (
          <section key={mediaIdx} className="w-full ">
            <div className="flex items-center justify-between mb-12 lg:mb-16 responsive">
              <Typography
                variant={'h4'}
                component={'h2'}
                weight={'bold'}
                lineHeight={'tight'}
                className={''}
              >
                {media.title}
              </Typography>
              <Link to={media.path} className='text-sm link text-theme-primary'>See More</Link>
            </div>
            <div className={'flex w-full relative overflow-x-scroll scrollbar--hidden'}>
              <div className={`flex px-8 pb-5 space-x-4 lg:responsive lg:grid lg:space-x-0 lg:gap-5 lg:grid-cols-3`}>
                {media.state.loading ? (
                  'loading'
                ) : (
                  media.state.list.records?.map((el, idx) => (
                    <MediaCard
                      key={idx}
                      className={`w-72 lg:w-full flex-shrink-0`}
                      data={{
                        title: el.title,
                        createdAt: media.title === 'News' && el.createdAt,
                        image: {
                          src: el.image.url,
                          alt: el.title,
                          ratio: media.title === 'News' ? {width: 4, height: 3} : media.title === 'Reports' ? {width: 3, height: 4} : {width: 16, height: 9} 
                        },
                        description: el.description,
                        action: {
                          title: media.title === "Videos" ? "WATCH" : "READ",
                          props: (
                            media.title === "Reports" ? { component: "a", href: `${el.link.includes('http') ? '' : 'https://'}${el.link}`, target: "_blank" }
                            : { component: "a", href: `${media.path}/${el._id}` }
                          )
                        }
                      }}
                    />
                  ))
                )}
              </div>
            </div>     
          </section>
        )
      ))}
    </GlobalLayout>
  )
}

export default MediasScreen