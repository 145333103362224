import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import Table from '../../../../components/Table/Table'
import TextField from '../../../../components/TextField/TextField'
import { useSeratusViewModel } from '../../../../viewModels/useSeratusViewModel'
import ActionButtons from '../../../components/ActionButtons/ActionButtons'

const DonationsSeratusScreen = ({page, paginationOnClick, pageNav}) => {

  const { state, handler, pickData } = useSeratusViewModel()  

  const [search, setSearch] = useState('')

  const navigate = useNavigate()  

  const searchOnChange = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {       
    handler.fetchSeratusList(page, null, search.length < 3 ? null : search)
    if(search.length < 3) return
    paginationOnClick(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search])



  return (
    <>
      <CMSLayout title={'Gerakan 100.000 Pahlawan'} toast={state.toast ? {...state.toast, close: handler.closeToast} : null }>
      <div className="flex space-x-2 col-span-full">
        {pageNav.map((pgNav, pgNavIdx) => (
          <Button key={pgNavIdx} variant={'outline'} active={pgNav.active} onClick={pgNav.onClick}>
            {pgNav.title}
          </Button>
        ))}
      </div>
        <Card className='flex-col p-5 space-y-4 col-span-full'>
          <div className="flex justify-between w-full">
            <TextField
              label={'Search'}
              wrapperClassName='w-1/4'
              variant={'search'}
              placeHolder={'Search name, email, phone'}
              value={search}
              onChange={searchOnChange}
            />
          </div>
          <Table
            containerClassName='w-full'
            data={state.seratusList?.records || []}
            pagination={true}
            page={page}
            pageTotal={state.seratusList?.totalPages || 1}
            maxDataPreview={state.seratusList?.limit}
            paginationOnClick={paginationOnClick}
            pickData={pickData.seratusList}
            actions={(list) => (
              <ActionButtons 
                deleteAction={async()=> {
                  await handler.deleteSeratus(list._id)
                  handler.fetchSeratusList()
                  paginationOnClick(1)
                }}
                seeDetailAction={() => navigate(`/cms/donation/seratus/view/${list._id}`)}
              />
            )}
          />
        </Card>
      </CMSLayout>
     
    </>
  )
}

export default DonationsSeratusScreen