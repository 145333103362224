import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Table from '../../../components/Table/Table'
import TextField from '../../../components/TextField/TextField'
import { useAuth } from '../../../hooks/useAuth'
import { useMessageViewModel } from '../../../viewModels/useMessageViewModel'
import ActionButtons from '../../components/ActionButtons/ActionButtons'

const MessagesScreen = () => {

  const { auth } = useAuth()
  const { state, handler } = useMessageViewModel()

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const navigatePage = (pageValue) => navigate({ pathname: `${location.pathname}`, search: `?page=${pageValue}`})

  const paginationOnClickHandler = (value) => {
    navigatePage(value)
  }

  const deleteMessageHandler = (data) => {
    handler.deleteMessage(data._id, () => {
      handler.fetchMessages()
      paginationOnClickHandler(1)
    })
    
  }

  const messagesPickData = [
    {
      title: 'Name',
      selector: 'name',
      className: 'whitespace-nowrap'
    }, 
    {
      title: 'Email',
      selector: 'email',
      className: 'whitespace-nowrap w-1/5 text-left'
    },     
    {
      title: 'Message',
      selector: 'message',
      className: 'text-left'
    },
  ]  

  useEffect(() => {
    if(!auth) return
    if(!location.search.includes('page')) return navigate({pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search.replace('?', '').split('&').find(x => x.includes('page')).replace('page=', '')
    setPage(currentPage)
    handler.fetchMessages(currentPage, null, search.length < 3 ? null : search)
    if(search.length < 3) return
    paginationOnClickHandler(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location.pathname, location.search, navigate, search])

  return (
    <CMSLayout title={'Messages'}>
      <Card className='flex-col p-5 space-y-4 col-span-full'>
        <div className="flex justify-between w-full">
          <TextField
            label={'Search'}
            wrapperClassName='w-1/4'
            variant={'search'}
            placeHolder={'Search ID, Name, Email, etc...'}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <Table
          containerClassName='w-full'
          data={state.messages?.data?.records}
          pagination={true}
          page={page}
          pageTotal={state.messages?.data?.totalPages}
          maxDataPreview={state.messages?.data?.limit}
          paginationOnClick={paginationOnClickHandler}
          pickData={messagesPickData}
          listNewState={(data) => data.status === 0}
          actions={(data) => (
            <ActionButtons 
              deleteAction={() => deleteMessageHandler(data)}
              seeDetailAction={() => navigate(`/cms/messages/view/${data._id}`)}
            />
          )}
        />
      </Card>
      

    </CMSLayout>
  )
}

export default MessagesScreen