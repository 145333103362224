import React, { useEffect, useState } from 'react'
import { IMAGE_PATH } from '../../constants/urlConstants'

const ImageContainer = ({
  className = '',
  ratio = { width: '', height: ''},
  src,
  alt,
  fit = 'cover',
  position = 'center',
  breakpoints,
  onClick
}) => {

  const [currentRatio, setCurrentRatio] = useState(ratio)

  const CHECK_IMAGE_RATIO = Number(currentRatio.height || 1 ) / Number(currentRatio.width || 1 ) * 100

  useEffect(() => {
    if(!breakpoints) return
    const checkWindowWidth = () => {
      const entries = Object.entries(breakpoints)
      entries.forEach(([key, value], idx) => {
        if(window.innerWidth >= key ) setCurrentRatio(value)
      })
    }
    checkWindowWidth()
    window.addEventListener('resize', checkWindowWidth)
    return () => {
      checkWindowWidth()
      window.removeEventListener('resize', checkWindowWidth)
    }
  }, [breakpoints])


  return (
    <div className={`flex ${className}`}>
      <div className="relative w-full pt-[67%]" style={{paddingTop: `${CHECK_IMAGE_RATIO}%`}}>
        <img src={src || `${IMAGE_PATH}/defaults/no-image.png`} alt={alt}  className='absolute inset-0 w-full h-full' style={{objectFit: fit, objectPosition: position}} onClick={onClick}/>
      </div>
    </div>
  )
}

export default ImageContainer