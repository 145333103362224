import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Button/Button'
import ShapeMask from '../../components/ShapeMask/ShapeMask'
import Typography from '../../components/Typography/Typography'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay   } from "swiper";
import { IMAGE_PATH } from '../../constants/urlConstants'

const HeroSection = () => {
  return (
    <ShapeMask 
      component={'section'}
      maskImage={`./assets/img/shapes/shape_1.svg`}
      maskPosition={'bottom'}
      overlay={true}
      className={styles.wrapper}
    >
      <div className='absolute inset-0'>
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          className="w-full h-full"
          loop={true}
        >
          {Array(5).fill('').map((slide, slideIdx) => (
            <SwiperSlide key={slideIdx} className={`bg-theme-primary`}>
              <img src={`${IMAGE_PATH}/photos/hero/slide-${slideIdx+1}.jpg`} alt={`slide${slideIdx+1}`} className={`opacity-50 h-full w-full object-cover object-center`}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.container}>
        <Typography
          variant={'h2'}
          component={'h1'}
          className={'text-white'}
          weight={'bold'}
          lineHeight={'tight'}
        >
          Helping Children <br/>
          Build Dreams
        </Typography>
        <Link to={'/seratus-ribu-pahlawan'}>
          <Button color={'secondary'} className={'uppercase'}>Join Gerakan 100.000 Pahlawan</Button>
        </Link>
      </div>
    </ShapeMask>
  )
}

const styles = {

  wrapper: "relative flex flex-col items-center w-full h-[100vh] ",
  container: "max-w-xl w-full mx-auto flex flex-col items-center space-y-5 text-center justify-center h-full relative z-10",
}

export default HeroSection

