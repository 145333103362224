import React from 'react'
import BasicCarousel from '../../../components/Carousels/BasicCarousel/BasicCarousel'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import SeratusJumbotron from '../../../components/Jumbotrons/SeratusJumbotron/SeratusJumbotron'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import SeratusRibuDonationSection from './SeratusRibuDonationSection'

const SeratusRibuPahlawanScreen = () => {

  const images = [
    {
      src: `${IMAGE_PATH}/photos/seratus/slide-1.jpg`,
      alt: `slide-1`
    },
    {
      src: `${IMAGE_PATH}/photos/seratus/slide-2.jpg`,
      alt: `slide-2`
    },
    {
      src: `${IMAGE_PATH}/photos/seratus/slide-3.jpg`,
      alt: `slide-3`
    },
  ]

  return (
    <GlobalLayout     
      smallHero={{
        title: `Gerakan 100.000 Pahlawan`, 
        image: `${IMAGE_PATH}/photos/small_hero/seratus-small_hero.jpg`
      }}
    >
      <div className='section responsive--article'>
        <section className='mb-12'>
          <div className='w-full space-y-6 md:space-y-8'>
            <BasicCarousel 
              containerClassName='w-full'
              navigation={true}
              loop={true}
              data={images}
              slideClassName={'rounded-xl overflow-hidden bg-slate-100'}
              renderSlide={(image, idx) => (
                <ImageContainer src={image.src} alt={image.alt} className='' ratio={{width: 16, height:9}}/>
              )}
            /> 
            <div className='space-y-4'>
              <Typography className='' lineHeight={'relaxed'}>
                To achieve our vision and mission to educate RNF children, every support and donation from <b>YOU</b> is priceless to help us achieve our goals.Increasing and elevating all RNF children' education level, job opportunities, prosperity and also health are RNF main goals in delivering our job.  
              </Typography>
              <Typography className='' lineHeight={'relaxed'}>
                In order to achieve our goals, RNF wants to invite <b>100.000</b> people to become 100.000 Pahlawan by donating <b>Rp100.000,-</b> or <b>$10</b>. Your donation altogether will bring a huge impact in helping children over Indonesia to have a proper education and reach their dreams. There for, please join us in:
              </Typography>
              <Typography className='text-center' lineHeight={'relaxed'} weight={'bold'} variant={"h5"}>
                “GERAKAN 100.000 PAHLAWAN”
              </Typography>
              <Typography className='' lineHeight={'relaxed'}>
                By becoming one of our Pahlawan, <b>YOU</b> will always be a part of Red Nose Foundation's big family.
              </Typography>
            </div>
            <SeratusJumbotron/>
          </div>
        </section>  
      </div>    
      <SeratusRibuDonationSection/>
    </GlobalLayout>
  )
}

export default SeratusRibuPahlawanScreen