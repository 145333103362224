import React from 'react'

const ListCard = ({
  className = '',
  children,
  onClick,
  element
}) => {
  
  return (
    <div className={`${styles.listCard} ${element === 'button' ? `cursor-pointer rounded-xl hover:bg-slate-50` : ''} ${className}`} onClick={onClick}>
      {children}
    </div>
  )
}

const styles = {
  listCard: `flex items-center w-full p-4 transition duration-150 border shadow`
}

export default ListCard