import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Table from '../../../components/Table/Table'
import TextField from '../../../components/TextField/TextField'
import Typography from '../../../components/Typography/Typography'
import { useGetFullDate } from '../../../hooks/useGetFullDate'
import { useFaqViewModel } from '../../../viewModels/useFaqViewModel'

import ActionButtons from '../../components/ActionButtons/ActionButtons'

const FAQScreen = () => {

  const [page, setPage] = useState(1)

  const { state, handler } = useFaqViewModel()

  const location = useLocation()
  const navigate = useNavigate()
  const getFullDate = useGetFullDate()

  const pickData = [
    {
      title: 'Question',
      selector: 'question',
      className: 'text-left w-1/3 flex-shrink-0'
    }, 
    {
      title: 'Answer',
      selector: 'answer',
      className: 'text-left w-full'
    },
    {
      title: 'Published Date',
      selector: 'createdAt',
      processor: getFullDate,
      className: 'whitespace-nowrap text-center w-1/5 flex-shrink-0'
    },
  ]

  const navigatePage = (pageValue) => navigate({ pathname: `${location.pathname}`, search: `?page=${pageValue}`})

  const paginationOnClickHandler = (value) => {
    navigatePage(value)
  } 

  useEffect(() => {
    if(!location.search.includes('page')) return navigate({pathname: location.pathname, search: `?page=1`})
    const currentPage = location.search.replace('?', '').split('&').find(x => x.includes('page')).replace('page=', '')
    setPage(currentPage)
    handler.fetch(null, { search: state.search.length < 3 ? null : state.search, page: currentPage })
    if(state.search.length < 3) return
    paginationOnClickHandler(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, navigate, state.search, page])

  return (
    <CMSLayout title={'Media'}>
      <Card id={'faqs'} className='flex-col p-5 space-y-4 col-span-full'>
        <Typography
          variant={'h6'}
          weight={'bold'}
          className={'capitalize'}
        > 
          Faq List
        </Typography>
        <div className="flex items-end justify-between w-full">
          <TextField
            label={'Search'}
            wrapperClassName='w-1/4'
            variant={'search'}
            placeHolder={'Search title'}
            value={state.search}
            onChange={handler.search}
          />
          <Button  className={'capitalize'} color={'secondary'} onClick={() => navigate(`/cms/faq/add`)}>
            Add Faq
          </Button>          
        </div>        
        <Table
          containerClassName='w-full'
          className='flex flex-col'
          headRowClassName={'flex w-full'}
          bodyRowClassName={'flex w-full items-center'}
          data={state.list?.records}
          pagination={true}
          page={page}
          pageTotal={state.list.totalPages}
          maxDataPreview={state.list.limit}
          paginationOnClick={paginationOnClickHandler}
          pickData={pickData}
          actions={(data) => (
            <ActionButtons 
              deleteAction={() => handler.delete(data._id, () => { 
                handler.fetch()
                navigatePage(1)
              })}
              editAction={() => navigate(`/cms/faq/edit/${data._id}`)}
            />
          )}
        />
      </Card>
    </CMSLayout>
  )
}

export default FAQScreen