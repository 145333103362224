import React, { useEffect, useRef } from 'react'
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom'
import { IoMenu } from 'react-icons/io5'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import useToggle from '../../../hooks/useToggle'
import IconContainer from '../../IconContainer/IconContainer'
import './GlobalLayout.css'
import Overlay from '../../Overlay/Overlay'
import ShapeMask from '../../ShapeMask/ShapeMask'
import Typography from '../../Typography/Typography'
import CallForActionJumbotron from '../../Jumbotrons/CallForActionJumbotron/CallForActionJumbotron'
import ImageContainer from '../../ImageContainer/ImageContainer'
import data from '../../../utils/data'
import SmallHero from '../../Heroes/SmallHero/SmallHero'

const GlobalLayout = ({ smallHero, children, contentClassName, contactJumbotron = true}) => {

  const headerCollMenus = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const [menu, toggleMenu] = useToggle(false)
  const [headerActive, toggleHeaderActive] = useToggle(false)

  const menuOnClickHandler = () => {
    toggleMenu()
  }   

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
  }, []);
  
  useEffect(() => {
    const currentHeaderCollMenus = headerCollMenus.current
    currentHeaderCollMenus.style.maxHeight = menu ? currentHeaderCollMenus.scrollHeight + 'px' : ''
    document.body.style.overflow = menu ? 'hidden' : ''
  }, [menu])

  useEffect(() => {
    if(!smallHero && location.pathname !== '/') return toggleHeaderActive(true)
    function scrollFunction() {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        toggleHeaderActive(true)
      } else {
        toggleHeaderActive(false)
      }
    }
    scrollFunction()
    window.addEventListener('scroll', scrollFunction)
    return () => { window.removeEventListener('scroll', scrollFunction) }
  }, [location.pathname, smallHero, toggleHeaderActive])

  const CHECK_MENU = menu ? 'active' : ''
  const CHECK_HEADER_ACTIVE = headerActive ? 'active' : CHECK_MENU
  
  return (
    <>
      <header className={`header ${CHECK_HEADER_ACTIVE}`}>
        <div className={`header__content ${CHECK_HEADER_ACTIVE}`}>
          <Link to={'/'} className='w-64'>
            <img src={`${IMAGE_PATH}/logo/type_logo.png`} alt="" />
          </Link>
          <div className="flex items-center space-x-4 lg:hidden">
            <button onClick={menuOnClickHandler}>
              <IconContainer className='text-2xl'>
                <IoMenu />
              </IconContainer>
            </button>
          </div>
          <div className="items-center hidden space-x-8 lg:flex">
            {data.link.navigation.map((nav, navIdx) => (
              <NavLink key={navIdx} to={nav.path} className={'link text-sm'}>
                {nav.title}
              </NavLink>
            ))}
          </div>
        </div>
        <div ref={headerCollMenus} className={`header__collapsible__menu ${CHECK_MENU}`}  onClick={menuOnClickHandler}>
          <div className="flex flex-col px-5 pb-8 space-y-3">
            {data.link.navigation.map((nav, navIdx) => (
              <NavLink key={navIdx} to={nav.path} className={'link text-sm'}>
                {nav.title}
              </NavLink>
            ))}
          </div>
        </div>
      </header>
      {smallHero && <SmallHero title={smallHero.title} subtitle={smallHero.subtitle} backgroundPosition={smallHero.backgroundPosition} src={smallHero.image}/>}
      <main className={`${contentClassName}`}>
        {children}
        {contactJumbotron && (
          <section className='section'>
            <CallForActionJumbotron title={`Do You Have Any Question?`} action={{title: 'CONTACT NOW', onClick: () => navigate('/contact')}}/>
          </section>
        )}
        <Overlay active={menu} onClick={menuOnClickHandler}/>
      </main>
      <ShapeMask 
        component={'footer'}
        maskImage={`${IMAGE_PATH}/shapes/shape_4.svg`}
        backgroundImage={`${IMAGE_PATH}/pattern/pattern1.png`}
        className={'bg-theme-tertiary-100 xl:py-20 mt-24'}
      >
        <div className='flex flex-col justify-center w-full h-full responsive'>
          <div className="grid gap-16 py-20 lg:grid-cols-3 lg:py-24 lg:gap-y-5">
            <div className="flex flex-col items-center lg:col-span-full lg:items-start">
              <ImageContainer className='w-20' src={`${IMAGE_PATH}/logo/logo.png`}/>
            </div>
            <div className='flex flex-col items-center space-y-6 text-center lg:text-left lg:items-start'>
              <div className='space-y-4'>
                <Typography variant={'subtitle2'} weight={'bold'}>
                  About Us
                </Typography>
                <Typography variant={'subtitle2'}>
                  Red Nose Foundation (RNF) is a non-profit organization that focuses on arts, education, and personal development based in Jakarta, Indonesia.
                </Typography>
              </div>
            </div>
            <div className="flex flex-col items-center w-full">
              <div className='flex flex-col items-center space-y-4 text-center lg:text-left lg:items-start'>
                <Typography variant={'subtitle2'} weight={'bold'}>
                  Links
                </Typography>
                <div className='flex flex-col space-y-2'>              
                  {data.link.navigation.map((nav, navIdx) => (
                    <Link key={navIdx} to={nav.path} className={'link text-sm'}>
                      {nav.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center space-y-4 text-center'>
              <Typography variant={'subtitle2'} weight={'bold'}>
                Follow Us
              </Typography>
              <div className='flex space-x-8'>              
                {data.link.socmed.map((nav, navIdx) => (
                  <a key={navIdx} href={nav.path} target={"_blank"} rel="noopener noreferrer"  className={'link text-lg'} title={nav.title}>
                    <IconContainer>
                      {nav.icon}
                    </IconContainer>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <Typography variant={'subtitle2'} weight={'medium'} className={'text-center pb-5'}>Copyright ©2022 Red Nose Foundation. All rights reserved.</Typography>
          <Typography variant={'subtitle2'} weight={'medium'} className={'text-center flex items-center justify-center space-x-2 pb-5 lg:pb-0'}>
            <div>Powered by:</div>
            <a href="https://entrustdigital.co.id" target="_blank" rel="noopener noreferrer">
              <img className='w-20' src={`${IMAGE_PATH}/logo/donors/Entrust.png`} alt="" />
            </a>
          </Typography>
        </div>
      </ShapeMask>
      
    </>
  )
}

export default GlobalLayout