import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useStatisticViewModel = () => {

  const initialState = {
    statistic: {
      helps: { kids: '', countries: '', cities: '' },
      seratus: { amount: '', count: '' }
    }
  }

  const { token } = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()

  const [search, setSearch] = useState('')
  const [statistic, setStatistic] = useState(initialState.statistic)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e, query) => {
    setStatistic({ ...statistic, [query]: { ...statistic[query], [e.target.name]: Number(e.target.value) } })
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      title: 'Helps Statistic',
      name: 'helps',
      fields: [
        {
          name: 'kids',
          label: 'Kids Count',
          value: statistic?.helps?.kids || '',
          onChange: (e) => textFieldOnChange(e, 'helps'),
          validation: errors?.helps?.kids?.message,
          message: {
            required: 'Title cannot be empty',
            invalid: 'Statistic with the same kids is already exist'
          },
          required: true,
          type: 'number'
        },
        {
          name: 'countries',
          label: 'Country Count',
          value: statistic?.helps?.countries || '',
          onChange: (e) => textFieldOnChange(e, 'helps'),
          validation: errors?.helps?.countries?.message,
          message: {
            required: 'You have to fill the country',
          },
          required: true,
          type: 'number'
        },
        {
          name: 'cities',
          label: 'Cities',
          value: statistic?.helps?.cities || '',
          onChange: (e) => textFieldOnChange(e, 'helps'),
          validation: errors?.helps?.cities?.message,
          message: {
            required: 'You have to fill cities',
          },
          required: true,
          type: 'number'
        },
      ]
    },
    {
      title: 'Seratus Ribu Pahlawan Statistic',
      name: 'seratus',
      fields: [
        {
          name: 'amount',
          label: 'Donation Amount',
          value: statistic?.seratus?.amount || '',
          onChange: (e) => textFieldOnChange(e, 'seratus'),
          validation: errors?.seratus?.amount?.message,
          message: {
            required: 'Amount cannot be empty',
          },
          required: true,
          type: 'number'
        },
        {
          name: 'count',
          label: 'Donation Count',
          value: statistic?.seratus?.count || '',
          onChange: (e) => textFieldOnChange(e, 'seratus'),
          validation: errors?.seratus?.count?.message,
          message: {
            required: 'Count cannot be empty',
          },
          required: true,
          type: 'number'
        },

      ]
    }
  ]


  const alerts = {
    add: {
      title: `Statistic Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => {
            navigate('/cms/statistic')
            setAlert(null)
          }
        }
      ]
    },
    edit: {
      title: `Statistic Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => {
            navigate('/cms/statistic')
            setAlert(null)
          }
        }
      ]
    },
    delete: {
      title: `Statistic Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => {
            navigate('/cms/statistic')
            setAlert(null)
          }
        }
      ]
    }
  }


  const fetchStatistic = async (params = {}) => {
    setLoading(true)
    const config = {
      url: `/statistic`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if (!data) return
      setLoading(false)
      setStatistic(data?.data || initialState.statistic)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? { ...errors, api: err.message } : { api: err.message })
    }
  }

  const addStatistic = async () => {


    setLoading(true)

    const config = {
      url: `/statistic`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      data: statistic,
    }
    try {
      const { data } = await axios(config)
      if (!data) return
      setLoading(false)
      setAlert(alerts.add)
      setStatistic(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? { ...errors, api: err.message } : { api: err.message })
    }
  }

  const editStatistic = async () => {

    setLoading(true)
    const config = {
      url: `/statistic/${statistic?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      data: statistic,
    }
    try {
      const { data } = await axios(config)
      if (!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setStatistic(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? { ...errors, api: err.message } : { api: err.message })
    }
  }

  const deleteStatistic = async (id, callback) => {
    const config = {
      url: `/statistic/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    }
    try {
      const { data } = await axios(config)
      if (!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setStatistic(data.data)
      callback && callback({ data: data, error: '' })
    } catch (err) {
      setLoading(false)
      setErrors(errors ? { ...errors, api: err.message } : { api: err.message })
      callback && callback({ data: {}, error: err })
    }
  }

  const submit = (method) => {
    let errorsObj = null
    inputs.forEach((inp) => {
      const valueValidation = validateValue(inp.fields)
      if (valueValidation) {
        errorsObj ? errorsObj[inp.name] = valueValidation : errorsObj = { [inp.name]: valueValidation }
      }
    })
    setErrors(errorsObj)
    if (errorsObj) return
    method === 'edit' || statistic?._id ? editStatistic(statistic) : addStatistic(statistic)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      detail: statistic,
      alert,
      search,
    },
    handler: {
      submit,
      fetch: fetchStatistic,
      delete: deleteStatistic,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}