import React, { useState } from 'react'
import Accordion from './Accordion'

const Accordions = ({
  data,
  className = '',
  renderHeader,
  renderCollapsible,
  openOne = true,
}) => {

  const [show, setShow] = useState(null)
  
  const showCollapsible = (callback, idx) => {
    setShow(show === idx ? null : idx)
    callback && callback()
  }

  const CHECK_OPEN_ONE = (faqIdx) => openOne ? { collapse: show === faqIdx ? false : true } : {}

  return (
    <section className={`space-y-3 ${className}`}>
      {data.map((dt, dtIdx) => (
        <Accordion
          key={dtIdx}
          {...CHECK_OPEN_ONE(dtIdx)}
          header={({ className, onClick }) => renderHeader({data: dt, className:className, onClick: () => showCollapsible(onClick, dtIdx)})}
          collapsible={() => renderCollapsible({data: dt })}
        />
      ))}
    </section>
  )
}

export default Accordions