import React, { useEffect, useState } from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import ImageInput from '../../../components/ImageInput/ImageInput'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Select from '../../../components/Select/Select'
import TextField from '../../../components/TextField/TextField'
import { BANK_ACCOUNT_VALIDATION, BANK_LOGO_VALIDATION, BANK_NAME_VALIDATION, BANK_OWNER_VALIDATION, BANK_QR_VALIDATION, BANK_SWIFT_VALIDATION } from '../../../constants/validationConstants'
import { useValidateValue } from '../../../hooks/useValidateValue'
import { IMAGE_BASE_URL } from '../../../utils/urls'
import { useDonationViewModel } from '../../../viewModels/useDonationViewModel'

const BankMethodScreen = () => {

  const [bank, setBank] = useState('')
  const [logo, setLogo] = useState('')
  const [account, setAccount] = useState('')
  const [owner, setOwner] = useState('')
  const [swift, setSwift] = useState('')
  const [qr, setQr] = useState('')
  const [errors, setErrors] = useState({})
  const [setInitial, setSetInitial] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [currentAlert, setCurrentAlert] = useState(null)

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const validateValue = useValidateValue()

  const { state, handler } = useDonationViewModel()

  const bankOnChangeHandler = (e, value) => {
    setBank(e?.target?.value || value || '')
  }  

  const accountOnChangeHandler = (e, value) => {
    setAccount(e?.target?.value || value || '')
  }

  const ownerOnChangeHandler = (e, value) => {
    setOwner(e?.target?.value || value || '')
  }

  const swiftOnChangeHandler = (e, value) => {
    setSwift(e?.target?.value || value || '')
  }

  const logoOnChangeHandler = (e, value) => {
    setLogo(value)
  }

  const qrOnChangeHandler = (e, value) => {
    setQr(value)
  }

  const deleteHandler = () => {
    handler.deleteBank(params.id)
    setCurrentAlert(null)
    navigate('/cms/donation')
  }

  const deleteAlert = {
    title: 'Delete Bank',
    subtitle: 'Are you sure to delete?',
    variant: 'danger',
    action: [
      {
        title: 'Yes',
        variant: 'outline',
        color: 'secondary',
        onClick: () => deleteHandler(),
        className: 'w-full'
      },
      {
        title: 'No',
        variant: '',
        color: 'secondary',
        onClick: () => setCurrentAlert(null),
        className: 'w-full'
      },
    ],
  }

  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputs = [
    {
      wrapperClassName: `w-full`,
      label: 'Bank Name',
      name: 'bank',
      value: bank,
      onChange: bankOnChangeHandler,
      message: BANK_NAME_VALIDATION.message,
      validation: errors?.bank?.message,
      autoComplete: 'off',
      required: true
    },    
    {
      label: 'Account Number',
      type: 'number',
      name: 'account',
      value: account,
      onChange: accountOnChangeHandler,
      message: BANK_ACCOUNT_VALIDATION.message,
      validation: errors?.account?.message,
      autoComplete: 'off',
      required: true
    },
    {
      label: 'Account Owner',
      type: 'text',
      name: 'owner',
      value: owner,
      onChange: ownerOnChangeHandler,
      message: BANK_OWNER_VALIDATION.message,
      validation: errors?.owner?.message,
      autoComplete: 'off',
      required: true
    },
    {
      label: 'Swift Code',
      type: 'text',
      name: 'swift',
      value: swift,
      onChange: swiftOnChangeHandler,
      message: BANK_SWIFT_VALIDATION.message,
      validation: errors?.swift?.message,
      autoComplete: 'off',
      required: false
    },
    {
      type: 'file',
      label: 'Bank Logo',
      name: 'logo',
      value: logo,
      onChange: logoOnChangeHandler,
      message: BANK_LOGO_VALIDATION.message,
      validation: errors?.logo?.message,
      autoComplete: 'off',
      required: true
    },
    {
      type: 'file',
      label: 'QR Image',
      name: 'qr',
      value: qr,
      onChange: qrOnChangeHandler,
      message: BANK_QR_VALIDATION.message,
      validation: errors?.qr?.message,
      autoComplete: 'off',
      required: true
    },
  ]

  const submit = () => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    let dataToSend = {}
    inputs.forEach(inp => {
      dataToSend[inp.name] = typeof inp.value === 'string' && inp.value.includes(IMAGE_BASE_URL) ? inp.value.split(IMAGE_BASE_URL)[1].split('/')[1] : inp.value
    })
    console.log(dataToSend)
    if(params.method === 'add') return handler.sendBank(dataToSend)
    handler.updateBank(params.id, dataToSend)
  }

  useEffect(() => {
    const method = ['add', 'edit']
    const findMethod = method.find(x => params.method === x)
    if( !findMethod || (params.method === 'edit' && !params.id) || (params.method === 'add' && params.id)) return navigate('/cms/not-found')
    if(findMethod !== 'edit') return
    handler.fetchBank(params.id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navigate, params.id, params.method])

  useEffect(() => {
    if(params.method !== 'edit' || !state.bank) return
    let dsbBtn = true
    inputs.forEach((inp) => {
      const bankVal = state.bank[inp.name]
      const CHECK_BANK_VALUE = bankVal?.url || state.bank[inp.name]  
      if(CHECK_BANK_VALUE !== inp.value) dsbBtn = false
    })
    setDisableButton(dsbBtn)
    if(setInitial) return 
    inputs.forEach((inp) => {
      const bankVal = state.bank[inp.name]
      inp.onChange(null, bankVal?.url || state.bank[inp.name]  )
    })
    setSetInitial(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.method, setInitial, state.bank, inputs])

  useEffect(() => {
    if(!state.banks) return () => handler.fetchBanks()
    if(state.banks.length < 3 || params.method === 'edit') return
    setCurrentAlert({
      title: 'Cannot Add Bank',
      subtitle: 'You already have 3 bank accounts',
      variant: 'caution',
      action: [
        {
          title: 'Back To Banks',
          variant: '',
          color: 'secondary',
          onClick: () => navigate('/cms/bank'),
          className: 'w-full'
        },
      ],
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.banks])

  useEffect(() => {
    if(!state.toast || state.toast.status_code !== 200) return
    setCurrentAlert({
      title: <div className='capitalize'>Bank {params.method}ed </div>,
      subtitle: `Bank account ${params.method}ed successfully`,
      variant: 'success',
      action: [
        {
          title: 'Back To Banks',
          variant: '',
          color: 'secondary',
          onClick: () => navigate('/cms/bank'),
          className: 'w-full'
        },
      ],
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.toast])

  return (
    <CMSLayout 
      title={`${params.method} Bank Account`} 
      action={params.method === 'edit' ? [
        {
          title: 'Delete',
          variant: 'outline',
          color: 'secondary',
          icon: <IoTrashOutline />,
          onClick: () => setCurrentAlert(deleteAlert)
        }
      ] : null}
      alert={currentAlert}
    >
      <Card className='flex-col col-span-4 p-5 space-y-2'>
        {inputs.map((tx, txIdx) => (
          tx.element === 'select' ? (
            <Select
              key={txIdx}
              {...tx}
            />
          ) : tx.type === 'file' ? (
            <ImageInput  
              key={txIdx}
              imageFit={'contain'}
              {...tx}
            />
          ) : (
            <TextField
              key={txIdx}
              {...tx}
            />
          )
        ))}
        <div className='w-full pt-5'>
          <Button className='w-full' onClick={submit} disabled={disableButton}>
            {params.method === 'add' ? 'Add Bank Account' : 'Save Bank Account'}
          </Button>
        </div>
      </Card>
    </CMSLayout>
  )
}

export default BankMethodScreen