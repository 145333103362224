import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useApplyingStepsViewModel = () => {

  const initialState = {
    applying: {title: '', subtitle: '', steps: []},
    step: {title: '', description: ''}
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [applying, setApplying] = useState(initialState.applying)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)
  const [step, setStep] = useState(initialState.step)
  const [stepAlert, setStepAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setApplying({...applying, [e.target.name]: e.target.value})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'title',
      title: 'title',
      value: applying?.title || '',
      onChange: textFieldOnChange,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
      },
      required: true
    },
    {
      name: 'subtitle',
      title: 'subtitle',
      value: applying?.subtitle || '',
      onChange: textFieldOnChange,
      validation: errors?.subtitle?.message,
      message: {
        required: 'Subitle cannot be empty',
      },
      required: true
    },
  ]

  const alerts = {
    add: {
      title: `ApplyingSteps Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/applying')
        }
      ]
    },
    edit: {
      title: `ApplyingSteps Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/applying')
        }
      ]
    },
    delete: {
      title: `ApplyingSteps Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/career/applying')
        }
      ]
    }
  }


  const fetchApplyingSteps= async(params = {}) => {
    setLoading(true)
    const config = {
      url: `/applying`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setApplying(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addApplyingSteps = async() => {

    setLoading(true)

    const config = {
      url: `/applying`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: applying,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setApplying(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editApplyingSteps = async() => {

    setLoading(true)
    const config = {
      url: `/applying/${applying?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: applying,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setApplying(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteApplyingSteps = async(id, callback) => {
    const config = {
      url: `/applying/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setApplying(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const showStepAlertHandler = (method, data, idx) => {
    setStepAlert({method, idx})
    if(method !== 'edit') return      
    setStep(data)
  }

  const hideStepAlertHandler = () => {
    setStepAlert(false)
    setStep(initialState.step)
  }

  const stepOnChangeHandler = (e) => {
    setStep({...step, [e.target.name]: e.target.value})
  }

  const addStep = () => {
    setApplying({...applying, steps: [...applying.steps, step]})
    hideStepAlertHandler()
  }

  const editStep = () => {
    const existStep = applying.steps[stepAlert?.idx]
    if(!existStep) return
    let stepToSplice = [...applying.steps]
    stepToSplice.splice(stepAlert?.idx, 1, step)
    setApplying({...applying, steps: stepToSplice})
    hideStepAlertHandler()
  } 

  const deleteStep = (data, idx) => {
    const existStep = applying.steps[idx]
    if(!existStep) return
    let stepToSplice = [...applying.steps]
    stepToSplice.splice(idx, 1)
    setApplying({...applying, steps: stepToSplice})
    hideStepAlertHandler()
  }

  const stepInputs = [
    {
      name: 'title',
      title: 'title',
      value: step?.title || '',
      onChange: stepOnChangeHandler,
      validation: errors?.title?.message,
      message: {
        required: 'Title cannot be empty',
      },
      required: true
    },
    {
      name: 'description',
      title: 'description',
      value: step?.description || '',
      onChange: stepOnChangeHandler,
      validation: errors?.description?.message,
      message: {
        required: 'Subitle cannot be empty',
      },
      required: true
    },
  ]
  

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addApplyingSteps(applying) : editApplyingSteps(applying)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      detail: applying,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchApplyingSteps,
      delete: deleteApplyingSteps,
      search: searchHandler
    },
    element: {
      inputs
    },
    bundle: {
      step: {
        state: {
          detail: step, 
          alert: stepAlert,
        },
        element: {
          inputs: stepInputs
        },
        handler: {
          alert: {
            show: showStepAlertHandler,
            hide: hideStepAlertHandler,
          },
          onChange: stepOnChangeHandler,
          add: addStep,
          delete: deleteStep,
          edit: editStep
        }
      }
    }
  }

  return returnObj
}