import React, { useEffect, useState } from 'react'
import ImageContainer from '../../../components/ImageContainer/ImageContainer'
import StatisticJumbotron from '../../../components/Jumbotrons/StatisticJumbotron/StatisticJumbotron'
import GlobalLayout from '../../../components/Layouts/GlobalLayout/GlobalLayout'
import Typography from '../../../components/Typography/Typography'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import data from '../../../utils/data'
import ShapeMask from '../../../components/ShapeMask/ShapeMask'
import PersonContainer from './components/PersonContainer/PersonContainer'
import Marquee from "react-fast-marquee";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BasicCarousel from '../../../components/Carousels/BasicCarousel/BasicCarousel'
import { useStatisticViewModel } from '../../../viewModels/useStatisticViewModel'
import { useTestimonyViewModel } from '../../../viewModels/useTestimonyViewModel'
import Card from '../../../components/Cards/Card/Card'
import { ACT_StructureGetList } from '../../../cms/screens/OrganizationScreen/actions/action.structure'


const AboutScreen = () => {

  const testimony = useTestimonyViewModel()
  const { state: statistic, handler: statisticHandler } = useStatisticViewModel()

  const [donors] = useState(
    [
      [
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Besar_CreditSuisse.png`
        },
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Besar_EMpower_Logo_Color_RGB.png`
        },
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Besar_Nike.png`
        },
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Besar_PermataBank.png`
        },
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Besar_GE.png`
        },
        {
          size: 'large',
          image: `${IMAGE_PATH}/logo/donors/Entrust.png`
        },
      ],
      [
        {
          size: 'medium',
          image: `${IMAGE_PATH}/logo/donors/Sedang_JIS.png`
        },
        {
          size: 'medium',
          image: `${IMAGE_PATH}/logo/donors/Sedang_PacificCrosNoBG.png`
        },
        {
          size: 'medium',
          image: `${IMAGE_PATH}/logo/donors/Sedang_RocheT.png`
        },
        {
          size: 'medium',
          image: `${IMAGE_PATH}/logo/donors/Sedang_Target.png`
        },
        {
          size: 'medium',
          image: `${IMAGE_PATH}/logo/donors/Plan_International_Logo_blue-removebg-preview.png`
        },
      ],
      [
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_BlueSteel.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_BogaGroup.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Glensil-removebg-preview.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_joint.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_JPAC.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_JSLuwansa.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_LeMeridien.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Logo-KITAKERJA.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Mercure.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Morrissey.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_NowJakarta.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Shihlin.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Sushi-Tei.jpg`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_Variety_logo.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_WarnaAgung.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecill_PB_Mono_Primer.png`
        },
        {
          size: 'small',
          image: `${IMAGE_PATH}/logo/donors/Kecil_joint.png`
        },
      ],
    ]
  )

  const statisticData = [
    {
      amount: statistic.detail?.helps?.kids,
      title: 'Children We Reach'
    },
    {
      amount: statistic.detail?.helps?.countries,
      title: 'Countries'
    },
    {
      amount: statistic.detail?.helps?.years,
      title: 'Years Experience'
    },
  ]

  const [structureList, setStructureList] = useState([])

  const getStructureList = async () => {
    const result = await ACT_StructureGetList()
    if (!result.data) return
    const newResult = result?.data?.records
    newResult.sort((a, b) => a.sort - b.sort)
    setStructureList(newResult)
  }

  useEffect(() => {
    statisticHandler.fetch()
    testimony.handler.fetch()
    getStructureList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <GlobalLayout smallHero={{ title: 'About Us' }}>
      <section className="grid gap-5 section xl:grid-cols-3 lg:gap-8">
        <ImageContainer className='w-full max-w-sm mx-auto' src={`${IMAGE_PATH}/logo/logo.png`} />
        <div className='flex flex-col justify-center xl:col-span-2'>
          <Typography
            variant={'h4'}
            component={'h2'}
            weight={'bold'}
            lineHeight={'tight'}
            className={'mb-6'}
          >
            Helping Children Build Dreams
          </Typography>
          <div className="space-y-2">
            <Typography>
              Red Nose Foundation (RNF) is a non-profit organization that focuses on arts, education, and personal development based in Jakarta, Indonesia. Established in 2008, we work to empower underprivileged children, youth, and the communities for reaching their dreams and improving their quality of life.
            </Typography>
            <Typography>
              RNF currently runs several different program for children, parents, teachers, and local communities in our two learning centers located in Cilincing, North Jakarta, and Jurangmangu, Bintaro.
            </Typography>
            <Typography>
              With the gender-based approach applies in all of our programs, we are hoping that our beneficiaries can be optimized their potential without gender limitation.
            </Typography>
          </div>
        </div>
      </section>
      <section className='section'>
        <StatisticJumbotron data={statisticData} />
      </section>
      <section className="flex flex-col justify-center space-y-8 section lg:flex-row lg:space-y-0 lg:space-x-12">
        <div className="flex flex-col items-center w-full space-y-5 xl:space-y-10 lg:w-[48%]">
          <Typography
            variant={'h5'}
            component={'h2'}
            weight={'bold'}
            lineHeight={'tight'}
            className={'lg:mb'}
          >
            We Started From This
          </Typography>
          <BasicCarousel
            containerClassName='w-full'
            navigation={true}
            loop={true}
            data={Array(3).fill(``)}
            slideClassName={'rounded-xl overflow-hidden bg-slate-100'}
            renderSlide={(slide, idx) => (
              <ImageContainer src={`${IMAGE_PATH}/photos/before_after/before/before-${idx + 1}.jpg`} className='' ratio={{ width: 16, height: 9 }} />
            )}
          />
        </div>
        <div className="flex flex-col items-center w-full space-y-5 xl:space-y-10 lg:w-[48%]">
          <Typography
            variant={'h5'}
            component={'h2'}
            weight={'bold'}
            lineHeight={'tight'}
            className={'lg:mb'}
          >
            And Grow To This
          </Typography>
          <BasicCarousel
            containerClassName='w-full'
            navigation={true}
            loop={true}
            data={Array(2).fill('')}
            slideClassName={'rounded-xl overflow-hidden bg-slate-100'}
            renderSlide={(slide, idx) => (
              <ImageContainer src={`${IMAGE_PATH}/photos/before_after/after/after-${idx + 1}.jpg`} className='' ratio={{ width: 16, height: 9 }} />
            )}
          />
        </div>
      </section>
      <section className='space-y-16 section-non-responsive '>
        {structureList.map((structure, structureIdx) => (
          <div key={structureIdx} className="flex flex-col responsive">
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-12 lg:mb-20 text-center '}
            >
              {structure.title}
            </Typography>
            <div className="flex flex-wrap justify-center w-full">
              {structure?.people.map((prs, prsIdx) => (
                <React.Fragment key={prsIdx} >
                  {prs.length > 0 ? (
                    <div className='flex flex-wrap justify-center w-full'>
                      {prs?.map((pr, prIdx) => (
                        <div key={prIdx} className='flex flex-col w-[48%] mb-12 xl:mb-16 lg:w-[33%] md:px-5 xl:w-[25%]'>
                          <PersonContainer

                            image={{
                              src: prs?.image?.url,
                              alt: 'person' + prsIdx
                            }}
                            name={prs?.name}
                            role={prs?.role}
                            backgroundColor={prsIdx % 3 === 1 ? 'secondary' : prsIdx % 3 === 2 ? 'tertiary' : 'primary'}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='flex flex-col w-[48%] mb-12 xl:mb-16 lg:w-[33%] md:px-5 xl:w-[25%]'>
                      <PersonContainer

                        image={{
                          src: prs?.image?.url,
                          alt: 'person' + prsIdx
                        }}
                        name={prs?.name}
                        role={prs?.role}
                        backgroundColor={prsIdx % 3 === 1 ? 'secondary' : prsIdx % 3 === 2 ? 'tertiary' : 'primary'}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </section>
      <ShapeMask
        component={'section'}
        maskImage={`${IMAGE_PATH}/shapes/shape_3.png`}
        maskPosition={'center'}
        maskSize={'100% 100%'}
        className={'bg-slate-100 section-non-responsive my-24'}
      >
        <div className={'py-24 lg:py-40 xl:responsive'}>
          <div className={`h-full w-full flex flex-col justify-center items-center`}>
            <Typography
              variant={'h4'}
              component={'h2'}
              weight={'bold'}
              lineHeight={'tight'}
              className={'mb-12 text-center'}
            >
              Donors and Partners
            </Typography>
            <div className='w-full space-y-6 lg:space-y-10'>
              {donors.map((dnr, dnrIdx) => (
                <Marquee
                  key={dnrIdx}
                  className={'w-full'}
                  gradient={true}
                  gradientColor={[241, 245, 249]}
                  gradientWidth={200}
                  direction={dnrIdx % 2 === 1 ? 'right' : 'left'}
                >
                  {dnr.map((chnk, chnkIdx) => (
                    <div key={chnkIdx} className='flex justify-center mx-5 overflow-hidden lg:mx-10'>
                      <ImageContainer src={chnk.image} fit={'contain'} className={`${chnk.size === 'large' ? `w-48 xl:w-52` : chnk.size === 'medium' ? 'w-24 xl:w-28' : 'w-16 xl:w-20'}`} ratio={{ width: 16, height: 9 }} />
                    </div>
                  ))}
                </Marquee>
              ))}
            </div>
          </div>
        </div>
      </ShapeMask>
      <section className="flex flex-col space-y-12 section lg:space-y-20">
        <Typography
          variant={'h4'}
          component={'h2'}
          weight={'bold'}
          lineHeight={'tight'}
          className={'text-center'}
        >
          What They Say About Us
        </Typography>
        <BasicCarousel
          autoplay={true}
          loop={testimony.state.list.records.length > 1 ? true : false}
          className='w-full'
          navigation={true}
          data={testimony.state.list.records}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2
            }
          }}
          renderSlide={(slide, idx) => (
            <Card className='flex flex-col w-full p-5 space-y-6'>
              <div className="flex flex-col w-full">
                <span className='-mb-4 text-5xl font-bold text-theme-secondary'>“</span>
                <Typography>
                  {slide.message}
                </Typography>
              </div>
              <div className='flex items-center space-x-2'>
                <ImageContainer src={`${slide.image.url}`} className='w-12 overflow-hidden rounded-full bg-slate-100' />
                <div>
                  <Typography variant={'body'} weight={'semibold'}>{slide.name}</Typography>
                  <Typography variant={'body2'} >{slide.occupation}</Typography>
                </div>
              </div>
            </Card>
          )}
        />
      </section>
    </GlobalLayout>
  )
}

export default AboutScreen