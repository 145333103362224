import React, { useEffect, useState } from 'react'
import { IoBusinessOutline, IoChatbubblesOutline, IoExitOutline, IoGiftOutline, IoMailOutline, IoNewspaperOutline, IoStatsChartOutline } from 'react-icons/io5'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { IMAGE_PATH } from '../../../constants/urlConstants'
import Alert from '../../Alert/Alert'
import Button from '../../Button/Button'
import IconContainer from '../../IconContainer/IconContainer'
import Typography from '../../Typography/Typography'
import Cookies from 'js-cookie'
import Toast from '../../Toast/Toast'
import { useAuth } from '../../../hooks/useAuth'
import { RiBankLine } from 'react-icons/ri'
import { FaSpinner } from 'react-icons/fa'

const CMSLayout = ({
  title,
  children,
  action,
  alert,
  toast,
  loading,
}) => {

  const { auth } = useAuth()

  const [offline, setOffline] = useState(false)

  const navigate = useNavigate()

  const logOut = () => {
    Cookies.remove('userInfo')
    navigate('/cms/login')
  }  

  useEffect(() => {
    if(!auth) return navigate('/cms/login')
  }, [auth, navigate])

  useEffect(() => {
    document.title = 'Red Nose Foundation CMS'
    setOffline(!window.navigator.onLine)
    window.addEventListener('offline', () => setOffline(true))
    window.addEventListener('online', () => setOffline(false))
  }, [])

  const navLinkClassName = ({isActive}) => `${styles.sidebar.menu.default} ${isActive && styles.sidebar.menu.active}`

  return (
    <>
      <div className='flex w-full h-screen bg-slate-100'>
        <div className='flex flex-col w-56 h-screen bg-white'>
          <div className="flex flex-col items-center p-5 space-y-2">
            <img src={`${IMAGE_PATH}/logo/rnf_logo_cms.png`} alt={'rnf_logo'} className={'h-8'}/>
            <Link to={'/'} target={`_blank`} className={'link text-theme-primary text-center'}>Go to website</Link>
          </div>
          <div className="w-full space-y-4">
            <div className={`${styles.sidebar.menu.container}`}>
              <Typography variant={`subtitle2`} weight={'bold'} className={'pl-3'}>
                MAIN MENU
              </Typography>
              <NavLink to={`/cms/donation`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoGiftOutline/>
                </IconContainer>
                <div>Donation</div>
              </NavLink>
              <NavLink to={`/cms/messages`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoMailOutline/>
                </IconContainer>
                <div>Messages</div>
              </NavLink>
              <NavLink to={'/cms/media'} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoNewspaperOutline/>
                </IconContainer>
                <div>Media</div>
              </NavLink>
              {/* <NavLink to={`/cms/career`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoBriefcaseOutline/>
                </IconContainer>
                <div>Career</div>
              </NavLink> */}
              <NavLink to={`/cms/faq`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoChatbubblesOutline/>
                </IconContainer>
                <div>FAQ</div>
              </NavLink>
              <NavLink to={`/cms/statistic`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoStatsChartOutline/>
                </IconContainer>
                <div>Statistic</div>
              </NavLink>
              <NavLink to={`/cms/organization`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <IoBusinessOutline/>
                </IconContainer>
                <div>Organization</div>
              </NavLink>
            </div>
            <div className={`${styles.sidebar.menu.container}`}>
              <Typography variant={`subtitle2`} weight={'bold'} className={'pl-3'}>
                SETTINGS
              </Typography>
              <NavLink to={`/cms/bank`} className={navLinkClassName}>
                <IconContainer className={styles.sidebar.menu.icon}>
                  <RiBankLine/>
                </IconContainer>
                <div>Bank</div>
              </NavLink>            
            </div>
          </div>
          <div className={`${styles.sidebar.menu.container} mt-auto`}>
            <button className={`${styles.sidebar.menu.default}`} onClick={logOut}>
              <IconContainer className={styles.sidebar.menu.icon}>
                <IoExitOutline/>
              </IconContainer>
              <div>Exit</div>
            </button>
          </div>
        </div>
        <div className='relative w-full h-full'>
          <div className="absolute inset-0 p-6 overflow-y-auto">
            <main className='grid grid-cols-12 gap-5'>
              {(title || action) && (
                <div className='flex justify-between col-span-full'>
                  {title && (
                    <Typography variant={'h5'} weight={'bold'} className={'col-span-full capitalize'}>
                      {title}
                    </Typography>
                  )}
                  {action && (
                    <div className='flex space-x-2'>
                      {action.map((act, actIdx) => (
                        <Button key={actIdx} className={act.className} onClick={act.onClick} variant={act.variant} color={act.color}>
                          <IconContainer className='text-xl'>
                            {act.icon}
                          </IconContainer>
                          <div>
                            {act.title}
                          </div>
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {children}
            </main>
          </div>
        </div>
      </div>
      {alert && (
        <Alert 
          title={alert.title}
          subtitle={alert.subtitle}
          variant={alert.variant}
          icon={alert.icon}
          action={alert.action}
        />
      )}
      {offline && (
        <div className='fixed inset-0 flex flex-col items-center justify-center text-white bg-theme-primary'>You Are Offline</div>
      )}
      <Toast active={toast}  message={toast?.message} variant={toast?.variant || toast?.status_code !== 200 ? 'danger' : 'success'} deactiveFunc={toast?.close}/>
      {loading && (
        <div className='fixed inset-0 z-50 flex flex-col items-center justify-center bg-white bg-opacity-50'>
          <FaSpinner className='animate-spin'/>
          <div>
            Loading...
          </div>          
        </div>
      )}
    </>
  )
}


const styles = {
  sidebar: {
    menu: {
      container: `w-full px-3 py-2 space-y-2`,
      default: `flex items-center w-full px-3 py-2 space-x-2 transition duration-150 rounded-full hover:bg-theme-primary-50 text-sm font-medium relative`,
      active: `bg-theme-primary hover:bg-theme-primary-700 text-white`,
      active2: `bg-theme-primary-50`,
      icon: `text-xl`
    }
  }
}

export default CMSLayout