import React, { useEffect } from 'react'
import useToggle from '../../hooks/useToggle'

const Overlay = ({
  active,
  onClick,
  zIndex = '40',
  freeze
}) => {

  const [render, toggleRender] = useToggle(false)
  const [visible, toggleVisible] = useToggle(false)

  const CHECK_VISIBLE = visible ? styles.visible : styles.hidden


  useEffect(() => {
    const renderHandler = () => {
      toggleRender(true)
      setTimeout(() => {
        toggleVisible(true)
      }, 10)
      if(!freeze) return
      document.body.style.overflow = 'hidden'
    }
    const hideHandler = () => {
      toggleVisible(false)
      setTimeout(() => {
        toggleRender(false)
      }, 150)
      if(!freeze) return
      document.body.style.overflow = ''
    }
    if (!active) return hideHandler()
    if (active) return renderHandler()
  }, [active, freeze, toggleRender, toggleVisible])

  
  return (
    render ? (
      <div className={`${styles.overlay} ${CHECK_VISIBLE}`} onClick={onClick} style={{zIndex}}></div>
    ) : ''
  )
}

export default Overlay

const styles = {
  overlay: 'fixed inset-0 bg-theme-primary-900 transition duration-150',
  hidden: 'opacity-0',
  visible: 'opacity-50'
}