import React, { useEffect, useState } from 'react'
import Input from '../Input/Input'
import { IoChevronDown } from 'react-icons/io5'

const Select = ({
  wrapperClassName = '',
  containerClassName = '',
  label,
  labelName,
  className,
  name,
  value,
  onFocus,
  onBlur,
  onClick,
  autoComplete,
  style,
  labelClassName = '',
  dropdownData = [],
  optionOnSelect,
  note,
  validation,
  disabled,
}) => {

  const [dropdown, setDropdown] = useState(false)

  const inputProps = {
    wrapperClassName: wrapperClassName,
    containerClassName: containerClassName,
    label: label,
    labelName: labelName,
    className: className,
    name: name || console.error(`Select must have name to work properly`),
    value: value,
    onFocus: onFocus,
    onBlur: onBlur,
    autoComplete: autoComplete,
    labelClassName: labelClassName,
    note: note,
    validation: validation,
    disabled: disabled,
  }

  const onClickHandler = (e) => {
    setDropdown(dropdown ? false : true)
    if (dropdown) e.target.blur()
    onClick && onClick(e)
  }

  const optionOnSelectHandler = (e, data, idx) => {
    optionOnSelect && optionOnSelect(e, data, idx)
    if (dropdown) e.target.blur()
    setDropdown(false)
  }

  useEffect(() => {
    if(!dropdown) return
    const windowOnClick = (e) => {
      if (e.target.name !== name) setDropdown(false)
    }
    window.addEventListener('click', windowOnClick)
    return () => window.removeEventListener('click', windowOnClick)
  }, [dropdown, name])

  return (
    <Input
      {...inputProps}
      dropdown={dropdown}
      onClick={onClickHandler}
      type={'button'}
      style={{textAlign: 'left'}}
      dropdownData={dropdownData}
      optionOnSelect={optionOnSelectHandler}
      adornment={<IoChevronDown />}
      adornmentPosition={'right'}
      adornmentRotate={'true'}
    />
  )
}

export default Select