
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import ImageInput from '../../../../components/ImageInput/ImageInput'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import Textarea from '../../../../components/Textarea/Textarea'
import TextField from '../../../../components/TextField/TextField'
import Typography from '../../../../components/Typography/Typography'
import { useNewsViewModel } from '../../../../viewModels/useNewsViewModel'


const MediaNewsScreen = ({method}) => {

  const [paths, setPaths] = useState([])

  const location = useLocation()
  const params = useParams()

  const { state, handler, element } = useNewsViewModel(method)

  useEffect(() => {
    const pathname = location.pathname
    const pathArr = pathname.split('/')
    pathArr.splice(0, 3)
    if(params.id) pathArr.pop()
    setPaths(pathArr)
  }, [location, params.id])
  
  useEffect(() => {
    if(method !== 'edit' || !params.id) return
    handler.fetch(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, params.id])

  const addPreviousPaths = (idx) => {
    let pathsArr = []
    for (let i = 0; i <= idx; i++) {
      pathsArr.push(paths[i])   
    }
    return pathsArr.join('/')
  }

  return (
    <CMSLayout alert={state.alert} title={`${method} News ${params.id ? `#${params.id}` : ''}`}>
      <div className='flex space-x-2 text-sm capitalize'>
        {paths.map((pth, pthIdx) => (
          <React.Fragment  key={pthIdx} >
            {pthIdx !== paths.length -1 ? (
              <>
                <Link to={`/cms/media/${addPreviousPaths(pthIdx)}`} className={'link '}>
                  {pth}
                </Link>
                <div>/</div>
              </>
            ) : (
              <div>{pth}</div>
            )}
            
          </React.Fragment>
        ))}
      </div>
      <Card className='flex flex-col p-5 col-span-full'>
        <div className="space-y-2 responsive responsive--article">
          {element.inputs.map((inp, inpIdx) => (
            <div  key={inpIdx} className={'space-y-1'}>
              <Typography variant={'subtitle1'} weight={'semibold'} className={'capitalize'}>
                {inp.title}
              </Typography>
              {inp.name === 'description' ? (
                <Textarea
                  {...inp}
                />            
              ) : inp.name === 'image' ? (
                <ImageInput
                  imageFit='cover'
                  {...inp}
                />
              ) : (
                <TextField               
                  {...inp}
                />
              )}
            </div>
          ))}
          <div className='w-full pt-5'>
            <Button className='w-full capitalize' disabled={state.loading} onClick={() => handler.submit(method)}>
              {method} News
            </Button>
          </div>
        </div>
      </Card>
    </CMSLayout>
  )
}

export default MediaNewsScreen