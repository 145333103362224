import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Typography from '../../../components/Typography/Typography'
import { IMAGE_PATH } from '../../../constants/urlConstants'

const NotFound = () => {

  const location = useLocation()

  return (
    <div className='grid w-full h-screen text-white bg-theme-primary place-content-center'>
      <div className='flex flex-col items-center text-center'>
        <div className='w-64 mb-5 lg:w-72 xl:w-80'>
          <img src={`${IMAGE_PATH}/logo/type_logo.png`} alt="" />
        </div>
        <div className={'text-theme-secondary text-7xl xl:text-8xl mb-5 font-bold'}>
          Oops!
        </div>
        <Typography variant={'h5'} weight={'semibold'}>
          404 - PAGE NOT FOUND
        </Typography>
        <Typography variant={'subtitle1'}>
          The page you requested could not be found
        </Typography>
        <Link to={location.pathname.includes('cms') ? '/cms' : '/'} >
          <Button color={"tertiary"} className={'mt-12'}>
            BACK TO {location.pathname.includes('cms') ? 'CMS' : 'HOME'}
          </Button>
        </Link>        
      </div>
    </div>
  )
}

export default NotFound