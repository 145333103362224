import React from 'react'
import { IoClose } from 'react-icons/io5'
import { RiAlertFill, RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri'
import Button from '../Button/Button'
import Card from '../Cards/Card/Card'
import IconContainer from '../IconContainer/IconContainer'
import Typography from '../Typography/Typography'

const Alert = ({
  title,
  subtitle,
  variant,
  icon,
  action,
  children,
  close,
  closeFunction
}) => {

  const variants = [
    {
      variant: 'success',
      icon: <RiCheckboxCircleFill className='text-green-500'/>
    },
    {
      variant: 'warning',
      icon: <RiAlertFill className='text-yellow-500'/>
    },
    {
      variant: 'danger',
      icon: <RiCloseCircleFill className='text-red-500'/>
    },
  ]

  const findVariant = variants.find(x => x.variant === variant)

  return (
    <div className='fixed inset-0 z-[999] flex flex-col items-center justify-center bg-black bg-opacity-50'>
      <div className='absolute inset-0 z-20'></div>
      <div className='relative w-full max-w-md'>
        <Card className='relative flex flex-col items-center justify-center w-full p-5 text-center z-[999]'>
          {close && (
            <button onClick={closeFunction} className={'absolute top-5 right-5'}>
              <IconContainer className='text-2xl'>
                <IoClose/>
              </IconContainer>
            </button>
          )}
          {(icon || findVariant) && (
            <IconContainer className='text-6xl'>
              {icon || findVariant?.icon || ''}
            </IconContainer>
          )}
          {title && (
            <Typography variant={'h6'} weight={'bold'}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography >
              {subtitle}
            </Typography>
          )}
          {children}
          {action && (
            <div className='flex w-full mt-6 space-x-2'>
              {action.map((act, actIdx) => (
                <Button key={actIdx} className={act.className} onClick={act.onClick} variant={act.variant} color={act.color}>
                  {act.icon && (
                    <IconContainer className='text-xl'>
                      {act.icon}
                    </IconContainer>
                  )}
                  <div>
                    {act.title}
                  </div>
                </Button>
              ))}
            </div>
          )}
        </Card>
        
      </div>
    </div>
  )
}

export default Alert