import React, { useState } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import ClickArea from '../../components/ClickArea/ClickArea'
import IconContainer from '../../components/IconContainer/IconContainer'
import ImageContainer from '../../components/ImageContainer/ImageContainer'
import List from '../../components/Lists/List/List'
import ListCard from '../../components/Lists/ListCard/ListCard'
import CardPopUp from '../../components/PopUps/CardPopUp/CardPopUp'
import Typography from '../../components/Typography/Typography'
import { IMAGE_PATH } from '../../constants/urlConstants'
import useToggle from '../../hooks/useToggle'


const AboutSection = ({
  className = ''
}) => {

  const islands = [
    {
      name: 'Sumatera',
      image: `${IMAGE_PATH}/maps/sumatera_island.png`,
      list: [
        {
          name: 'Aceh',
          image: `${IMAGE_PATH}/photos/who_we_are/Aceh.jpg`,
          description: `Aligned with our belief that social circus can be a powerful tool of youth empowerment, RNF always aims to introduce social circus too much more youth so it can bring real impacts to them. Together with the United States Agency for International Development (USAID), we invited a group of young people from Aceh to Jakarta to get special social circus training. As they learned various circus tricks in an exciting way, the social circus was also a way for them to heal past traumas and learn important soft skills, such as confidence, responsibility, and teamwork.`
        },
        {
          name: 'Mentawai',
          image: `${IMAGE_PATH}/photos/who_we_are/Mentawai.jpg`,
          description: `In October 2010, a 7.8 magnitude earthquake and tsunami hit Mentawai, West Sumatra which swept up the local villagers’ homes without warning and resulted in the displacement of thousands of people. As a response, the RNF team held a social circus workshop and performance to entertain and heal the trauma of these natural disasters’ victims, including many children. To reach the area where they temporarily stayed, we collaborated with the United Nations Development Program (UNDP).`
        },
      ],
    },
    {
      name: 'Java',
      image: `${IMAGE_PATH}/maps/java_island.png`,
      list: [
        {
          name: 'Cilincing',
          image: `${IMAGE_PATH}/photos/who_we_are/Cilincing.jpg`,
          description: `Be the beginning of the region to pioneer the journey of Red Nose. This area is famous for its shellfish peeling area and also a fishing village. Dan Roberts and Dedi teamed up to start RNF's journey in the region in 2008. The Red Nose Foundation was established to be a safe home for every child who wants to receive an education and also practice their artistic skills. Learning and playing become daily activities that are carried out in Cilincing.`
        },
        {
          name: 'Sentul',
          image: `${IMAGE_PATH}/photos/who_we_are/Sentul.jpg`,
          description: `Gerakan on the Spot (GOTS) is one of the agendas of the 100,000 Heroes Movement implemented by the Red Nose Foundation. The activities that have been carried out in Sentul are proof that everyone can donate regardless of age and that everyone can become a hero of the Red Nose Foundation. This activity will continue to be carried out to continue to help the children of the Red Nose Foundation in achieving their dreams.          `
        },
        {
          name: 'Jurangmangu',
          image: `${IMAGE_PATH}/photos/who_we_are/Jurangmangu.jpg`,
          description: `The South Tangerang area is also one of the Red Nose Foundation's targets in reaching out to children. This area is also the main office of the Red Nose Foundation to help children in the scavenger village, Bintaro Lama to be able to achieve their dreams and freely express their creativity.`
        },
        {
          name: 'Yogyakarta',
          image: `${IMAGE_PATH}/photos/who_we_are/Yogyakarta.jpg`,
          description: `The eruption of Mount Merapi, Yogyakarta in 2010 has left many impacts on those who were affected, including the feeling of trauma. To help the victims’ trauma recovery, RNF collaborated with the Jakarta Intercultural School (JIS) to conduct the special circus performance and workshops for them. Through this program, our hope was to replace the fear and grief experienced by the victims, especially children, with laughter and joy.`
        },
      ],
    },
    {
      name: 'Bali',
      image: `${IMAGE_PATH}/photos/who_we_are/Bali.jpg`,
      description: `In upholding justice and gender equality, the Red Nose Foundation held a Workshop on Social Circus and 'Training of Trainer (ToT): Counseling and Parenting Techniques' in Bali. RNF collaborates with PVI and the East Bali Poverty Project (EBPP). This training is carried out with the hope that it is important that every child understands the existence of gender equality and that children can freely reach their dreams without any restrictions.`,
    },
    {
      name: 'Sulawesi',
      image: `${IMAGE_PATH}/maps/sulawesi_island.png`,
      list: [
        {
          name: 'Palu',
          image: `${IMAGE_PATH}/photos/who_we_are/Palu.jpg`,
          description: `Together with NIKE and Sahabat Pulau Indonesia, RNF flew to Palu, Central Sulawesi in 2018 to hold 6 C's Coaching Strategies for Elementary's PE Teachers. This activity aims to share the CAC (Coaching Across Continents) Curriculum, and its coaching strategies to building children' positive character through fun sport games. Other than holding sport training, RNF also held training for parents about improving parenting skills and strengthening the family-function as children's support system.`
        },
        {
          name: 'Majene',
          image: `${IMAGE_PATH}/photos/who_we_are/Majene.jpg`,
          description: `A project jointly implemented with the Ministry of Education and Culture and Nike, the Red Nose Foundation continues its journey to continue to help and save Indonesian children. RNF supports the realization of the Healthy School program to improve the health quality of children in Indonesia.`
        },
        {
          name: 'Bulukumba',
          image: `${IMAGE_PATH}/photos/who_we_are/Bulukumba.jpg`,
          description: `From Palu, RNF moved to Bulukumba, South Sulawesi in 2018 to hold 6 C's Coaching Strategies for Elementary's PE Teachers. This activity aims to share the CAC (Coaching Across Continents) Curriculum, and its coaching strategies to building children' positive character through fun sport games. Other than holding sport training, RNF also held training for parents about improving parenting skills and strengthening the family-function as children's support system which was held in SDN 13 Salemba, Bulukumba.`
        },
      ],
    },
  ]

  const [islandPopUp, toggleIslandPopUp] = useToggle(false)
  const [detailPopUp, toggleDetailPopUp] = useToggle(false)
  const [island, setIsland] = useState(null)
  const [detail, setDetail] = useState(null)

  const showIslandPopUpHandler = (isl) => {
    const findIsland = islands.find(x => x.name === isl)
    if(!findIsland) return
    toggleIslandPopUp()
    setIsland(findIsland)
  }

  const closeIslandPopUpHandler = (type) => {
    toggleIslandPopUp(false)
    setTimeout(() => {
      setIsland(null)
    }, 300)
  }

  const showDetailPopUpHandler = (isl, ct, dt) => {
    if(dt) {
      setDetail(dt)
      toggleDetailPopUp()
      return
    }
    const findIsland = islands.find(x => x.name === isl)
    if(!findIsland) return console.error('Island Not Found')
    const findCity = findIsland.list.find(x => x.name === ct)
    if(!findCity) return console.error('City Not Found')
    setDetail(findCity)
    toggleDetailPopUp()
  } 

  const closeDetailPopUpHandler = () => {
    toggleDetailPopUp()
    setTimeout(() => {
      setDetail(null)
    }, 300)
  }

  const clickAreas = [
    {
      id: 'sumatera',
      left: '10%',
      top: '28%',
      width: '8%',
      height: '20%',
      visible: false,
      onClick: () => showIslandPopUpHandler('Sumatera'),
    },
    {
      id:'java',
      left:'28%',
      bottom:'12%',
      width:'8%',
      height:'20%',
      visible: false,
      onClick: () => showIslandPopUpHandler('Java'),
    },
    {
      id:'bali',
      left:'39.6%',
      bottom:'5%',
      width:'8%',
      height:'20%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Bali'),
    },
    {
      id:'sulawesi',
      right:'40.6%',
      bottom:'40%',
      width:'8%',
      height:'20%',
      visible: false,
      onClick: () => showIslandPopUpHandler('Sulawesi'),
    },
  ]

  const bigClickAreas = [
    {
      id: 'aceh',
      left: '0%',
      top: '8%',
      width: '1.5%',
      height: '3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Sumatera', 'Aceh'),
    },
    {
      id: 'mentawai',
      left: '6.5%',
      top: '42%',
      width: '1.5%',
      height: '3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Sumatera', 'Mentawai'),
    },
    {
      id:'sentul',
      left:'25.6%',
      bottom:'23%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Java', 'Sentul'),
    },
    {
      id:'cilincing',
      left:'25.1%',
      bottom:'26%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Java', 'Cilincing'),
    },
    {
      id:'jurangmangu',
      left:'24.1%',
      bottom:'24.5%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Java', 'Jurangmangu'),
    },
    {
      id:'yogyakarta',
      left:'34%',
      bottom:'15.5%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Java', 'Yogyakarta'),
    },
    {
      id:'bali',
      left:'43.1%',
      bottom:'12%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler(null, null, islands.find(x => x.name === 'Bali')),
    },
    {
      id:'Palu',
      right:'45.6%',
      top:'41%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Sulawesi', 'Palu'),
    },
    {
      id:'Bulukumba',
      right:'44.4%',
      bottom:'29.3%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Sulawesi', 'Bulukumba'),
    },
    {
      id:'Majene',
      right:'47.6%',
      bottom:'41%',
      width:'1.5%',
      height:'3.5%',
      visible: false,
      onClick: () => showDetailPopUpHandler('Sulawesi', 'Majene'),
    },
  ]

  return (
    <>
      <section className={`relative text-center ${className}`}>
        <Typography
          variant={'h4'}
          component={'h2'}
          weight={'bold'}
          lineHeight={'tight'}
          className={'mb-6 text-theme-primary'}
        >
          Who We Are
        </Typography>
        <Typography variant={'body1'} className={'mb-12 lg:mb-20 max-w-4xl mx-auto'}>
        Red Nose Foundation (RNF) is a non-profit organization aiming to improve individual qualities through arts and education of children and youth living in underprivileged environments. Established in 2008, we are eager to help children build dreams. Our vision is supporting children to become individuals with life goals, equip them with skills and abilities to achieve it, so they become positive contributors for the society. Over a decade, we have promoted the empowerment of children and youth through art, education, and outreach programs, supporting educational and personal development, building safe places for children to explore, experiment and well-educated, also pioneering force behind creative education, advocacy, and rehabilitation.
        </Typography>
        <div className='relative block w-full lg:hidden'>
          <img
            className='w-full'
            src={'./assets/img/maps/indonesian_map_small.png'}
            alt={'indonesian_map'}
          />
          {clickAreas.map((area, idx) => (
            <ClickArea
              key={idx}
              {...area}
            />
          ))}
        </div>
        <div className='relative hidden w-full lg:block'>
          <img
            className='w-full'
            src={'./assets/img/maps/indonesian_map.png'}
            alt={'indonesian_map'}
          />
          {bigClickAreas.map((area, idx) => (
            <ClickArea
              key={idx}
              {...area}
            />
          ))}
        </div>
      </section>
      <CardPopUp
        active={islandPopUp}
        title={island?.name}
        close
        closeFunction={() => closeIslandPopUpHandler('island')}
        freeze
      >
        <div className="relative flex flex-col flex-1">
          <div className="absolute inset-0 flex flex-col w-full pb-5 space-y-5 overflow-y-scroll scrollbar--hidden">
            <ImageContainer className='w-full' src={island?.image} ratio={{width: 16, height:9}} fit={'contain'}/>
            <div className="flex flex-col w-full space-y-5">
              {island?.list && (
                <div className='space-y-2'>
                  {island?.description && (
                    <Typography 
                      className={'text-gray-500'}
                    >
                      {island?.description}
                    </Typography>
                  )}
                  <List className='space-y-1'>
                    {island?.list.map((ls, lsIdx) => (
                      <ListCard key={lsIdx} className="space-x-2 " onClick={() => showDetailPopUpHandler(null, null, ls)}>
                        <IconContainer className='font-semibold text-theme-secondary'>{lsIdx + 1}</IconContainer>
                        <div className='w-full font-semibold'>{ls.name}</div>
                        <IconContainer><IoChevronForward /></IconContainer>
                      </ListCard>
                    ))}
                  </List>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardPopUp>
      <CardPopUp
        active={detailPopUp}
        title={detail?.name}
        close
        closeFunction={() => closeDetailPopUpHandler()}
        freeze
      >
        <div className="relative flex flex-col flex-1">
          <div className="absolute inset-0 flex flex-col w-full pb-5 space-y-5 overflow-y-scroll scrollbar--hidden">
            <ImageContainer className='w-full overflow-hidden rounded-xl' src={detail?.image} ratio={{width: 16, height:9}}/>
            <div className="flex flex-col w-full space-y-5">
              {detail?.description && (
                <Typography 
                  className={'text-gray-500'}
                >
                  {detail?.description}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </CardPopUp>
    </>
  )
}

export default AboutSection