import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Cards/Card/Card'
import CMSLayout from '../../../../components/Layouts/CMSLayout/CMSLayout'
import Table from '../../../../components/Table/Table'
import TextField from '../../../../components/TextField/TextField'
import Typography from '../../../../components/Typography/Typography'
import { useGetFullDate } from '../../../../hooks/useGetFullDate'
import { useNewsViewModel } from '../../../../viewModels/useNewsViewModel'
import ActionButtons from '../../../components/ActionButtons/ActionButtons'

const MediasNewsScreen = ({page, paginationOnClick, pageNav}) => {

  const { state, handler } = useNewsViewModel()

  const navigate = useNavigate()
  const getFullDate = useGetFullDate()

  const pickData = [
    {
      title: 'Title',
      selector: 'title',
      className: 'text-left w-1/3 flex-shrink-0'
    }, 
    {
      title: 'Description',
      selector: 'description',
      className: 'text-left w-full'
    },
    {
      title: 'Published Date',
      selector: 'createdAt',
      processor: getFullDate,
      className: 'whitespace-nowrap text-center w-1/5 flex-shrink-0'
    },
  ]

  useEffect(() => {
    handler.fetch(null, {search: state.search.length > 3 ? state.search : null, page: page} )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.search, page])

  return (
    <CMSLayout title={'Media'}>
      <div className="flex space-x-2 col-span-full">
        {pageNav.map((pgNav, pgNavIdx) => (
          <Button key={pgNavIdx} variant={'outline'} active={pgNav.active} onClick={pgNav.onClick}>
            {pgNav.title}
          </Button>
        ))}
      </div>
      <Card id={'news'} className='flex-col p-5 space-y-4 col-span-full'>
        <Typography
          variant={'h6'}
          weight={'bold'}
          className={'capitalize'}
        > 
          News List
        </Typography>
        <div className="flex items-end justify-between w-full">
          <TextField
            label={'Search'}
            wrapperClassName='w-1/4'
            variant={'search'}
            placeHolder={'Search title'}
            value={state.search}
            onChange={handler.search}
          />
          <Button  className={'capitalize'} color={'secondary'} onClick={() => navigate(`/cms/media/news/add`)}>
            Add News
          </Button>          
        </div>        
        <Table
          containerClassName='w-full'
          className='flex flex-col'
          headRowClassName={'flex w-full'}
          bodyRowClassName={'flex w-full items-center'}
          data={state.list?.records}
          pagination={true}
          page={page}
          pageTotal={state.list.totalPages}
          maxDataPreview={state.list.limit}
          paginationOnClick={paginationOnClick}
          pickData={pickData}
          actions={(data) => (
            <ActionButtons 
              deleteAction={() => handler.delete(data._id, () => {
                handler.fetch()
                paginationOnClick(1)
              })}
              editAction={() => navigate(`/cms/media/news/edit/${data._id}`)}
              seeDetailAction={()=>window.open(`/media/news/${data._id}`,'_blank')}
            />
          )}
        />
      </Card>
    </CMSLayout>
  )
}

export default MediasNewsScreen