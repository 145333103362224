export const useValidateValue = () => {
  const validateValue = (valueToValidate = []) => {
    let errObj = {}
    valueToValidate.forEach((vl, idx) => {
      if(vl.required && (vl.value?.toString() === '' || !vl.value)) return errObj[vl.name] = { type: 'required', message: vl.message.required }
      if(vl.minLength && vl.value.length < vl.minLength ) return errObj[vl.name] = { type: 'minLength', message: vl.message.minLength }
      if(vl.pattern && !vl.pattern.test(vl.value)) return errObj[vl.name] = { type: 'invalid', message: vl.message.invalid }
    })
    if(Object.entries(errObj).length === 0) errObj = null
    return errObj
  }
  return validateValue
}