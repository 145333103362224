import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Card from '../../../components/Cards/Card/Card'
import CMSLayout from '../../../components/Layouts/CMSLayout/CMSLayout'
import Textarea from '../../../components/Textarea/Textarea'
import Typography from '../../../components/Typography/Typography'
import { useFaqViewModel } from '../../../viewModels/useFaqViewModel'


const FAQMethodScreen = () => {
  const params = useParams()

  const methods = ['add', 'edit']
  const [CHECK_METHOD] = useState(methods.find(x => params.method === x))

  const { state, handler, element } = useFaqViewModel(CHECK_METHOD)
  
  useEffect(() => {
    if(CHECK_METHOD !== 'edit' || !params.id) return
    handler.fetch(params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CHECK_METHOD, params.id])


  return (
    <CMSLayout alert={state.alert} title={`${CHECK_METHOD} Faq ${params.id ? `#${params.id}` : ''}`} >

      <Card className='flex flex-col p-5 col-span-full'>
        <div className="space-y-2 responsive responsive--article">
          {element.inputs.map((inp, inpIdx) => (
            <div  key={inpIdx} className={'space-y-1'}>
              <Typography variant={'subtitle1'} weight={'semibold'} className={'capitalize'}>
                {inp.title}
              </Typography>
              <Textarea
                {...inp}
              />     
            </div>
          ))}
          <div className='w-full pt-5'>
            <Button className='w-full capitalize' loading={state.loading} onClick={() => handler.submit(CHECK_METHOD)}>
              {CHECK_METHOD} Faq
            </Button>
          </div>
        </div>
      </Card>
    </CMSLayout>
  )
}

export default FAQMethodScreen