import axios from "axios"
import {  useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { useValidateValue } from "../hooks/useValidateValue"
import { API_BASE_URL } from "../utils/urls"

export const useFaqViewModel = () => {

  const initialState = {
    faq: {question: '', answer: '', }
  }

  const {token} = useAuth()
  const navigate = useNavigate()
  const validateValue = useValidateValue()
  
  const [search, setSearch] = useState('')
  const [faqList, setFaqList] = useState({records: []})
  const [faq, setFaq] = useState(initialState.faq)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [alert, setAlert] = useState(null)

  const textFieldOnChange = (e) => {
    setFaq({...faq, [e.target.name]: e.target.value})
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  const inputs = [
    {
      name: 'question',
      title: 'question',
      value: faq?.question || '',
      onChange: textFieldOnChange,
      validation: errors?.question?.message,
      message: {
        required: 'Question cannot be empty',
        invalid: 'FAQ with the same question is already exist'
      },
      required: true,
      rows: 4
    },
    {
      name: 'answer',
      title: 'answer',
      value: faq?.answer || '',
      onChange: textFieldOnChange,
      validation: errors?.answer?.message,
      message: {
        required: 'You have to write the answer',
      },
      required: true,
      rows: 10
    },
  ]
  

  const alerts = {
    add: {
      title: `FAQ Added Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/faq')
        }
      ]
    },
    edit: {
      title: `FAQ Edited Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/faq')
        }
      ]
    },
    delete: {
      title: `FAQ Deleted Successfuly`,
      variant: 'success',
      action: [
        {
          title: 'OK',
          className: 'w-full',
          onClick: () => navigate('/cms/faq')
        }
      ]
    }
  }


  const fetchFaq= async(id, params = {}) => {
    setLoading(true)
    const config = {
      url: `/faq${id ? `/${id}` : ''}`,
      method: 'GET',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
      },   
      params: {
        ...params
      }
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      !id ? setFaqList(data) : setFaq(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const addFaq = async() => {
    setLoading(true)

    const config = {
      url: `/faq`,
      method: 'POST',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: faq,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.add)
      setFaq(data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }
  
  const editFaq = async() => {

    setLoading(true)
    const config = {
      url: `/faq/${faq?._id}`,
      method: 'PUT',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
      data: faq,
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.edit)
      setFaq(data.data)
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
    }
  }

  const deleteFaq = async(id, callback) => {
    const config = {
      url: `/faq/${id}`,
      method: 'DELETE',
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
      },   
    }
    try {
      const { data } = await axios(config)
      if(!data) return
      setLoading(false)
      setAlert(alerts.delete)
      setFaq(data.data)
      callback && callback({data: data , error: ''})
    } catch (err) {
      setLoading(false)
      setErrors(errors ? {...errors, api: err.message} : {api: err.message})
      callback && callback({data: {} , error: err})
    }
  }

  const submit = (method) => {
    const valueValidation = validateValue(inputs)
    setErrors(valueValidation)
    if(valueValidation) return
    method === 'add' ? addFaq(faq) : editFaq(faq)
  }

  const returnObj = {
    state: {
      loading,
      errors,
      list: faqList,
      detail: faq,
      alert, 
      search,
    },
    handler: {
      submit,
      fetch: fetchFaq,
      delete: deleteFaq,
      search: searchHandler
    },
    element: {
      inputs
    }
  }

  return returnObj
}